import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ert-table-filter',
  templateUrl: './table-filter.component.html',
  styleUrls: ['./table-filter.component.scss']
})
export class TableFilterComponent implements OnInit {
  @Input() title: string;
  @Output() filterEvent = new EventEmitter<string>();
  @Input() placeholder: string;
  @Input() mode: string; // Mandatory Field : light/dark
  filterString: string;

  constructor() { }

  ngOnInit() {
    this.mode = this.mode.trim().toLowerCase();
  }

  getFilterString(filterString) {
    this.filterEvent.emit(filterString);
  }

}
