<!-- seconds FIELD -->
<!-- <div *ngIf="!subMetricsParamsData">
  <mat-card>
    <mat-spinner diameter="50" class="loading"></mat-spinner>
  </mat-card>
</div> -->
<mat-card class="sub-metrics__parameters" *ngIf="subMetricsParamsData">
  <div class="text-left sub-metrics__parameters-heading">
    <h4>{{metricFormName + ' Parameters '}}</h4>
    <small><b>Duration</b> is the minimum acceptable time to complete a single {{metricFormName}} <b style="color:red">{{alertmsg}}</b>
    </small>
  </div>
  <div class="row parameters-field-inline" [formGroup]="metricForm">
    <div [formArrayName]="subMetricFormName" class="col-sm-12 formArray-row"
         *ngFor="let subDiary of metricForm.get(subMetricFormName).controls; let i=index;trackBy:track">
      <ng-container [formGroupName]="i">
        <div class="var-list-form">
          <b class="col-sm-3 field sub-name">{{subDiary.get('subMetricName').value}}</b>
          <span class="col-sm-1 toggle-sub">
            <mat-slide-toggle formControlName="active" [checked]="subDiary.get('active').value"
                              class="field"></mat-slide-toggle>
          </span>
          <span class="left-padding col-sm-4 " *ngIf="subDiary.get('active').value === true">
             Duration:
             <mat-form-field class="field" appearance="legacy">
               <input type="text" ErtDurationTime matInput formControlName="paramTime" pattern="^(\d?\d?\d):([0-5]?\d)$">
               <mat-hint align="end">Min: 00:01 </mat-hint>
               <mat-error>Incorrect format</mat-error>
             </mat-form-field>`
               <span class="parameters-field-inline__units">(mm:ss)</span>
          </span>
        </div>
      </ng-container>

    </div>

  </div>
<!--  <div class="row col-sm-12 justify-content-end form-container_button">-->
<!--    <button class="es-button -es-primary" type="submit"-->
<!--            [disabled]="!subMetricsForm.valid">Save-->
<!--    </button>-->
<!--  </div>-->
</mat-card>
