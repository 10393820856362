<div class="inline-table-container">
  <div class="inline-table__control-filter cluster">
      <span style="padding-top: 20px;"><input type="search" (keyup)="applyFilter($event.target.value)" placeholder="Search" /></span>
      <mat-paginator  class="pagination" #paginator [pageSize]="50" [pageSizeOptions]="[10, 20, 50, 100]"
                     class="inline-table__control paginator">
      </mat-paginator>
  </div>

  <!-- Table -->
  <div class="inline-table">
    <form>
      <mat-table mat-table [dataSource]="dataSource" class="ert-table" matSort multiTemplateDataRows>
        <!--  Columns -->
        <ng-container *ngFor="let column of columnNames; let columnIndex = index; trackBy:track"
                      matColumnDef="{{column}}">
          <mat-header-cell mat-header-cell *matHeaderCellDef
                           mat-sort-header> {{columnDetails[column]?.displayName || column}}
          </mat-header-cell>
          <mat-cell mat-cell *matCellDef="let data; let rowIndex = dataIndex">
            <!-- When Nothing is Ediatble -->
            <!-- <p *ngIf="rowIndex!==selectedRowIndex"> {{getFieldValue(data[column], column)}} </p> -->
            <!-- When only Provided fields are editable -->
            <div *ngIf="isFieldEditable(rowIndex, column);else notEditableField">
              <!-- Editable INPUT FIELD  -->
              <div *ngIf="fieldType(column) === fieldTypes.TEXT || fieldType(column) === fieldTypes.EMAIL">
                  <cr-input #input
                         placeholder="{{column}}"
                         [value]="getFieldValue(data, column)" [(ngModel)]="data[column]"
                         type="{{fieldType(column)}}" [ngModelOptions]="{standalone: true}"></cr-input>
                  <!-- <mat-hint align="end"></mat-hint> -->
                  <span class="form-error"
                        *ngIf="showError(data, data[column], column)"> {{ getColErrorMessage(column) }} </span>
              </div>

              <!-- Editable SELECT FIELD  -->
              <div *ngIf="fieldType(column) === fieldTypes.SELECT">
                  <select [(ngModel)]="data[column]" [ngModelOptions]="{standalone: true}">
                    <option *ngFor="let option of columnDetails[column].options" [value]="option.key">
                      {{option.value}}
                    </option>
                  </select>
              </div>
              <!-- Editable DATE FIELD  -->
              <div *ngIf="fieldType(column) === fieldTypes.DATE">
                <mat-form-field>
                  <input matInput #input placeholder="{{column}}" [value]="getFieldValue(data, column)"
                         [(ngModel)]="data[column]" [ngModelOptions]="{standalone: true}">>
                  <!-- <mat-hint align="end"></mat-hint> -->
                </mat-form-field>
              </div>
              <div *ngIf="fieldType(column) === fieldTypes.NUMBER ">
                  <cr-input *ngIf="data['format'] === 'seconds'; else regularTextInput" #input
                         placeholder="{{column}}" ErtDurationTime
                         [value]="getFieldValue(data, column)" [(ngModel)]="data[column]"
                         type="text" [min]="0" [ngModelOptions]="{standalone: true}"></cr-input>
                  <ng-template #regularTextInput>
                    <cr-input matInput #input placeholder="{{column}} {{data.config?.minValue || data.config?.minValue === 0 ? 'Min: ' + data.config?.minValue : '' }}
                    {{data.config?.maxValue ? '/Max: ' + data.config?.maxValue : ''}}"
                           [value]="getFieldValue(data, column)" [(ngModel)]="data[column]"
                           type="{{fieldType(column)}}" [min]="0" [ngModelOptions]="{standalone: true}"></cr-input>
                  </ng-template>
                  <span class="form-error"
                        *ngIf="showError(data, data[column], column)"> {{ getColErrorMessage(column) }} </span>
              </div>
              <!-- <div *ngIf="(dateFields && dateFields.indexOf(column) !== -1)">
              <mat-form-field>
                <input matInput #dateInput [matDatepicker]="picker" placeholder="PICK A DATE" [value]="data[column]"
                  [(ngModel)]="data[column]">
                <mat-datepicker #picker></mat-datepicker>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              </mat-form-field>
            </div> -->
              <!--
                TODO::Editable field having a link? -->
            </div>

            <!-- Not Editable fields -->
            <ng-template #notEditableField>
              <div *ngIf="column && column.toLowerCase()!=='action'">
                <!-- Render text if it is not hyperlink , expandable -->
                <span [ngClass]="columnDetails[column].class ? columnDetails[column].class + data[column]: null" *ngIf="hasValue(data[column]) && fieldType(column) !== fieldTypes.HYPERLINK
                            && fieldType(column) !== fieldTypes.EXPAND && fieldType(column)!== fieldTypes.TOGGLE">
                {{getFieldValue(data, column)}}
              </span>
                <!-- Render an expansion div button-->
                <span *ngIf="hasValue(data[column]) && fieldType(column) === fieldTypes.EXPAND"
                      (click)="toggleExpansion(data)">
                    <a class="hyperlink">{{getFieldValue(data, column)}}</a>
              </span>

                <!--Check if the column is of type hyperlink-->
                <span *ngIf="hasValue(data[column]) && fieldType(column) === fieldTypes.HYPERLINK">
                  <!--Check if the row contains a link then render link, else render text-->
                    <span *ngIf="fieldType(column) === fieldTypes.HYPERLINK" (click)="linkClick(data)">
                        <a class="hyperlink">{{getFieldValue(data, column)}}</a>
                    </span>
                    <span *ngIf="fieldType(column) !== fieldTypes.HYPERLINK">
                        {{getFieldValue(data, column)}}
                    </span>
              </span>

                <!--Render a toggle checkbox-->
                <span *ngIf="hasValue(data[column]) && fieldType(column) === fieldTypes.TOGGLE"
                >
                  <mat-label ngClass="toggleLabel">Inactive</mat-label>
                      <mat-slide-toggle
                        (click)="toggleCheckbox($event, data)"
                        [checked]="toggleCheck(data)">Active</mat-slide-toggle>
              </span>


                <ert-no-value
                  *ngIf="!hasValue(data[column]) && columnDetails[column]?.actions !== 'checkbox'"></ert-no-value>
              </div>
            </ng-template>


            <!--Checbox-->
            <div class="fieldset-item cluster">
              <input type="checkbox" 
              *ngIf="columnDetails[column]?.actions === 'checkbox' && checkCheckBox(data)"
              [disabled]="setCheckboxStatus(data)"
              (change)="columnChecked(data)"
              data-type="primary">
            </div>
            <!-- ACTION ICONS -->

            <!-- Delete Icon -->
            <button id='delete{{rowIndex}}'  class="icon-button cursor-pointer"
            *ngIf="hasDeleteIcon
            && (column && column.toLowerCase()==='action'
            && columnDetails[column]?.actions === 'actionAll'
            && rowIndex!==selectedRowIndex )" cr-tooltip="Delete row"
            (click)="deleteRow(data, rowIndex)" [disabled]="!hasConditionalDeleteIcon(data)">
              <svg viewBox="0 0 448 512" [class.disabled]="!hasConditionalDeleteIcon(data)">
                <path d="M177.1 48h93.7c2.7 0 5.2 1.3 6.7 3.6l19 28.4h-145l19-28.4c1.5-2.2 4-3.6 6.7-3.6zM354.2 80L317.5 24.9C307.1 9.4 289.6 0 270.9 0H177.1c-18.7 0-36.2 9.4-46.6 24.9L93.8 80H80.1 32 24C10.7 80 0 90.7 0 104s10.7 24 24 24H35.6L59.6 452.7c2.5 33.4 30.3 59.3 63.8 59.3H324.6c33.5 0 61.3-25.9 63.8-59.3L412.4 128H424c13.3 0 24-10.7 24-24s-10.7-24-24-24h-8H367.9 354.2zm10.1 48L340.5 449.2c-.6 8.4-7.6 14.8-16 14.8H123.4c-8.4 0-15.3-6.5-16-14.8L83.7 128H364.3z"/>
              </svg>
            </button>

            <!-- Edit icon -->
            <button 
            id='edit{{rowIndex}}' 
            *ngIf="hasEditIcon             
            && (column && column.toLowerCase()==='action'
            && rowIndex!==selectedRowIndex )"
            cr-tooltip="Edit row"
            class="icon-button cursor-pointer"
            (click)="editRow(data, rowIndex)" [disabled]="!hasConditionalEditIcon(data)">
              <svg viewBox="0 0 512 512"  [class.disabled]="!hasConditionalEditIcon(data)">
                <path d="M36.37 360.9C40.53 346.8 48.16 333.9 58.57 323.5L362.7 19.32C387.7-5.678 428.3-5.678 453.3 19.32L492.7 58.75C495.8 61.87 498.5 65.24 500.9 68.79C517.3 93.63 514.6 127.4 492.7 149.3L188.5 453.4C187.2 454.7 185.9 455.1 184.5 457.2C174.9 465.7 163.5 471.1 151.1 475.6L30.77 511C22.35 513.5 13.24 511.2 7.03 504.1C.8198 498.8-1.502 489.7 .976 481.2L36.37 360.9zM59.44 452.6L137.6 429.6C139.9 428.9 142.2 427.1 144.4 426.9L108.1 419.6C100.2 418 93.97 411.8 92.39 403.9L85.13 367.6C84.02 369.8 83.11 372.1 82.42 374.4L59.44 452.6zM180.7 393.3L383 191L320.1 128.1L118.7 331.3L128.1 383L180.7 393.3z"/>
              </svg>
            </button>

            <!-- Copy icon -->
            <button 
            id='copy{{rowIndex}}'
            *ngIf="hasCopyIcon
            && (column && column.toLowerCase()==='action'
            && columnDetails[column]?.actions === 'actionAll'
            && rowIndex!==selectedRowIndex )"
            cr-tooltip="Copy row"
            class="icon-button cursor-pointer"
            (click)="copyRow(data, rowIndex)" [disabled]="!hasConditionalCopyIcon(data)">
              <svg viewBox="0 0 512 512" [class.disabled]="!hasConditionalCopyIcon(data)">
                <path d="M448 384H256c-35.3 0-64-28.7-64-64V64c0-35.3 28.7-64 64-64H396.1c12.7 0 24.9 5.1 33.9 14.1l67.9 67.9c9 9 14.1 21.2 14.1 33.9V320c0 35.3-28.7 64-64 64zM64 128h96v48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H256c8.8 0 16-7.2 16-16V416h48v32c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V192c0-35.3 28.7-64 64-64z"/>
              </svg>
            </button>

            <!-- Info icon -->
            <button 
            id='info{{rowIndex}}'
            *ngIf="hasInfoIcon && hasConditionalInfoIcon(data)
            && (column && column.toLowerCase()==='action'
            && columnDetails[column]?.actions === 'actionAll'
            && rowIndex!==selectedRowIndex )"
            cr-tooltip="More Info"
            class="icon-button cursor-pointer"
            (click)="InfoRow(data, rowIndex)">
              <svg fill="currentColor" viewBox="0 0 512 512">
                <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336c-13.3 0-24 10.7-24 24s10.7 24 24 24h80c13.3 0 24-10.7 24-24s-10.7-24-24-24h-8V248c0-13.3-10.7-24-24-24H216c-13.3 0-24 10.7-24 24s10.7 24 24 24h24v64H216zm40-144a32 32 0 1 0 0-64 32 32 0 1 0 0 64z"/>
              </svg>
            </button>

            <!-- Disable  -->
            <span [ngClass]="{'isDisabled': disableSave(data)}">
              <button class="icon-button cursor-pointer" (click)="saveRow(data)"
                *ngIf="(column && column.toLowerCase() === 'action' && rowIndex === selectedRowIndex)">
                <svg viewBox="0 0 448 512" [style.color]="disableSave(data) ? 'green' : ''" fill="currentColor" cr-tooltip="Update row">
                  <path d="M441 103c9.4 9.4 9.4 24.6 0 33.9L177 401c-9.4 9.4-24.6 9.4-33.9 0L7 265c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l119 119L407 103c9.4-9.4 24.6-9.4 33.9 0z"/>
                </svg>
              </button>
            </span>
            


            <!-- Clear icon -->
            <button *ngIf="(column && column.toLowerCase()==='action' && rowIndex===selectedRowIndex )" class="icon-button cursor-pointer" (click)="cancelEditRow($event, data)">
              <svg viewBox="0 0 384 512" (click)="cancelEditRow($event, data)" fill="currentColor" cr-tooltip="Cancel" style="color:'red'">
                <path d="M345 137c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-119 119L73 103c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l119 119L39 375c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l119-119L311 409c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-119-119L345 137z"/></svg>
            </button>
          </mat-cell>
        </ng-container>


        <ng-container matColumnDef="showSiteStudySQL">
          <td mat-cell ngClass="displaySqlRow" *matCellDef="let element" [attr.colspan]="columnNames.length">
            <div class="element-detail" [@detailExpand]="checkExpanded(element) ? 'expanded' : 'collapsed'">
              <div class="create-variable__write-sql">
                <div class="create-variable__write-sql-study">
                  <legend><b>Study SQL </b></legend>
                  <div>
                    <ert-code-mirror [config]="element.studySQL" [codeMirrorId]="element.studySQL.id"></ert-code-mirror>
                  </div>
                </div>
                <div class="create-variable__write-sql-site">
                  <legend><b>Site SQL</b></legend>
                  <div>
                    <ert-code-mirror [config]="element.siteSQL" [codeMirrorId]="element.siteSQL.id"></ert-code-mirror>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="showAssociatedStudies">
          <td mat-cell ngClass="displaySqlRow" *matCellDef="let element" [attr.colspan]="columnNames.length">
            <div class="element-detail associatedStudies"
                 [@detailExpand]="checkExpanded(element) ? 'expanded' : 'collapsed'">
              <h4 class="associatedStudiesTitle">Associated Studies</h4>
              <mat-list class="associatedStudiesList">
                <mat-list-item class="associatedStudiesListItem"
                               *ngFor="let study of element.studies">{{study}}</mat-list-item>
              </mat-list>

            </div>
          </td>
        </ng-container>

        <!-- Rows -->
        <mat-header-row mat-header-row *matHeaderRowDef="columnNames"></mat-header-row>
        <mat-row mat-row *matRowDef="let row; columns: columnNames;"

                 [ngClass]="{'highlight': selectedRowIndex == row.id , 'inactive': setRowStatus(row), 'checked':isRowChecked(row),
          'no-border':hasExapandable, 'no-height':hasExapandable }"
        >
        </mat-row>
        <div *ngIf="hasExapandable">
          <mat-row mat-row *matRowDef="let row; columns: [expandableTemplate]" class="detail-row no-height"></mat-row>
        </div>

      </mat-table>
    </form>
    </div>
</div>


