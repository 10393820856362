<div class="toolbar">
  <div class="flex-spacer" #toolbarFlexSpacer></div>

  <div class="dropdown-menu" *ngIf="toolbarData.dropdownMenu">
    <mat-form-field >
      <input #search matInput [placeholder]="toolbarData.dropdownMenu.label" aria-label="Select Site" [matAutocomplete]="auto"
        [formControl]="formCtrl">
	</mat-form-field>
	<mat-autocomplete #auto="matAutocomplete" [displayWith]="toolbarData.dropdownMenu.displayItem">
		<mat-option *ngFor="let item of filteredItems | async" [value]="item" (onSelectionChange)="onItemSelected(item)">
			<span>{{ item[toolbarData.dropdownMenu.propertyName] }}</span>
		</mat-option>
	</mat-autocomplete>
  </div>
</div>
