
interface Bin {
  dim: number;
  max: number;
  points: any[];
}

export class PointFilter {
  private bins: Bin[];
  private minPt: number[];
  private maxPt: number[];
  private dim: number;

  constructor(points: any, count: number, dim: number) {
      this.bins = [];
      this.minPt = points[0];
      this.maxPt = points[points.length - 1];
      const interval = (this.maxPt[dim] - this.minPt[dim]) / (count - 1);
      this.dim = dim;
      for (let i = 0; i < count - 1; i++) {
          this.bins.push({
              dim: dim,
              max: this.minPt[dim] + (i + 1) * interval,
              points: []
          });
      }
      // numerical insurance
      this.bins[this.bins.length - 1].max = this.maxPt[dim];
      // fill
      for (let i = 0; i < points.length - 2; i++) { // do not add last point
          this.insert(points[i]);
      }
  }

  insert(val: number[]) {
      for (let i = 0; i < this.bins.length; i++) {
          if (val[this.dim] <= this.bins[i].max) {
              this.bins[i].points.push(val);
              return;
          }
      }
  }

  avg() {
      const back = [];
      // always insert min Point
      back.push(this.minPt);
      for (let i = 0; i < this.bins.length - 1; i++) {
          const e = this.bins[i];
          const sum = [0, 0];
          if (e.points.length > 0) {
              e.points.forEach(pt => {
                  sum[0] += pt[0];
                  sum[1] += pt[1];
              });
              back.push([Math.floor(sum[0] / e.points.length), Math.floor(sum[1] / e.points.length)]);
          }
      }
      back.push(this.maxPt);
      return back;
  }

  ravg(a = 0.7) {
      const back = [];
      // always insert min Point
      back.push(this.minPt);
      for (let i = 0; i < this.bins.length - 1; i++) {
          const e = this.bins[i];
          const sum = [0, 0];
          if (e.points.length > 1) {
              sum[0] = e.points[0][0];
              sum[1] = e.points[0][1];
              for (let j = 1; j < e.points.length; j++) {
                  sum[0] = a * e.points[j][0] + (1.0 - a) * sum[0];
                  sum[1] = a * e.points[j][1] + (1.0 - a) * sum[1];
              }
              back.push([Math.floor(sum[0]), Math.floor(sum[1])]);
          }
      }
      back.push(this.maxPt);
      return back;
  }
}
