<h2 class="task-complete__header">Complete Task</h2>
<form [formGroup]="completeTaskForm" (ngSubmit)="complete(completeTaskForm)">
    <table class="alert-table">
        <tr>
            <td><b>Alert:</b></td>
            <td class="item">{{data.alertTitle}}</td>
        </tr>
        <tr>
            <td><b>Task:</b></td>
            <td class="item">{{data.taskTitle}}</td>
        </tr>
        <tr>
            <td><b>Due Date:</b></td>
            <td class="item">{{data.dueDate | displayDate}}</td>
        </tr>
        <tr>
            <td colspan="2"><b>Description:</b></td>
        </tr>
        <tr>
            <td colspan="2" class="desc">
                {{data.taskDescription}}
            </td>
        </tr>
        <tr>
            <td colspan="2"></td>
        </tr>
        <tr>
            <td colspan="2" class="desc">
                <mat-form-field style="width: 100%;margin-top: 10px;">
                    <textarea rows="5" matInput onkeyup="this.value = this.value[0].toUpperCase() + this.value.substr(1)"
                        placeholder="Action Taken" formControlName="taskComment"></textarea>
                </mat-form-field>
            </td>
        </tr>
        <tr>
            <td colspan="2">
              <button class="es-button -es-primary" type="submit" [disabled]="!completeTaskForm.valid">Complete Task</button>
            </td>
        </tr>
    </table>
</form>
