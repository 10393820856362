import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, UntypedFormArray } from '@angular/forms';
import { Subject } from 'rxjs';

import {FieldBaseGroup, FieldsBase} from './form-base';

@Injectable({
  providedIn: 'root'
})
export class FormGeneratorService {
  group: any = {};
  private getFieldChangesSubject = new Subject<any>();
  getFielValues = this.getFieldChangesSubject.asObservable();

  constructor() { }

  setFormGroup(fieldsData: FieldBaseGroup[]): UntypedFormGroup {
    this.group = {};
    fieldsData.forEach(field => {
      if (field.fieldGroupName) { // FormGroup, list of fields
        const fieldsGroup = {};
        field.fieldsList.forEach(f => {
          fieldsGroup[f.name] = fieldsGroup[f.name] || this.setFormControl(f);
        });
        this.group[field.fieldGroupName] = new UntypedFormGroup(fieldsGroup);
      } else {
        // single field, formControl
        this.group[field.name] = this.group[field.name] || this.setFormControl(field);
      }
    });
    return new UntypedFormGroup(this.group);
  }

  setFormControl(field: any) {
    const validations = field.getValidators();
    // const required = this.isRequired(validations) ? Validators.required : '';
    if (!this.group[field.name]) {
      return new UntypedFormControl(field.value, validations);
    }
  }

  seFormGroup(fields) {
    return new UntypedFormGroup(fields);
  }

  addToFormArray(form, field, newFields) {
    const control = (<UntypedFormArray>form.controls[field]) as UntypedFormArray;
    control.push(this.setFormControl(newFields));
  }

  setFieldValues(values) {
    this.getFieldChangesSubject.next(values);
  }

  patchValues(form, formData) {
    return form.patchValue(formData);
  }
}
