<h4>Add Study</h4>
<mat-form-field>
  <mat-select matNativeControl [(ngModel)]="selectedStudy">
    <mat-option value="null">Select Study</mat-option>
    <mat-option *ngFor="let study of filStudyServiceStudyList" value={{study.ertStudyId}}>
      {{study.ertStudyName}}
    </mat-option>
  </mat-select>
</mat-form-field>

<div class="add_study_buttons">
  <button class="button cursor-pointer" (click)="onNoClick()">Cancel</button>
  <div class="add_button">
    <button class="button cursor-pointer"   [ngStyle]="{'background-color': selectedStudy == 'null'  ? '#bcbdbc' :'#0092bc', 'border-color': selectedStudy == 'null' ? '#bcbdbc' : '#0092bc'}" 
      [disabled]="selectedStudy == 'null'" 
      [color]="'primary'"
      [class.mat-raised-button] = "true"
      (click)="addStudy()">Add</button>
  </div>
</div>
