import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray, UntypedFormControl, Validators } from '@angular/forms';
import { SubMetricsConfigService } from '@app/settings/admin/study-parameters-config/sub-metrics-config/sub-metrics-config.service';
import { ActivatedRoute } from '@angular/router';
import { Subject, zip } from 'rxjs';

@Component({
  selector: 'ert-sub-metrics-config',
  templateUrl: './sub-metrics-config.component.html',
  styleUrls: [ './sub-metrics-config.component.scss' ]
})
export class SubMetricsConfigComponent implements OnInit, OnDestroy {
  @Input() metricForm: UntypedFormGroup;
  @Input() metricFormName: string;
  subMetricsForm: UntypedFormGroup;
  subMetricFormName = "subMetricFormName";
  private unsubscribe$: Subject<void> = new Subject<void>();
  studyId: number;
  metricId: number;
  subMetricsParamsData: any;
  alertmsg: string;
  defaults: any;

  constructor(
    private route: ActivatedRoute,
    private subMetricsConfigService: SubMetricsConfigService,
  ) {
    this.route.params.subscribe(params => {
      this.studyId = +params.studyId;
      this.metricId = +params.metricId;
    });
  }

  ngOnInit() {
    this.getSubMetricFormIds();
  }

  getSubMetricFormIds() {
      //get the details from DI DB
      zip(
      this.subMetricsConfigService.getSubMetric(this.studyId, this.metricId),
      this.subMetricsConfigService.getSubMetricDefaults()
      ).subscribe(([result, defaults]) => {
        this.defaults = defaults;
        if(result && result.length > 0) {
          let forms = result.sort((a, b) => (a.subMetricName > b.subMetricName) ? 1 : -1);
          this.subMetricsParamsData = forms.map(form => {
            let seconds = Math.floor(form.settings.durSeconds/60)+":"+(form.settings.durSeconds%60)
            return {
                id: form.calcTypeHierarchyId,
                subMetricName: form.subMetricName,
                paramTime: seconds, 
                active: form.active,
                formId: form.subMetricId
              };
            });
            this.setSubMetricParamsForm();
        } else {
          this.getODSSubMetricForm();
        }
      },
      (err) => {
        return;
      }
    );
  }

  getODSSubMetricForm() {
    // use aws configure to make it work in local
    this.subMetricsConfigService.getSubMetricFormIds(this.studyId, this.metricId).subscribe(parameters => {
      if(parameters && parameters.rows && parameters.rows.length) {
        this.alertmsg = "(The parameter values have never been saved by the user.)";
        let isDurationLow = (this.metricFormName.toLowerCase().indexOf('low') > 1)? true : false;
        let forms = parameters.rows.sort((a, b) => (a.FORMNAME > b.FORMNAME) ? 1 : -1);
        this.subMetricsParamsData = forms.map(form => {
          let defaultValue = '00:00';
          if(this.defaults) {
            let result = this.defaults.filter(v => v.formName.trim().toLowerCase() === form.EXT_NAME.toLowerCase());
            if(result.length > 0) {
              let roundedValue = (isDurationLow) ? Math.round(result[0].minValue) : Math.round(result[0].maxValue);
              defaultValue = Math.floor(roundedValue/60)+":"+(roundedValue%60)
            }
          }
          return {
              id: -1,
              subMetricName: form.FORMNAME,
              paramTime: defaultValue, // TODO default value has to fill
              active: true,
              formId: form.EXT_OID
            };
          });
        this.setSubMetricParamsForm();
      }
    },(err) => {
          return;
    });
  }

  setSubMetricParamsForm() {
    this.metricForm.addControl(this.subMetricFormName, new UntypedFormArray([]));

    this.subMetricsParamsData.forEach(formParam => {
      this.addSubMetricFormArray(formParam);
    })
  }

  /**
   * Get  FormArray as array and will be referred by index 0
   */
  get subMetricsFormArray() {
    return this.metricForm.get(this.subMetricFormName) as UntypedFormArray;
  }

  addSubMetricFormArray(subMetricData) {
    const { subMetricName, paramTime='', active = true, formId, id} = subMetricData;
    const variableFormGroup = new UntypedFormGroup({
      id: new UntypedFormControl(id),
      subMetricName: new UntypedFormControl({ value: subMetricName, disabled: true }),
      paramTime: new UntypedFormControl(paramTime),
      active: new UntypedFormControl(active),
      formId: new UntypedFormControl(formId)
    });

    this.subMetricsFormArray.push(variableFormGroup);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
