
<h4>Reason for change</h4>
<form class="row">
  <cr-input type="text" [formControl]="reasonForChangeControl" [(ngModel)]="reasonForChange"></cr-input>
  <mat-error *ngIf="reasonForChangeControl.hasError('required')">
    Reason for change is <strong>required</strong>
  </mat-error>


<div class="add_parameter_buttons" style="display:flex">
  <button class="button cursor-pointer" (click)="onNoClick()">Cancel</button>
  <div class="confirm_button">
    <ert-spinner-button buttonText="Confirm" [disabled]="reasonForChange.trim().length <= 0" (click)="onConfirm()">
    </ert-spinner-button>
  </div>
</div>
</form>