import { Component, OnInit, ViewChild, ElementRef, ViewEncapsulation, Input, AfterContentInit, OnChanges, OnDestroy } from '@angular/core';
import * as d3 from 'd3';
import { CommunicationEventsService } from "@app/shared/services/communication/communication-events.service";
import { Store } from "@ngrx/store";
import { takeUntil, catchError } from "rxjs/operators";
import { Subject, of } from "rxjs";

export interface HStackedBarChartData {
    srcStudyId: number;
    studyName: string;
    protocolName: string;
    protocolNumber: string;
    srcSiteId: number;
    siteNo: string;
    activeAlerts: number;
    bucket_quality_score: string;
    bucket_compliance_score: string;

    complianceScore: number;
    qualityScore: number;
    eventsToScoresRatio: number;

    yDomain: number;
    yDomainText: string;
}

const legendBgPadding = 30;
const legendSidePadding = 10;
const legendSquareTextPadding = 5;
const legendSquareWidth = 18;
const legendSqaureHeight = 18;
const barGrandHeight = 18;
const barsInnerPadding = 0.2;
const barsOuterPadding = 0.08;
const xAxisHeight = 25;
let tooltipD = null;

@Component({
  selector: 'ert-h-stacked-bar-chart',
  templateUrl: './h-stacked-bar-chart.component.html',
  styleUrls: ['./h-stacked-bar-chart.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HStackedBarChartComponent implements OnInit, OnChanges, OnDestroy {

  @ViewChild('hStackedBarChartLeft', {static: true }) private chartContainerLeft: ElementRef;
  @ViewChild('hStackedBarChartRight', {static: true }) private chartContainerRight: ElementRef;
  @ViewChild('hStackedBarChartTop', {static: true }) private chartContainerTop: ElementRef;
  @ViewChild('hStackedBarChartMiddle', {static: true }) private chartContainerMiddle: ElementRef;
  @ViewChild('hStackedBarChartBottom', {static: true }) private chartContainerBottom: ElementRef;
  @ViewChild('hStackedBarChartMiddleTooltipWrapper', { static: true }) private hStackedBarChartMiddleTooltipWrapper: ElementRef;

  @Input() private data: HStackedBarChartData[];
  @Input() private xAxisLabel: string = 'Active Events';
  @Input() private yAxisLabel: string = 'Study Sites';
  @Input() private selectedStudiesData: any[];

  private unsubscribe$: Subject<void> = new Subject<void>();

  private keys = ['complianceScore', 'qualityScore'];
  private keyLegends = ['Compliance', 'Quality'];
  private keyColors = ['#1692ba', '#90368c'];

  private firstDraw = true;
  private margin = { top: 5, right: 5, bottom: 5, left: 5 };

  private leftSvg: any;
  private topSvg: any;
  private middleSvg: any;
  private bottomSvg: any;

  private tooltipWrapper: any;

  constructor(
    private communicationEvents: CommunicationEventsService,
    private store: Store<any>
  ) { }

  ngOnInit() {
    // if (this.data) {
    //   this.preprocessData();
    // }
  }

  ngOnChanges(changes) {
    if (this.data) {
      this.preprocessData();
      this.drawChart();
      this.firstDraw = false;
      window.addEventListener("resize", this.drawChart.bind(this));

      if (this.selectedStudiesData.length) {
        const studyId = this.selectedStudiesData[0].studyId;
        const siteIds =
          studyId &&
          this.selectedStudiesData[0].siteIds &&
          this.selectedStudiesData[0].siteIds || [];
        this.registerEvent(studyId, siteIds);
      }
    }
  }

  // ngAfterContentInit() {
  //   this.drawChart();
  //   this.firstDraw = false;
  //   window.addEventListener('resize', this.drawChart.bind(this));
  // }

  ngOnDestroy() {
    window.removeEventListener('resize', this.drawChart.bind(this));
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private preprocessData() {
    let i = 0;
    this.data.forEach(d => {
      d.activeAlerts = +d.activeAlerts;
      d.complianceScore = +d.bucket_compliance_score;
      d.qualityScore = +d.bucket_quality_score;
      d.eventsToScoresRatio = d.activeAlerts / (d.complianceScore + d.qualityScore);
      d.yDomain = ++i;
      d.yDomainText = `${d.studyName} ${d.siteNo}`;
    });
    this.data.sort((a, b) => b.activeAlerts - a.activeAlerts);
  }

  private drawChart() {
    const elementLeft = this.chartContainerLeft.nativeElement;
    const elementTop = this.chartContainerTop.nativeElement;
    const elementMiddle = this.chartContainerMiddle.nativeElement;
    const elementBottom = this.chartContainerBottom.nativeElement;
    const elementTooltipWrapper = this.hStackedBarChartMiddleTooltipWrapper.nativeElement;

    // NO svg created, only to get the height for Y-Axis
    const elementRight = this.chartContainerRight.nativeElement;

    if (!this.firstDraw) {
      this.preprocessData();
      d3.select(elementLeft).selectAll('svg').remove();
      d3.select(elementTop).selectAll('svg').remove();
      d3.select(elementMiddle).selectAll('svg').remove();
      d3.select(elementBottom).selectAll('svg').remove();
      d3.select(elementTooltipWrapper).selectAll('div').remove();
      this.leftSvg = null;
      this.topSvg = null;
      this.middleSvg = null;
      this.bottomSvg = null;
      this.tooltipWrapper = null;
    }

    const leftElementWidth = elementLeft.offsetWidth;
    const leftElementHeight = elementRight.offsetHeight;

    // TOP legend
    // stackedBarChartTotalWidth: in order to align in the middle of the chart including Y-Axis
    const stackedBarChartTotalWidth = leftElementWidth + elementTop.offsetWidth;
    const legendsTotalHeight = elementTop.offsetHeight;
    const legendsWidth = stackedBarChartTotalWidth - this.margin.left - this.margin.right;
    const firstLegendX = - elementLeft.offsetWidth + this.margin.left ; // may be negative
    const firstLegendY = this.margin.top;

    this.topSvg = d3.select(elementTop)
      .append('svg')
      .attr('width', '100%')
      .attr('height', '100%');
    const topRootG = this.topSvg.append('g')
      .attr('id', 'topRootGroup');

    const legendBlockWidth = legendsWidth / this.keyLegends.length;
    const legendPositions = [];
    for (let i = 0; i < this.keyLegends.length; i++) {
      const pos = {
        groupX: firstLegendX + i * legendBlockWidth,
        groupY: firstLegendY,
        groupW: legendBlockWidth,
        groupH: legendsTotalHeight
      };
      legendPositions.push(pos);
    }

    const legendTextWH = [];
    topRootG.selectAll('.dummyText')
      .data(this.keyLegends)
      .enter().append('text')
      .text(function(d) { return d; })
      .each(function(d, i) {
        const txWidth = this.getComputedTextLength();
        const cRect = this.getBoundingClientRect();
        const txHeight = cRect.height;
        legendTextWH.push({
          text: d,
          width: Math.round(txWidth),
          height: Math.round(txHeight)
        });
        this.remove(); // remove them just after displaying them
    });

    for (let i = 0; i < this.keyLegends.length; i++) {
      const pos = legendPositions[i];
      if (i === 0) { // first legend: align right
        pos.textX = legendPositions[1].groupX - legendSidePadding - legendTextWH[i].width;
        pos.rectX = pos.textX  - legendSquareTextPadding - legendSquareWidth;
        pos.rectY = pos.groupY;
        pos.textY = Math.round(pos.rectY + legendSqaureHeight / 2);
        pos.left = pos.rectX;
        pos.right = pos.textX + legendTextWH[i].width;
      } else {
        pos.rectX = pos.groupX + legendSidePadding;
        pos.rectY = pos.groupY;
        pos.textX = pos.rectX + legendSquareWidth + legendSquareTextPadding;
        pos.textY = Math.round(pos.rectY + legendSqaureHeight / 2);
        pos.left = pos.rectX;
        pos.right = pos.textX + legendTextWH[i].width;
      }
    }

    // Background Rect for legend
    const legendBgRectX = legendPositions[0].left - legendBgPadding;
    const legendBgRectW = legendPositions[legendPositions.length - 1].right - legendPositions[0].left + 2 * legendBgPadding;
    const legendBgRectY = 0;
    const legendBgRectH = legendPositions[0].groupH;
    topRootG.append('g')
      .attr('id', 'legendBgGroup')
      .append('rect')
      .attr('x', legendBgRectX)
      .attr('y', legendBgRectY)
      .attr('width', legendBgRectW)
      .attr('height', legendBgRectH)
      .style('fill', '#f8f8f8')
      .style('z-index', '0');

    const legendG = topRootG.append('g')
      .attr('id', 'legendGroup')
      .selectAll('g')
      .data(this.keyLegends)
      .enter().append('g')
      .attr('transform', (d, i) => `translate(0, ${legendPositions[i].groupY})`)
      .style('font-family', '"Noto Sans", Arial, Helvetica, sans-serif')
      .style('font-size', '12px')
      .style('fill', 'black');

    legendG.append('text')
      .attr('x', (d, i) => legendPositions[i].textX)
      .attr('y', (d, i) => legendPositions[i].textY)
      .text(function(d) { return d; });

    // Score Colors
    const zScale = d3.scaleOrdinal()
      .domain(this.keys)
      .range(this.keyColors);

    legendG.append('rect')
      .attr('x', (d, i) => legendPositions[i].rectX)
      .attr('width', legendSquareWidth)
      .attr('height', legendSqaureHeight)
      .attr('fill', zScale);

    // MIDDLE bars
    const chartWidth = elementTop.offsetWidth - this.margin.right;
    const chartHeight = barGrandHeight * this.data.length;
    const barsHeight = chartHeight;
    const xAxisWidth = chartWidth;

    // Active Alerts
    const maxActiveAlerts = d3.max(this.data, d => d.activeAlerts);
    const xScale = d3.scaleLinear()
      .domain([0, maxActiveAlerts])
      .range([0, xAxisWidth - 10]);

    // Study Sites
    const yScale = d3.scaleBand()
      .domain(this.data.map(d => d.yDomain))
      .rangeRound([0, barsHeight])
      .paddingInner(barsInnerPadding)
      .paddingOuter(barsOuterPadding);

    this.middleSvg = d3.select(elementMiddle)
      .append('svg')
      .attr('height', chartHeight);

    const middleRootG = this.middleSvg.append('g')
      .attr('id', 'middleRootGroup');

    const barsG = middleRootG.append('g')
      .attr('id', 'barsGroup')
      .attr('transform', `translate(${this.margin.left}, 0)`);

    this.tooltipWrapper = d3.select(elementTooltipWrapper);

    const tooltip = this.tooltipWrapper.append('div')
      .attr('id', 'activeEventsTooltip')
      .attr('class', 'h_stacked_bar-tooltip')
      .style('position', 'absolute')
      .style('z-index', '10')
      .style('opacity', 0)
      .style('background', '#dedede')
      .style('font-size', '12px')
      .style('line-height', '1.6')
      .style('transform', 'translate(-50%, 1em)')
      .style('transition', 'all .15s ease-in-out')
      .style('padding', '5px')
      .style('border-radius', '2px')
      .style('font-family', '"Noto Sans"');

    barsG.selectAll('g')
      .data(d3.stack().keys(this.keys)(this.data))
      .enter().append('g')
        .attr('fill', d => zScale(d.key))
      .selectAll('rect')
      .data(d => d)
      .enter().append('rect')
        .filter((d) => {
          return this.selectedStudiesData.map( (study) => study.studyId === d.data.srcStudyId );
        })
        .attr('x', d => xScale(d[0] * d.data.eventsToScoresRatio))
        .attr('y', d => yScale(d.data.yDomain))
        .attr('width', d => xScale((d[1] * d.data.eventsToScoresRatio)) - xScale((d[0] * d.data.eventsToScoresRatio)))
        .attr('height', yScale.bandwidth())
        .attr('class', 'bar-row')
        .style('cursor', 'pointer')
        .on('mousemove', () => {
          // tooltip.style('left', (d3.event.pageX - 110) + 'px')
          //       .style('top', (d3.event.pageY - 23) + 'px');
        })
        .on('mouseover', (d, i, n) => {
          const element = d3.select(this.chartContainerMiddle.nativeElement);
          const elemRect = element.node().getBoundingClientRect();
          tooltip.html(() => {
            const toolTipHeading =
              `<span style="color:${this.keyColors[0]}">` +
              `<a href="/dashboard/study/${d.data.srcStudyId}">` +
              `<b>${d.data.studyName}</b>` +
              `</a> | <a href="/dashboard/study/${d.data.srcStudyId}/site/${d.data.srcSiteId}">` +
              `<b>${d.data.siteNo}</b>` +
              `</a></span><br>`;
            const toolTipText =
              `<span style="color:black">` +
              `<b>Compliance Score: </b> ${d.data.complianceScore}<br>` +
              `<b>Quality Score: </b> ${d.data.qualityScore}<br>` +
              `<b>Active Events: </b> ${d.data.activeAlerts}`;
            return `${toolTipHeading}${toolTipText}`;
          });

          tooltip.style('opacity', 0.9)
            .style('container', 'body')
            .style('pointer-events', 'auto');
          tooltip.on('mouseover', () => {
            tooltip.style('opacity', 0.9);
          })
          .on('mouseleave', () => {
            tooltip.style('opacity', 0).html('');
          });

          if (tooltipD !== d) {
            tooltipD = d;
            tooltip
              .style( 'left', (d3.event.pageX ) - (elemRect.left  + 125 ) + 'px' )
              .style( 'top', (d3.event.pageY ) - (elemRect.top  + 21 ) + 'px' );
          }
        })
        .on('mouseleave', function(d, i, n) {
          tooltip.style('opacity', 0);
        })
        .filter((d) => d.data.srcStudyId)
        .attr('data-study-id', (d) => d.data.srcStudyId)
        .filter((d) => d.data.siteNo)
        .attr('data-site-id', (d) => d.data.srcSiteId)
        .on('click', (d) => {
          const data = this.registerEvent();
          this.communicationEvents.singleClickEventListener(data);
       });


    // BOTTOM X-Axis
    const bottomHeight = elementBottom.offsetHeight;
    this.bottomSvg = d3.select(elementBottom)
      .append('svg')
      .attr('width', chartWidth)
      .attr('height', bottomHeight + 15);  // must set height

    const bottomRootG = this.bottomSvg.append('g')
      .attr('id', 'bottomRootGroup')
      .attr('transform', `translate(${this.margin.left}, 0)`);

    const xAxis = d3.axisBottom(xScale)
      .ticks(5)
      .tickSize(0);
    const xAxisG = bottomRootG.append('g')
      .attr('id', 'xAxisGroup');

    xAxisG.call(xAxis)
      .call(g => g.select('.domain').remove());   // erease the domain line (scale)

    const xAxisLabelTextX = Math.round(-leftElementWidth + stackedBarChartTotalWidth / 2);
    const xAxisLabelTextY = xAxisHeight;
    const xAxisLabelG = bottomRootG.append('g')
      .attr('id', 'xAxisLabelGroup');

    xAxisLabelG.append('text')
      .attr('x', xAxisLabelTextX)
      .attr('y', xAxisLabelTextY)
      .attr('text-anchor', 'middle')
      .text(this.xAxisLabel)
      .style('fill', 'grey')
      .style('font-family', '"Noto Sans", Arial, Helvetica, sans-serif')
      .style('font-size', '12px')
      .style('font-weight', 'bold');  // Active Events

    // LEFT Y-Axis
    this.leftSvg = d3.select(elementLeft)
      .append('svg')
      .attr('width', leftElementWidth)
      .attr('height', leftElementHeight - 5);  // must set height: -5 to avoid right side cliping on text

    const leftRootG = this.leftSvg.append('g')
      .attr('id', 'leftRootGroup');

    const yAxisG = leftRootG.append('g')
      .attr('id', 'yAxisGroup');

    const yAxisLabelX = Math.round(leftElementWidth / 3 * 2);
    const yAxisLabelY = Math.round(leftElementHeight / 2);
    yAxisG.append('text')
      .attr('x', yAxisLabelX)
      .attr('y', yAxisLabelY)
      .attr('transform', `rotate(-90, ${yAxisLabelX}, ${yAxisLabelY})`)
      .attr('text-anchor', 'middle')
      .text(this.yAxisLabel)
      .style('fill', 'grey')
      .style('font-family', '"Noto Sans", Arial, Helvetica, sans-serif')
      .style('font-size', '12px')
      .style('font-weight', 'bold');
  }

  registerEvent(study = null, siteIds = []) {
    const data = { studyId: null, siteIds: [], metricIds: null };

    if (d3 && d3.event) {
      d3.event.stopPropagation();
      d3.event.stopImmediatePropagation();

      study =  d3.event.target && d3.event.target.getAttribute('data-study-id') || study;
      siteIds = d3.event.target  && [d3.event.target.getAttribute('data-site-id')] || siteIds;
    }

    data.studyId = study;
    data.siteIds = siteIds;

    const isSelected = this.selectedStudiesData.find(s => s.studyId === +data.studyId );

    // don't show tooltip unless is study and site selection
    if (data.studyId && data.siteIds.length) {
      let dataSelection;
      if (data.siteIds.length > 0) {
        dataSelection = data.siteIds.map((siteNum) => `[data-study-id='${data.studyId}'][data-site-id='${siteNum}']` ) || [];
      }

      dataSelection = dataSelection && dataSelection.join(', ') || '';
      const container = d3.select(this.chartContainerMiddle.nativeElement);
      const barSelected = container.selectAll(dataSelection);
      const barSelectedClasses = barSelected.size() && barSelected.attr('class') || [];

      if ( !isSelected || (isSelected && !barSelectedClasses.includes('selected-study')) ) {
        barSelected.classed('selected-study', true);
        d3.selectAll('rect.bar-row:not(.selected-study)').style('opacity', .25);

        const selectedBarHeight = barSelected.node().getBoundingClientRect().y;
        const BarChartPosition = this.middleSvg.node().getBoundingClientRect().y + 110;

        container.transition().duration(1000).tween('scroll', scrollTopTween(selectedBarHeight - BarChartPosition));

        function scrollTopTween(scrollTop) {
          return function() {
            const self = this;
            const i = d3.interpolateNumber(self.scrollTop, scrollTop);
            return function(t) { self.scrollTop = i(t); };
          };
        }

      } else if (barSelectedClasses.includes('selected-study')) {
        barSelected.classed('selected-study', false);
      }
      return data;
    }
  }
}
