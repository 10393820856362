import { Injectable } from '@angular/core';
import { DataService, EndpointService } from '@shared/services';
import { Observable } from 'rxjs';
import { Study } from '@shared/models/study.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SubMetricsConfigService {

  constructor(
    private endpointService: EndpointService,
    private dataService: DataService<any>
  ) {
  }

  public getSubMetricFormIds(studyId, metricId): Observable<any> {
    const endpoint = this.endpointService.getEndpoint('subMetricFormIds', studyId, metricId );
    return this.dataService.getAll(endpoint).pipe(map((response: any) => response.result));
  }

  
  public getSubMetric(studyId, parentMetricId): Observable<any> {
    const endpoint = this.endpointService.getEndpoint('getSubMetric', studyId, parentMetricId );
    return this.dataService.getAll(endpoint).pipe(map((response: any) => response.result));
  }
  
  public getSubMetricDefaults(): Observable<any> {
    const endpoint = this.endpointService.getEndpoint('getSubMetricDefaults');
    return this.dataService.getAll(endpoint).pipe(map((response: any) => response.result));
  }
}
