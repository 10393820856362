import { Component, OnInit, ViewChildren, ElementRef, QueryList, Input, AfterViewInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { StudyService } from '@app/shared/services/study.service';
import { Observable } from 'rxjs';
import { Study } from '@app/shared/models/study.model';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import dateFormat from 'dateformat';

@Component({
  selector: 'ert-search-paginator',
  templateUrl: './search-paginator.component.html',
  styleUrls: ['./search-paginator.component.scss']
})
export class SearchPaginatorComponent implements OnInit, AfterViewInit {
  @ViewChildren('search') comps: QueryList<ElementRef>;
  @Input() ds: MatTableDataSource<any>;
  @Input() dateColumn: string;


  search: ElementRef;
  private unsubscribe$: Subject<void> = new Subject<void>();
  private startDate;
  private readonly initStartDate: Date = new Date('1980-01-01T00:00:00.000Z');
  private endDate;
  private readonly initEndDate: Date = new Date('2200-01-01T00:00:00.000Z');
  private filterSubject: Subject<string> = new Subject();
  studies=[];
  allStudies: string;
  renderData: any;
  typeOfAudit: string;
  selector = '';
  startDateValue: Date;
  endDateValue: Date;
  name = '';
  // dateFormat = require('dateformat');
  toDate: any;
  fromDate: any;

  constructor(private store: Store<any>, private study: StudyService) {
    this.startDate = this.initStartDate;
    this.endDate = this.initEndDate;
    if (sessionStorage.getItem('studyChosen') === undefined || sessionStorage.getItem('studyChosen') === null) {
      this.allStudies = null;

    } else {
      this.allStudies = sessionStorage.getItem('studyChosen');
    }
  }

  ngOnInit() {
    this.study.getStudies().subscribe(dm => {
      this.studies = dm;
    });
    this.store.select('authState')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(authReducer => {
        this.name = `${authReducer.firstName}_${authReducer.lastName}`;
      });

  }


  loadAll() {
    this.filterSubject.next(null);
  }

  ngAfterViewInit(): void {
    if (this.comps.length > 0) {
      this.getSearchElementRef(this.comps);
    } else {
      this.comps.changes.subscribe((comps: QueryList<ElementRef>) => {
        this.getSearchElementRef(comps);
      });
    }
    setTimeout(() => {
      this.ds.filterPredicate = this.customFilter;
      if (sessionStorage.getItem('studyChosen') === undefined || sessionStorage.getItem('studyChosen') === null) {
        this.filterSubject.next('');
        this.allStudies = null;

      } else {
        this.applyFilter(this.generateFilterVal(String(sessionStorage.getItem('studyChosen'))))
        this.allStudies = sessionStorage.getItem('studyChosen');
      }
    });
  }

  resetStartDate() {
    this.fromDate = null;
    this.startDateChange(this.fromDate);
  }

  resetEndDate() {
    this.toDate = null;
    this.endDateChange(this.toDate);
  }


  searchInputChange(searchVal: string) {
    sessionStorage.setItem('studyChosen', searchVal);
    this.filterSubject.next(searchVal);
    this.renderData = this.ds.filteredData;
    this.allStudies = searchVal;
  }

  startDateChange(st: Date) {
    if (!!st) {
      const st_utc = new Date(Date.UTC(st.getFullYear(), st.getMonth(), st.getDate()));
      this.startDate = st_utc.toUTCString();
    } else {
      this.startDate = this.initStartDate.toDateString();
    }
    if (sessionStorage.getItem('studyChosen') === undefined || sessionStorage.getItem('studyChosen') === null) {
      this.filterSubject.next('');

    } else {
      this.filterSubject.next(String(sessionStorage.getItem('studyChosen')));
    }
  }

  endDateChange(et: Date) {
    if (!!et) {
      const et_utc = new Date(Date.UTC(et.getFullYear(), et.getMonth(), (et.getDate() + 1)));
      this.endDate = et_utc.toUTCString();
    } else {
      this.endDate = this.initEndDate.toDateString();
    }
    if (sessionStorage.getItem('studyChosen') === undefined || sessionStorage.getItem('studyChosen') === null) {
      this.filterSubject.next('');

    } else {
      this.filterSubject.next(String(sessionStorage.getItem('studyChosen')));
    }
  }

  getSearchElementRef(compList: QueryList<ElementRef>) {
    if (compList.length > 0) {
      this.search = compList.first;
      this.filterSubject.subscribe(filterVal => {
        this.applyFilter(this.generateFilterVal(filterVal));
      });
    }
  }

  private generateFilterVal(filterVal: string): string {
    return `${filterVal}|||${this.startDate}|||${this.endDate}|||${this.dateColumn}`;
  }

  private applyFilter(filterValue: string) {
    this.ds.filter = filterValue;
  }

  private customFilter(data: any, filterVal: string): boolean {
    const arr = filterVal.split('|||');
    if (arr.length !== 4) {
      return true;
    }
    const searchInput = arr[0].toLowerCase().trim();
    const startDate = new Date(arr[1]);
    const endDate = new Date(arr[2]);
    const dateColumns = arr[3].split(',');

    // transfer object to string by concatenating values.
    // this keyword in customFilter refers to the MatTableDataSource instead of the current component SearchPaginatorComponent.
    const convertObjValToString = (obj: any): string => {
      // to search only the Study Name column in MatTableDataSource
      if (data.studyName) {
        return data.studyName.trim();
      } else {
        return '';
      }
    };

    let dateMatch = false;
    dateColumns.forEach((dateColumn) => {
      const dt = dateColumn.trim();
      const filteredDateVal = new Date(data[dt].replace(/-/g,' '));
      if (filteredDateVal >= startDate && filteredDateVal <= endDate) {
        dateMatch = true;
      }
    });

    if (dateMatch) {
      if (searchInput === '') {
        return true;
      }
      const dataVal: string = convertObjValToString(data).toLowerCase();
      return dataVal.indexOf(searchInput.toLowerCase()) > -1;
    }

    return false;
  }

  exportToCSV() {
    this.renderData = this.ds.filteredData;
    this.renderData.forEach(element => {
      for (var i in element) {
        if (element[i] === null) {
          element[i] = ''
        }
      }
    });
    const location = String(window.location);
    this.typeOfAudit = location.slice((location.lastIndexOf('/') + 1), (location.length));
    switch (this.typeOfAudit) {
      case 'kri': this.exportKri(); break;
      case 'kpi': this.exportKpi(); break;
      case 'alert': this.exportAlert(); break;
      case 'user': this.exportUser(); break;
      case 'milestone': this.exportMilestone(); break;
      case 'task': this.exportTask(); break;
      case 'study': this.exportStudy(); break;
    }

  }

  getCurrentDate(): string {
    const today = new Date();
    return dateFormat(today, 'ddmmmyyyy').toString();
  }

  exportFile(options: any): void {
    const currentTime = this.getCurrentDate();
    const study = sessionStorage.getItem('studyChosen');
    if (study === null || study === '') {
      const fileName = 'Audit ' + ' All Studies ' + this.typeOfAudit + ' ' + currentTime + ' ' + this.name.toString();
      new ngxCsv(this.renderData, fileName, options);
    } else {
      const fileName = 'Audit ' + ' ' + study + ' ' + this.typeOfAudit + ' ' + currentTime + ' ' + this.name.toString();
      new ngxCsv(this.renderData, fileName, options);
    }
  }

  exportKri() {
    const options = {
      showLabels: true,
      headers: ['Study ID', 'Study Name', 'Changed On', 'Changed By', 'Reason for Change', 'Action', 'KRI',
        'Mitigation Stategy', 'Threshold Min', 'Threshold Max', 'Threshold', 'Threshold Operator', 'Weight', 'System Generates Alert']
    };
    this.exportFile(options);
  }
  exportKpi() {
    const options = {
      showLabels: true,
      headers: ['Study ID', 'Study Name', 'Changed On', 'Changed By', 'Reason for Change', 'Action', 'KPI',
       'Mitigation Stategy', 'Target', 'Weight', 'System Generates Alert']
    };
    this.exportFile(options);
  }
  exportAlert() {
    const options = {
      showLabels: true,
      headers: ['Alert ID', 'Study ID', 'Study Name', 'Protocol #', 'Site #', 'Created On', 'Created By', 'Action',
        'Generated By', 'Title', 'Description']
    };
    this.exportFile(options);
  }
  exportTask() {
    const options = {
      showLabels: true,
      headers: ['Study ID', 'Study Name', 'Created On', 'Created By', 'Protocol #', 'Site #', 'Action', 'Task Title',
       'Task Description', 'Due Date', 'Assignee', 'Impact', 'Action Taken', 'Alert ID']
    };
    this.exportFile(options);
  }
  exportUser() {
    const options = {
      showLabels: true,
      headers: ['Study ID', 'Study Name', 'Changed On', 'Changed By', 'Action', 'First Name', 'Last Name', 'Prefix', 'Title',
        'Email', 'Role', 'Level']
    };
    this.exportFile(options);
  }
  exportMilestone() {
    const options = {
      showLabels: true,
      headers: ['Study ID', 'Study Name', 'Changed On', 'Changed By', 'Reason for Change', 'Action', 'Milestone', 'Target Date', 'Alert Start Date',
        'Alert Offset', 'System Generates Alert']
    };
    this.exportFile(options);
  }
  exportStudy() {
    const options = {
      showLabels: true,
      headers: ['Study ID', 'Study OID', 'Study Name', 'Changed On', 'Changed By', 'Reason for Change', 'Phase', 'Sponsor Name',
        'Protocol Name', 'Protocol #', 'Country', 'Therapeutic Area', 'Sites', 'Subjects', 'Indication', 'Synopsis', 'Start Date']
    };
    this.exportFile(options);
  }
}
