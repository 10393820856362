<mat-card>
    <div class="smallFontSize">
        <div class="audit-primary cluster">
            <input type="search" (keyup)="applyFilter($event.target.value)" placeholder="Seacrh" autocomplete="off" style="width: 180px; height: 12px;">
            <div class="export-csv">
                <button class="button cursor-pointer" (click)="onExport()">Export as CSV</button>
            </div>
        </div>
        <mat-paginator #paginator [pageSize]="50" [pageSizeOptions]="[10, 25, 50, 75, 100]" >
        </mat-paginator>
        <div class="audit_info">
            <button class="icon-button">               
                <svg fill="currentColor" viewBox="0 0 512 512" [matTooltip]="'This audit trail page only includes the most recent 25,000 rows of data. For an all inclusive export of your audit changes please reach out to your ERT administrator.'" matTooltipPosition="above">
                <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336c-13.3 0-24 10.7-24 24s10.7 24 24 24h80c13.3 0 24-10.7 24-24s-10.7-24-24-24h-8V248c0-13.3-10.7-24-24-24H216c-13.3 0-24 10.7-24 24s10.7 24 24 24h24v64H216zm40-144a32 32 0 1 0 0-64 32 32 0 1 0 0 64z"/>
              </svg>
            </button>
        </div>
    </div>
    <div class="audit-table" *ngIf="auditDataFlag==='valid'">
        <!-- Creating the Table -->
        <mat-table #table [dataSource]="displayedData" class="ert-table" matSort matSortActive="active"
            matSortDirection="desc" matSortStart="desc">

            <!-- Changed On column -->
            <ng-container matColumnDef="changedOn">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Changed On
                </mat-header-cell>
                <mat-cell *matCellDef="let auditLog">{{auditLog.changedOn}}</mat-cell>
            </ng-container>

            <!-- Changed By column -->
            <ng-container matColumnDef="changedBy">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Changed By
                </mat-header-cell>
                <mat-cell *matCellDef="let auditLog">{{auditLog.changedBy}}</mat-cell>
            </ng-container>

            <!-- Action column -->
            <ng-container matColumnDef="action">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Action
                </mat-header-cell>
                <mat-cell *matCellDef="let auditLog">{{auditLog.action}}</mat-cell>
            </ng-container>

            <!-- Protocol # column -->
            <ng-container matColumnDef="protocolNumber">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Protocol #
                </mat-header-cell>
                <mat-cell *matCellDef="let auditLog">{{auditLog.protocolNumber}}</mat-cell>
            </ng-container>

            <!-- Object column -->
            <ng-container matColumnDef="object">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Object Name
                </mat-header-cell>
                <mat-cell *matCellDef="let auditLog">{{auditLog.object}}</mat-cell>
            </ng-container>

            <!-- Row Data column -->
            <ng-container matColumnDef="rowData">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Row Data
                </mat-header-cell>
                <mat-cell *matCellDef="let auditLog">{{auditLog.rowData}}</mat-cell>
            </ng-container>

            <!-- Changed Fields column -->
            <ng-container matColumnDef="changedFields">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Changed Fields
                </mat-header-cell>
                <mat-cell *matCellDef="let auditLog">{{auditLog.changedFields}}</mat-cell>
            </ng-container>

            <!-- Changed On column -->
            <ng-container matColumnDef="reasonForChange">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Reason For Change
                </mat-header-cell>
                <mat-cell *matCellDef="let auditLog">{{auditLog.reasonForChange}}</mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
    </div>
    <div class="audit-loading" *ngIf="auditDataFlag==='unchecked'">
        <mat-spinner diameter="50"></mat-spinner>
    </div>
    <div class="audit-no-data" *ngIf="auditDataFlag==='invalid'">
        <ert-no-data></ert-no-data>
    </div>
</mat-card>
