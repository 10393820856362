<mat-drawer-container autosize>
  <mat-drawer class="sidenav" #drawer opened mode="side">
    <mat-list class="full-width vert-align" role="list">
      <div>
        <button color="primary" mat-button [routerLink]="['/dashboard/alerts']">
          <mat-icon aria-label="Alerts" svgIcon="alerts"></mat-icon>Alerts
        </button>
      </div>
      <div>
        <button mat-button [routerLink]="['/dashboard/tasks']">
          <mat-icon aria-label="Tasks" svgIcon="tasks"></mat-icon>Tasks
        </button>
      </div>
    </mat-list>
  </mat-drawer>

  <mat-toolbar>
    <button class="back-to-tasks es-button" routerLink="{{from}}">
      <mat-icon>keyboard_backspace</mat-icon><span>Back to tasks</span>
    </button>
    <button class="es-button -es-primary" *ngIf="canComplete" (click)="completeTask(item)">
      <mat-icon>done</mat-icon><span>Complete Task</span>
    </button>
  </mat-toolbar>
  <div class="parent-container">
    <mat-card *ngIf="item">
      <mat-card-header>
        <mat-card-title>
          <h4>{{item.taskTitle}}</h4>
        </mat-card-title>
        <mat-card-subtitle>Last Updated: {{item.updatedDate | displayDate}}</mat-card-subtitle>
        <mat-card-subtitle>Due Date: {{item.dueDate | displayDate}}</mat-card-subtitle>
        <mat-card-subtitle>Protocol #: {{item.protocolNumber}}</mat-card-subtitle>
        <mat-card-subtitle>Site #: {{item.siteNo}}</mat-card-subtitle>
        <mat-card-subtitle>Assigner: {{item.assigner}}</mat-card-subtitle>
        <mat-card-subtitle>Assignee: {{item.assignee}}</mat-card-subtitle>
        <mat-card-subtitle [routerLink]="['/dashboard/alerts/detail', item.studyAlertLogId ] ">Alert ID:
          {{item.studyAlertLogId}} Alert Description : {{item.alertMessageData.msgTemplate}} <span> <img src="assets/icons/ux/launch.svg"
              title="Go to Alert" alt="" /> </span> </mat-card-subtitle>
        <img mat-card-avatar src="" alt="{{item.calcTypeId}}">
      </mat-card-header>
      <mat-card-content>
        <mat-divider></mat-divider>
        <br>
        <p>{{item.taskDescription}}</p>
      </mat-card-content>
      <mat-card-actions>

      </mat-card-actions>
    </mat-card>
  </div>

</mat-drawer-container>
