import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef,  MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AuthService } from '@core/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { OrganizationService } from '../../organization.service';
import { ErrorNotificationService } from '@core/services/error-notification.service';

@Component({
  selector: 'clario-dialog-calc-reset-warning',
  templateUrl: './dialog-calc-reset-warning.component.html',
  styleUrls: ['./dialog-calc-reset-warning.component.scss']
})
export class DialogCalcResetWarningComponent implements OnInit {

  selectedConfirm:any = null;

  constructor(
    private authService: AuthService,
    private toastr: ToastrService,
    private errorNotificationService: ErrorNotificationService,
    private organizationService: OrganizationService,
    public dialogRef: MatDialogRef<DialogCalcResetWarningComponent>
    ) { }

  ngOnInit(): void {
  }

  onCancel() : void {
    this.dialogRef.close();
  }

  clearCalcLock() {
    this.selectedConfirm = 'selected';
    const clientId = this.authService.getOrganization().clientId;
    if (clientId) {
      this.organizationService.clearCalcLock(clientId).subscribe(calcLockInfo => {
        this.toastr.success("Successfully cleared calc lock.");
        this.dialogRef.close();
      }, err => {
        this.errorNotificationService.notifyError(`Error Clearing Calc Lock.`, err);
        this.dialogRef.close();
      }); 
    }
  }
}
