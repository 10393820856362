import { EndpointService } from './../../../shared/services/endpoint.service';
import { DataService } from './../../../shared/services/data.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ActionAppSetTasks } from '@app/core/store/app.actions';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

@Component({
  selector: 'ert-task-complete',
  templateUrl: './task-complete.component.html',
  styleUrls: ['./task-complete.component.scss']
})
export class TaskCompleteComponent implements OnInit {
  completeTaskForm: UntypedFormGroup;

  constructor(
    private dataService: DataService<any>,
    private endpointService: EndpointService,
    public dialogRef: MatDialogRef<TaskCompleteComponent>,
    private toastr: ToastrService,
    private store: Store<any>,
    public router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.completeTaskForm = new UntypedFormGroup({
      taskComment: new UntypedFormControl('', Validators.required)
    });
    if (!this.data.alertMessageData.alertTitle) {
      this.data.alertTitle = this.data.alertMessageData.calcTypeName || this.data.alertMessageData.msgTemplate;
    } else {
      this.data.alertTitle = this.data.alertMessageData.alertTitle;
    }
  }

  complete(form) {
    if (this.completeTaskForm.valid) {
      const endpoint = this.endpointService.getEndpoint('singleTask', this.data.studyTaskId);
      const payload = {
        comment: this.completeTaskForm.value.taskComment,
        srcUrl: location.origin
      };
      this.dataService.update(endpoint, payload).subscribe(response => {
        this.toastr.success('Task completed', 'Success');
        this.dialogRef.close();
        this.router.navigate(['/dashboard/tasks']);

        // update count of tasks remaining notification
        const taskEndpoint = this.endpointService.getEndpoint('tasks');
        this.dataService.getAll(taskEndpoint).subscribe((tasks: any) => {
          const count = tasks.result.filter(items => items.taskOrigin === 'assigned').length;
          this.store.dispatch(new ActionAppSetTasks(count));
        });
      });
    }
  }

}
