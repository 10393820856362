import { DataService, EndpointService } from '@shared/services';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { UpdateToolbar } from '@app/core/store/toolbar/toolbar.actions';
import { TaskCompleteComponent } from '@app/tasks/modal/task-complete/task-complete.component';
import { SetBreadCrumbs } from '@app/core/store/breadcrumbs/breadcrumbs.actions';
import dateFormat from 'dateformat';

@Component({
  selector: 'ert-message-list',
  templateUrl: './message-list.component.html',
  styleUrls: ['./message-list.component.scss']
})
export class TaskMessageListComponent<T> implements OnInit {


  displayedData;
  displayedColumns = ['siteNo', 'protocolNumber', 'taskTitle', 'taskDescription', 'assignee', 'dueDate'];
  selectedTask = false;
  // dateformat = require('dateformat');

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  pageEvent: PageEvent;

  constructor(
    private store: Store<any>,
    private endpointService: EndpointService,
    private dataService: DataService<any>,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    const tasksEndpoint = this.endpointService.getEndpoint('tasks');
    this.dataService.getAll(tasksEndpoint).subscribe((tasks: any) => {
      this.initUserTable(tasks.result);
    });

    this.store.dispatch(new UpdateToolbar({
      title: 'Tasks',
    }));

    this.store.dispatch(new SetBreadCrumbs({
      crumbs: [
        { text: 'Tasks', url: 'tasks' },
      ],
    }));

  }

  initUserTable(tasks) {
    this.displayedData = new MatTableDataSource(tasks);
    // Custom Filter
    this.displayedData.filterPredicate = (data, filter: String) => {
      const lowerFilter = filter.trim().toLowerCase();
      return this.checkFilter(data.taskTitle, lowerFilter) || this.checkFilter(data.taskDescription, lowerFilter) ||
        this.checkFilter(data.assignee, lowerFilter) || this.checkDate(data.dueDate, lowerFilter);
    };
    this.displayedData.paginator = this.paginator;
    this.displayedData.sort = this.sort;
  }

  checkFilter(item, lowerFilter) {
    return item.trim().toLowerCase().indexOf(lowerFilter) !== -1;
  }
  checkDate(item, lowerFilter) {
    return dateFormat(item, 'dd-mmm-yyyy').toString().trim().toLowerCase().indexOf(lowerFilter) !== -1;
  }

  applyFilter(filterValue: string) {
    this.displayedData.filter = filterValue;
  }


  completeTask(task) {
    const dialogRef = this.dialog.open(TaskCompleteComponent, {
      width: '250px',
      height: '600px',
      data: task
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      // this.animal = result;
    });
  }

}


