<mat-card class="scorecard">

  <div class="card-header">
    <!-- <img src="assets/{{imageFilename}}.svg" alt=""> -->
    <h4 class="scorecard__header-text">{{ scorecardTitle }}</h4>
    <span class="spacer"></span>
    <ert-observation *ngIf="isStudyAdmin" [studyId]="studyId" [siteId]="siteId"></ert-observation>
  </div>

  <mat-card-content>
    <mat-table #table [dataSource]="dataSource" matSort class="ert-table">

      <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <mat-header-cell class="kri_col" *matHeaderCellDef mat-sort-header> KRI </mat-header-cell>
        <mat-cell class="kri_col" *matCellDef="let element"> <span><img *ngIf="element.isErtData" src="assets/ERT-Logo.svg" alt=""> {{element.name}} </span></mat-cell>
      </ng-container>

      <!-- Score Column -->
      <ng-container matColumnDef="score">
        <mat-header-cell class="rating_col" *matHeaderCellDef mat-sort-header> Score </mat-header-cell>
        <mat-cell [matTooltip]=" scoreTooltip(element)" matTooltipClass="scorecard-tooltip" matTooltipPosition="left" class="rating_col" *matCellDef="let element">
          <div *ngIf="element.score === null" [matTooltip]="'No data available'"
            class="scorecard__color-square-null" [ngStyle]="{'background': getScoreColor(element.score)}">
            <ert-no-value></ert-no-value>
          </div>
          <div class="scorecard__color-square" [ngStyle]="{ 'background': element.color }" *ngIf="element.score !== null">{{element.score}}</div>
        </mat-cell>
      </ng-container>

      <!-- Value Column -->
      <!-- <ng-container matColumnDef="calcShortDisplayVal">
        <mat-header-cell class="score_col" *matHeaderCellDef mat-sort-header > Value </mat-header-cell>
        <mat-cell class="score_col" *matCellDef="let element" >
          <p class="scoreCardValue">
            {{ element.calcShortDisplayVal === 'N/D' || element.calcShortDisplayVal === 'n/d' ? '—' : element.calcShortDisplayVal}}
          </p>
        </mat-cell>
      </ng-container> -->

      <!-- Trend Column -->
      <ng-container matColumnDef="trend">
        <mat-header-cell class="trend_col" *matHeaderCellDef> Trend </mat-header-cell>
        <mat-cell class="trend_col" *matCellDef="let element">
          <!-- <ert-sb-trendline [height]="65" [maxDotRadius]="6" [data]="element.historical" [maxPoints]="6"></ert-sb-trendline> -->
          <ert-sparkline *ngIf = "element.historical" [data]="element.historical" width="180" height="60"></ert-sparkline>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row [routerLink]="['detail', kriData[i].calcTypeId]" *matRowDef="let row; columns: displayedColumns; let i= index"></mat-row>
    </mat-table>

  </mat-card-content>
</mat-card>
