import { TaskCompleteComponent } from '@app/tasks/modal/task-complete/task-complete.component';
import { Observable } from 'rxjs';
import { EndpointService } from '@app/shared/services/endpoint.service';
import { DataService } from '@app/shared/services/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SetBreadCrumbs } from '@app/core/store/breadcrumbs/breadcrumbs.actions';
import { Store } from '@ngrx/store';

@Component({
  selector: 'ert-task-message-detail',
  templateUrl: './message-detail.component.html',
  styleUrls: ['./message-detail.component.scss']
})
export class TaskMessageDetailComponent implements OnInit {
  from = '';
  item$: Observable<any>;
  item: any;
  createdDate: Date;
  canComplete = false;
  constructor(
    private route: ActivatedRoute,
    private dataService: DataService<any>,
    private endpointService: EndpointService,
    public dialog: MatDialog,
    public router: Router,
    private store: Store<any>,
  ) { }

  ngOnInit() {
    this.route.url.subscribe(url => {
      this.from = '/dashboard/tasks';
      this.route.params.subscribe(params => {
        const endpoint = this.endpointService.getEndpoint('singleTask', params.id);

        this.item$ = this.dataService.getOne(endpoint);
        this.item$.subscribe(data => {
          if (data.result[0]) {
            const resultData = data.result[0];

            this.store.dispatch(new SetBreadCrumbs({
              crumbs: [
                { text: 'Tasks', url: 'tasks' },
                { text: `${resultData.taskTitle}`, url: `tasks/detail/${params.id}` },
              ],
            }));
            this.item = resultData;
            this.canComplete = (this.item.taskOrigin === 'assigned') || (this.item.assignee === this.item.assigner);
            this.createdDate = new Date(data.result[0].createdDate);
          }
        });
      });
    });
  }

  completeTask(item) {
    const dialogRef = this.dialog.open(TaskCompleteComponent, {
      data: item
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

}
