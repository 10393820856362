import { Component, OnInit, OnDestroy, Input, ViewChild, ElementRef } from '@angular/core';
import * as d3 from 'd3';

export interface ChartData {
  names: string[];
  values: number[];
}

@Component({
  selector: 'ert-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})

export class BarChartComponent implements OnInit, OnDestroy {
  @ViewChild('barChart', { static: true }) chartContainer: ElementRef;
  @Input() data;
  @Input() private barColor: number;
  @Input() public metricTitle = '';

  private svg: any;
  private xScale: any;
  private yScale: any;
  private width: number;
  private height: number;
  private xDomain;
  private yDomain;
  private firstDraw: boolean;
  private tooltip;
  private chartData: ChartData;
  private margin: any = { top: 5, bottom: 5, left: 15, right: 15 };

  constructor() {
    this.firstDraw = true;
  }

  ngOnInit() {
    if (this.data) {
      this.firstDraw = false;
      this.chartData = this.barData();

      this.drawChart();
      window.addEventListener('resize', this.drawChart.bind(this));
    }
  }

  barData() {
    const barData  = {names: [], values: []};
    this.data.forEach(data => {
      barData.names.push(Object.values(data)[0]);
      barData.values.push(Object.values(data)[1]);
    });

    return barData;
  }

  wrap(text, width) {
    text.each(function () {
      let text = d3.select(this), word, line = [], lineNumber = 0, lineHeight = 1.1; // ems
      const words = text.text().split(/\s+/).reverse();
      const y = text.attr('y');
      const dy = parseFloat(text.attr('dy'));
      let tspan = text.text(null).append('tspan').attr('x', 0).attr('y', y).attr('dy', dy + 'em');
      while (word = words.pop()) {
        line.push(word)
        tspan.text(line.join(' '))
        if (tspan.node().getComputedTextLength() > width) {
          line.pop();
          tspan.text(line.join(' '));
          line = [word];
          tspan = text.append('tspan').attr('x', 0).attr('y', y).attr('dy', `${++lineNumber * lineHeight + dy}em`).text(word)
        }
      }
    });
  }

  drawChart() {
    const element = this.chartContainer.nativeElement;
    const maxWidth = (75 * this.chartData.values.length);
    const responseWidth = element.offsetWidth - this.margin.left - this.margin.right;
    this.width = responseWidth > maxWidth ? maxWidth : responseWidth;
    this.height = element.offsetWidth > 230 ? 230 : element.offsetWidth;
    // this.width = (75  * this.chartData.values.length);
    // this.height = 250;

    if (!this.firstDraw) {
      d3.select(element).selectAll('svg').remove();
      this.svg = null;

      this.tooltip = d3.select('body').append('div')
        .style('position', 'absolute')
        .attr('class', 'bar-tooltip')
        .style('z-index', '10')
        .style('visibility', 'hidden')
        .style('background', 'black')
        .style('color', 'white')
        .style('font-size', '10px')
        .style('padding', '2px 4px')
        .style('border-radius', '3px')
        .style('font-family', '"Noto Sans", Helvetica Neue, Helvetica, Arial, sans-serif');
    }

    this.xDomain = this.chartData.values;
    this.yDomain = [0, d3.max(this.chartData.values)]; // d3.max(this.chartData, d => d[1])];
    // create scales
    this.xScale = d3.scaleBand()
      .padding(0.3)
      .domain(d3.range(this.chartData.values.length))
      // .range([0, this.width]);
      .rangeRound([0, this.width]);

    this.yScale = d3.scaleLinear()
      .domain(this.yDomain)
      .range([0, this.height]);

    // to show the labels 0-100
    const yScaleReversed = d3.scaleLinear()
      .domain([100, 0])
      .range([0, this.height]);

    const xAxis = d3.axisBottom(this.xScale).ticks(5);
    const yAxis = d3.axisLeft(yScaleReversed).ticks(5);
    const labelMargin = 3 * this.margin.left;

    this.svg = d3.select(element).append('svg')
      .attr('class', 'bar-chart')
      .attr('width', this.width + 40)
      .attr('height', this.height + 60)
      .attr('transform', `translate( ${this.margin.left}, 0 )`);

    this.svg.selectAll('rect')
      .data(this.chartData.values)
      .enter()
      .append('rect')
      .attr('class', 'bar-rect')
      .attr('fill', this.barColor)
      .attr('height', (d) => this.yScale(d))
      .attr('y', d => this.height - this.yScale(d) + this.margin.top)
      .attr('x', (d, i) => this.xScale(i) + labelMargin)
      .attr('width', this.xScale.bandwidth())
      .on('mouseover', (d) => {
        return this.tooltip.html(() => `<span>score: ${d}</span>`)
          .style('visibility', 'visible');
      })
      .on('mousemove', () => {
        this.tooltip.style('top', (d3.event.pageY - 10) + 'px').style('left', (d3.event.pageX + 10) + 'px');
      })
      .on('mouseout', () => this.tooltip.style('visibility', 'hidden'));

    this.svg.append('g')
      .attr('class', 'x-bar-label')
      .attr('transform', `translate(${labelMargin}, ${this.height + 5})`)
      .call(xAxis.tickFormat((d, i) =>  this.chartData.names[i] ))
      // .call(g => g.select('.domain').remove())
      .selectAll('.tick text')
      .style('font-size', '1rem')
      .style('font-family', '"Noto Sans", Helvetica Neue, Helvetica, Arial, sans-serif')
      .style('margin', '0 0 0.7rem 0')
      .call(this.wrap, this.xScale.bandwidth());

    this.svg
      .append('g')
      .attr('class', 'y-bar-label')
      .attr('transform', `translate(${labelMargin} , ${this.margin.top})`)
      .call(yAxis);

    this.svg
      .append('text')
      .attr('transform', 'rotate(-90)')
      .attr('class', 'axis-label')
      .attr('alignment-baseline', 'baseline')
      .attr('y', 0)
      .attr('x', 0 - this.height / 2)
      .attr('dy', '1em')
      .style('text-anchor', 'middle')
      .text('Score');
  }

  ngOnDestroy() {
    window.removeEventListener('resize', this.drawChart.bind(this));

  }


}
