import { FormGeneratorService } from './../form-base.service';
import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { FormOptionsDefault, FormOptions, FormDataType } from '../form-base';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'ert-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss']
})
export class DynamicFormComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();

  form: UntypedFormGroup;
  formFields;
  formErrors = {};
  formOptions: FormOptions;
  payLoad;

  @Input() formData: FormDataType;
  @Output() submitForm: EventEmitter<any> = new EventEmitter<any>();
  // @Output() getFieldValues: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private formGeneratorService: FormGeneratorService,
  ) { }

  ngOnInit() {
    this.formFields = this.formData.fields;
    this.formOptions = { ...FormOptionsDefault, ...this.formData.options };

    this.form = this.formGeneratorService.setFormGroup(this.formFields);

    this.form.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(data => {
      this.formGeneratorService.setFieldValues(this.formValues);
      this.getFormErrors(data);
    });
  }

  getEvent(field) {
    return Object.keys(field.events)[0];
  }

  eventFn(field, event) {
    if (!Object.keys(field.events).length) return;
    return field.events[Object.keys(field.events)[0]](event);
  }

  /**
   * Get Form Values
   * */
  get formValues() {
    return this.form.value;
  }

  public resetForm() {
    // this.form.reset();
    this.form = this.formGeneratorService.setFormGroup(this.formFields);
  }
  /** Form submit event
   * Form values sent to parent event handler
   */
  onSubmit(event: Event) {
    // this.payLoad = JSON.stringify(this.formValues);
    // event.preventDefault();
    // event.stopPropagation();
    if (this.form.valid) {
      this.submitForm.emit(this.formValues);
    } else {
      this.validateAllFormFields(this.form);
    }
  }

  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  /** Get all form errors
   * only used when inline Error Showing is on (showInlineErrors)
   */
  getFormErrors(data: any) {
    if (!this.form) { return; }
    this.formFields.forEach(field => {
      this.formErrors[field.name] = [];
      const control = this.form.get(field.name);
      // iterate the keys in errors
      if (control && (control.dirty || control.touched) && !control.valid) {
        for (const error in control.errors) {
          if (error) {
            const errMessages = this.getValidationMessage(field, error);
            if (!errMessages.message && error !== 'required') {
              field.validations.push({name: error, message: control.errors[error]});
              errMessages.message = control.errors[error];
            }
            this.formErrors[field.name].push(errMessages.message);
          }
        }
      }
    });
  }

  getValidationMessage(field, validationName: string): { message: string } {
    const noMessage = {message: ''};
    return (field.validations.find((val) => {
      if (val && val.name === validationName) {

        return (typeof val.message === 'string') ? val.message : '';
      }
    }) || noMessage);
  }

  debug(val) {
    // console.log(val);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
