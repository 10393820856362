import { Component, OnInit, Input } from '@angular/core';
import { UtilsService } from '../services/utils.service';

@Component({
  selector: 'ert-metric-info-banner',
  templateUrl: './metric-info-banner.component.html',
  styleUrls: ['./metric-info-banner.component.scss']
})
export class MetricInfoBannerComponent implements OnInit {
  @Input() heading: string;
  @Input() metricScore: number;
  @Input() metricName: string;

  constructor() { }

  ngOnInit() {
  }
  
  getScoreColor(score) {
    return UtilsService.getColorFromScore(score);
  }

}

