
import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  Component, OnInit, Input, Output, EventEmitter, ViewChild,
  ChangeDetectionStrategy, ChangeDetectorRef, AfterViewInit, OnChanges, TemplateRef
} from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DisplayDatePipe } from '@app/shared/pipes/display-date.pipe';
import { ColumnTypes, ColumnDetails, EnumFieldTypes } from './inline-table-edit.model';
import {CodeMirrorService} from '@shared/code-mirror/code-mirror.service';
import { isDate } from '@shared/helpers/util';
import dateFormat from 'dateformat';

@Component({
  selector: 'ert-inline-table',
  templateUrl: './inline-table-edit.component.html',
  styleUrls: ['./inline-table-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    // trigger('detailExpand', [
    //   state('collapsed', style({height: '0', minHeight: '0px'})),
    //   state('expanded', style({height: '*', overflow: 'scroll'})),
    //   transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    // ]),
    // trigger('detailExpand', [ state('collapsed, void', style({ height: '0px' })), state('expanded', style({ height: '*' })), transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')), transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')) ])
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})
export class InlineTableEditComponent
  implements OnInit, OnChanges, AfterViewInit {
  // displayedColumns: string[] = null
  editableFields = [];
  // dateformat = require('dateformat');
  hasError = false;
  formErrors = [];
  dateFields = [];
  filter;
  currentSavedDBRowData;
  fieldTypes = EnumFieldTypes;
  paginationOptions = [];
  isSearchable: boolean;
  selectedRowIndex: number;
  mxCharsInColumn: number;
  message: string;
  isEnabled: boolean;
  isAddRequired: boolean;
  // array to store checked rows in case of a checkebox
  checkedRows = [];

  // expanded rows
  expandedRows = [];

  @Input() dataSource: any;
  @Input() columnNames: string[];
  @Input() columnDetails: ColumnDetails;
  @Input() editableColumns: string[];
  @Input() columnToSetRowStatus: string;
  @Input() columnToSetRowAsNotEdit = {};
  @Input() pageSizeOptions: [string];
  // updated the display action icon code, now each row can have a different set of icons available
  // this implies some rows have copy,edit and delete icons and some not
  @Input() hasEditIcon: boolean;
  @Input() columnForEdit: string;
  @Input() hasCopyIcon: boolean;
  @Input() columnForCopy: string;
  @Input() hasDeleteIcon: boolean;
  @Input() columnForDelete: string;
  @Input() hasInfoIcon: boolean;
  @Input() columnForInfo: string;
  @Input() columnToSetHyperlink: string;
  // display a checbox in a row.
  @Input() hasCheckbox: boolean;
  @Input() columnForCheckbox: string;
  // on click of edit icon should it open the edit page on a new page.
  @Input() isEditExternal: boolean;
  // check if there is an Expandable div
  @Input() hasExapandable: boolean;
  @Input() columnForExpandableDiv: string;
  @Input() expandableTemplate: string;
  // check if there is a toggle button or not
  @Input() hasRadioToggle: boolean;
  @Input() columnForRadioToggle: string;
  @Input() columnToDisableCheckbox: string;


  @Output() editAdminAction = new EventEmitter<any>();
  @Output() deleteAdminAction = new EventEmitter<any>();
  // event emitted when a link is clicked.
  @Output() hyperlinkClicked = new EventEmitter<any>();
  // event emitted when an external edit is required
  @Output() editClicked = new EventEmitter<any>();
  //event emitted when an external copy is required
  @Output() copyClicked = new EventEmitter<any>();
  //event emitted when an external delete is required
  @Output() deleteClicked = new EventEmitter<any>();
  // event emitted when an expansion button is clicked
  @Output() expansionClicked = new EventEmitter<any>();
  // event emitted when a radio toggle button is clicked
  @Output() radioToggleClicked = new EventEmitter<any>();

  @ViewChild(MatSort) matSort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private displayDatePipe: DisplayDatePipe,
    private cdRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {}

  ngOnChanges() {
    setTimeout(() => {
      if (this.dataSource) {
        this.paginationOptions = this.pageSizeOptions; // page size options
        this.dataSource.paginator = this.paginator; // pagination
        this.dataSource.sort = this.matSort;
        this.dataSource.filterPredicate = (data, filter) => {
          const lowerFilter = filter.trim().toLowerCase();

          return (
            // for admin management table
            this.checkActive(data.active, lowerFilter) ||
            this.checkField(data.firstName, lowerFilter) ||
            this.checkField(data.lastName, lowerFilter) ||
            this.checkField(data.email, lowerFilter) ||
            this.checkRole(data.adminLevel, lowerFilter) ||
            this.checkField(data.assignedDateUI, lowerFilter) ||
            this.checkField(data.updatedDateUI, lowerFilter) ||
            this.checkField(data.lastLoginDateUI, lowerFilter) ||

            // for metric listing table
            this.checkField(data.metricName, lowerFilter) ||
            this.checkField(data.type, lowerFilter) ||

            // for variable library
            this.checkField(data.name, lowerFilter) ||
            this.checkField(data.description, lowerFilter) ||
            this.checkField(data.associatedMetric, lowerFilter) ||

            Object.keys(data || {}).filter(col => this.columnNames.includes(col)).some(col => {
              if (typeof data[col] === 'object') {
                const colData =  Object.values(data[col] || {}).map(val => val);
                return this.checkField(colData, lowerFilter);
              } if (isDate(data[col])) {
                return this.checkDate(data[col] , lowerFilter);
              }
              return this.checkField(data[col], lowerFilter);
            })

          );
        };
        if (this.filter != null && this.filter !== '') {
          this.applyFilter(this.filter);
        }
        this.dataSource.sortingDataAccessor = (
          datas: any,
          header: string
        ): any => {
          if (typeof datas[header] === 'string') {
            return datas[header].trim().toLocaleLowerCase();
          } else {
            return datas[header];
          }
        };
      }

    });
  }

  checkActive(item, lowerFilter) {
    if (item !== null) {
      if (item) return 'active'.includes(lowerFilter);
      else return 'inactive'.includes(lowerFilter);
    }
  }

  checkRole(item, lowerFilter) {
    if (item !== null) {
      if (item === 1) return 'client admin'.includes(lowerFilter);
      else if (item === 2) return 'ert admin'.includes(lowerFilter);
    }
  }

  checkDate(item, lowerFilter) {
    return dateFormat(item, 'dd-mmm-yyyy').toString().trim().toLowerCase().indexOf(lowerFilter) !== -1;
  }

  checkField(item, lowerFilter) {
    if (item) {
      if (typeof item === 'string') {
        return item.trim().toLowerCase().includes(lowerFilter);
      } else {
        return item.toString().trim().toLowerCase().includes(lowerFilter);
      }
    }
  }

  ngAfterViewInit() {
    this.cdRef.detectChanges();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  clearFilters() {
    this.dataSource.filter = '';
    this.filter = '';
  }
  /**
   * if columnToSetRowAsNotEdit is set and is a match
   * don't allow row edit
   * @param rowData
   * @param rowDataIndex
   */
  editRow(rowData: any, rowDataIndex: number) {
    if(this.isEditExternal){
      // external Edit open in new Link
      this.editClicked.emit({
        rowData
      });
    }
    else
    {// inline edit
      if (
        !this.columnToSetRowAsNotEdit['key'] ||
        rowData[this.columnToSetRowAsNotEdit['key']] !==
          this.columnToSetRowAsNotEdit['value']
      ) {
        this.currentSavedDBRowData = { ...rowData };
        this.selectedRowIndex = rowDataIndex;
      }
    }

  }

  deleteRow(rowData: any, rowDataIndex: number) {
    this.deleteAdminAction.emit({
      rowData,
    });
    this.selectedRowIndex = -1;
  }

  copyRow(rowData: any, rowDataIndex: number) {
    this.copyClicked.emit({
      rowData
    });
  }

  cancelEditRow(event, rowData: any) {
    this.selectedRowIndex = -1;
    Object.keys(rowData).forEach(
      (key) => (rowData[key] = this.currentSavedDBRowData[key])
    );

    return rowData;
  }

  saveRow(rowData) {
    this.editAdminAction.emit({
      rowData,
      oldDBData: this.currentSavedDBRowData,
    });
    this.selectedRowIndex = -1;
  }

  disableSave(dataRow) {
    return (
      this.formErrors.length ||
      JSON.stringify(this.currentSavedDBRowData) === JSON.stringify(dataRow)
    );
  }

  isFieldEditable(rowIndex, column): boolean {
    return (
      rowIndex === this.selectedRowIndex &&
      column.toLowerCase() !== 'action' &&
      this.editableColumns &&
      this.editableColumns.includes(column)
    );
  }

  setCheckboxStatus(rowData) {
    return rowData[this.columnToDisableCheckbox] || this.setRowStatus(rowData);
  }
  /**
   * Displays the row as active or enactive
   * @param rowData
   */
  setRowStatus(rowData) {
    if (this.columnToSetRowAsNotEdit['key']) {
      return (
        rowData[this.columnToSetRowAsNotEdit['key']] ===
        this.columnToSetRowAsNotEdit['value']
      );
    }

    return rowData[this.columnToSetRowStatus] === false;
  }

  showError(data, colValue, col: string): boolean {
    let errorMessage = '';
    this.hasError = false;
    if (col === this.fieldTypes.EMAIL) {
      const emailValidator = new RegExp(
        '^[a-z0-9]+(.[_a-z0-9]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,15})$',
        'i'
      );
      this.hasError = !emailValidator.test(colValue);
    }

    if((this.columnDetails[col].validations)) {
      const {config: {minValue, maxValue}} = data;
      const validatorFn = (validatorName, validatorCallback) => ({
          'min': validatorCallback(minValue),
          'max': validatorCallback(maxValue),
      })[validatorName];

      this.columnDetails[col].validations.map(validation => {
        this.hasError = !!validatorFn(validation.validator.name,  validation.validator)({value: colValue})

        if(this.hasError) {
          errorMessage = validation.message
        }
      })

    }
    if(errorMessage){
      this.hasError = true;
    }
    if (!this.hasError && this.columnDetails[col].required) {
      this.hasError = !!(
        this.columnDetails[col] &&
        this.columnDetails[col].required &&
        !this.hasValue(colValue)
      );
    }
    const colsFormWithErrors = this.formErrors.map(colObj => colObj.col);
    if (this.hasError) {
      this.formErrors = [                                                                 //resetting formError before pushing recent errorMessage
        ...this.formErrors.filter((colWithErr) => !colWithErr[col]),
      ];

      this.formErrors.push({[col]: errorMessage || 'Invalid Value'});
    }
    if (!this.hasError && this.formErrors.find(colObj => colObj[col])) {
      this.formErrors = [
        ...this.formErrors.filter((colWithErr) => !colWithErr[col]),
      ];
    }

    return (this.hasError || errorMessage) ? true : false;
  }

  getColErrorMessage(column) {
    return (this.formErrors.find(col => col[column]) || {})[column] || ''
  }

  showErrorOLd(colValue, col: string): boolean {
    if (col === this.fieldTypes.EMAIL) {
      const emailValidator = new RegExp(
        '^[a-z0-9]+(.[_a-z0-9]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,15})$',
        'i'
      );
      this.hasError = !emailValidator.test(colValue);
    } else if (this.columnDetails[col].required) {
      this.hasError = !!(
        this.columnDetails[col] &&
        this.columnDetails[col].required &&
        !this.hasValue(colValue)
      );
    }

    if (this.hasError && !this.formErrors.includes(col)) {
      this.formErrors.push(col);
    }
    if (!this.hasError && this.formErrors.includes(col)) {
      this.formErrors = [
        ...this.formErrors.filter((colWithErr) => colWithErr !== col),
      ];
    }

    return this.hasError;
  }

  fieldType(col): ColumnTypes {
    return (
      (this.columnDetails[col] && this.columnDetails[col].type) ||
      this.fieldTypes.TEXT
    );
  }

  getFieldValue(rowData, col) {
    let value = rowData[col];
    if (!this.columnDetails[col]) {
      return value;
    }

    // if select field map the key to the correct value from options
    if(this.fieldType(col) === this.fieldTypes.NUMBER && rowData['format'] !== 'seconds') {
      value = parseFloat(value)
    }
    if (this.fieldType(col) === this.fieldTypes.SELECT) {
      const option = this.columnDetails[col].options?.find((option) => option.key === value);
      value = option ? option.value : null;
    }
    // if Date field transform the date
    if (this.fieldType(col) === this.fieldTypes.DATE) {
      value = this.displayDatePipe.transform(value);
    }

    if (typeof (value) === 'object' && value !== null) {
      return Object.values(value);
    }

    return value;
  }


  hasValue(colValue) {
    return !!(colValue || colValue === 0 || colValue === false);
  }

  /**
   * This method is used to bind the link click on the table to the event emitter.
   * @param rowData the row where link was clicked.
   */
  linkClick(rowData){
    this.hyperlinkClicked.emit({
      rowData
    });
  }

  /**
   * This method is used to check whether a row contains a hyperlink or not,
   * based on the columnToSetHyperlink column's value.
   * @param row the row where link is to be rendered.
   * @returns a boolean true/false value indicating whether a row contains a link or not.
   */
  checkHyperlink(row){
    return row[this.columnToSetHyperlink];
  }


  /**
   * This method is used to check if a row contains a checkbox.
   * It checks if the hasCheckbox flag is set or not, if yes then it returns the value for the row
   * else it returns false everytime.
   * @param row The row where checkbox is to be rendered.
   * @returns The boolean true/false value indicating whether checkbox has to be rendered.
   */
  checkCheckBox(row){
    if(this.hasCheckbox){
      return row[this.columnForCheckbox];
    }else{
      return false;
    }
  }

  /**
   * This method updates the checkedRows array whenever a checkbox is checked.
   * @param row The row where checkbox was clicked.
   */
  columnChecked(row){
    const index = this.checkedRows.indexOf(row, 0);
    if (index > -1) {
      this.checkedRows.splice(index, 1);
    }else{
      this.checkedRows.push(row);
    }
  }

  /**
   * This method is used to check whether the row is in the checkedRows array.
   * @param row The row which is to be checked.
   * @returns A boolean true/false value indicating if a row is checked.
   */
  isRowChecked(row){
   return this.checkedRows.indexOf(row,0)>-1?true:false;
  }

  checkExpandable(row){
    return !!row[this.columnForExpandableDiv];
  }


  toggleExpansion(row){
    const index = this.expandedRows.indexOf(row);
    if(index === -1) {
        this.expandedRows.push(row);
        this.expansionClicked.emit({
          row
        });
    } else {
      this.expandedRows.splice(index,1);
    }

  }


  checkExpanded(element): boolean {
    let flag = false;
    for(let i=0;i<this.expandedRows.length;i++){
      if(this.expandedRows[i] === element) {
        flag = true;

      }
    }
    return flag;
  }
  track(item: any, index: number) {
    return index;
  }


  toggleCheckbox(event,data){
    event.preventDefault();
    const currentToggleStatus = data[this.columnForRadioToggle];
    const updatedToggleStatus = currentToggleStatus?false:true;
    this.radioToggleClicked.emit({
      data,updatedToggleStatus
    });
  }


  toggleCheck(data){
    return data[this.columnForRadioToggle];
  }

  getTemplateToRender(data){
    // console.log(data)
    // emit aggr data -> parent uses it to fetch data
    // parent then updates the templateRef array
    // child then picks it up
    // meanwhile show spinner
    // return data.siteSQL;
  }

   /**
   * This method is used to check if a row contains any icons (edit,copy,delete) or not.
   * It checks if the table has hasConditionalAction flag set, if yes then it returns the row
   * specific value, else return true everytime.
   * @param row the row where icons are to be rendered.
   * @returns the boolean true/false value indicating whether a row has any icons.
   */
    hasConditionalDeleteIcon(row){
      if(this.hasDeleteIcon){
        return row[this.columnForDelete];
      }else{
        return false;
      }
    }

    hasConditionalEditIcon(row){
      if(this.hasEditIcon){
        return row[this.columnForEdit];
      }else{
        return false;
      }
    }

    hasConditionalCopyIcon(row){
      if(this.hasCopyIcon){
        return row[this.columnForCopy];
      }else{
        return false;
      }
    }


    hasConditionalInfoIcon(row){
      if(this.hasInfoIcon){
        return row[this.columnForInfo];
      }else{
        return false;
      }
    }

    InfoRow(row, rowIndex){
      this.toggleExpansion(row);
    }

    expandRow(row, rowIndex) {
      this.toggleExpansion(row);
    }

}

