import { Router } from '@angular/router';
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { startWith, map } from 'rxjs/operators';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
  selector: 'ert-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit, AfterViewInit {
  @ViewChild('toolbarFlexSpacer') toolbarFlexSpacerTextElment: ElementRef;
  @ViewChildren('search') comps: QueryList<ElementRef>;
  @ViewChildren(MatAutocompleteTrigger) triggers: QueryList<MatAutocompleteTrigger>;

  trigger: MatAutocompleteTrigger;
  search: ElementRef;
  toolbarData: any;
  onItemSelected: Function;
  formCtrl: UntypedFormControl;
  filteredItems: Observable<any[]>;

  constructor(
    private store: Store<any>,
    private router: Router,
  ) {
    this.formCtrl = new UntypedFormControl();
    this.store.subscribe(state => {
      this.toolbarData = state.toolbarState;
      // clear form after data loads
      this.formCtrl.reset();

      if (this.toolbarData.dropdownMenu) {
        this.onItemSelected = this.toolbarData.dropdownMenu.onItemSelected.bind(this);
      }
    });

  }

  ngOnInit() {
    this.filteredItems = this.formCtrl.valueChanges
      .pipe(
        startWith(''),
        map(text => {
          if (text) {
            return this.filterItems(text);
          } else {
            if (this.toolbarData.dropdownMenu && this.toolbarData.dropdownMenu.items) {
              return this.toolbarData.dropdownMenu.items.slice();
            }
            return [];
          }
        })
      );
  }

  filterItems(name) {
    const toolbarData = this.toolbarData;
    return toolbarData.dropdownMenu.items.filter(item =>
      item[toolbarData.dropdownMenu.propertyName].toLowerCase().indexOf(name.toLowerCase()) > -1);
  }

  ngAfterViewInit(): void {
    // ngIf in parent element prevent @viewchild getting search element function properly. Need to use @ViewChildren.
    this.comps.changes.subscribe((comps: QueryList<ElementRef>) => {
      if (comps.length > 0) {
        this.search = comps.first;
      }
    });

    this.triggers.changes.subscribe((trigs: QueryList<MatAutocompleteTrigger>) => {
      if (trigs.length > 0) {
        this.trigger = trigs.first;
        this.trigger.panelClosingActions
          .subscribe(e => {
            if (!(e && e.source)) {
              this.formCtrl.setValue(null);
              this.trigger.closePanel();
            }
            this.toolbarFlexSpacerTextElment.nativeElement.click();
          });
      }
    });
  }
}
