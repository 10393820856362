import { Component, OnInit, Inject, Optional } from "@angular/core";
import { MatDialog, MatDialogRef,  MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UtilsService } from "@app/shared/services/utils.service";
import { ErrorNotificationService } from '@app/core/services/error-notification.service';
import { ToastrService } from 'ngx-toastr';
import {UntypedFormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'ert-dialog-reason-for-change',
  templateUrl: './dialog-reason-for-change.component.html',
  styleUrls: ['./dialog-reason-for-change.component.scss']
})

export class DialogReasonForChangeComponent implements OnInit {

  constructor(
    private toastr: ToastrService,
    private errorNotificationService: ErrorNotificationService,
    public dialogRef: MatDialogRef<DialogReasonForChangeComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public diStudyList: any
  ) {
  }

  reasonForChangeControl = new UntypedFormControl('',[Validators.required]);
  reasonForChange = '';
  matcher = new MyErrorStateMatcher();

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close(null);
  }

  onConfirm(): void {
    if(this.reasonForChange.trim().length > 0) {
      this.dialogRef.close(this.reasonForChange);
    }
  }
}
