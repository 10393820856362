import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ViewChild,
  ElementRef,
  ViewEncapsulation
} from "@angular/core";
import * as d3 from "d3";

const axisSx = 150;
const axisSy = 25;
const legendPadding = 3;
const legendWidthPct = 0.75;
const legendHeightPct = 0.15;
const highlightColor = "#c4ed55";

@Component({
  selector: "ert-tornado-chart",
  templateUrl: "./tornado-chart.component.html",
  styleUrls: ["./tornado-chart.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class TornadoChartComponent implements OnInit {
  @ViewChild("tornado", { static: true, read: ElementRef })
  private chartContainer: ElementRef;
  firstDraw: boolean = true;
  @Input() data: any;
  @Input() private xAxisLabel: string;
  @Input() private yAxisLabel: string;

  private svg: any = null;
  private margin: any = { top: 20, right: 60, bottom: 30, left: 60 };
  private width: number;
  private height: number;
  private legendSvg: any;

  constructor() {}

  ngOnInit() {
    this.firstDraw = false;
    this.drawChart();
    window.addEventListener("resize", () => {
      if(this.data) {
        this.drawChart.bind(this);
      }
    });
  }

  ngOnChanges(changes) {    
    if(this.data){
      this.drawChart();
    }
  }

  drawChart() {
    //when there is no data, do nothing
    if(!this.data)
      return;

    const element = this.chartContainer.nativeElement;
    this.svg = null;
    this.width = element.offsetWidth - this.margin.left - this.margin.right;
    this.height = element.offsetWidth > 230 ? 230 : element.offsetWidth;
    this.width = this.width < 45 ? 80 : this.width;
    const tHeight = this.height - this.margin.top - this.margin.bottom;
    const lHeight = Math.round(tHeight * legendHeightPct) - 2;

    if (!this.firstDraw) {
      d3.select(element)
        .selectAll("svg")
        .remove();

      d3.select(element)
        .selectAll(".tornado_tooltip")
        .remove();

      this.svg = null;
      this.legendSvg = null;
    }

    var x = d3.scaleLinear().range([0, this.width]);
    var isOnToolTip = false;

    var y = d3
      .scaleBand()
      .range([0, this.height])
      .paddingInner(0.3);

    var formatter = d3.format("0");

    // create legend svg
    this.legendSvg = d3
      .select(element)
      .append("svg")
      .attr("width", this.width)
      .attr("height", lHeight)
      .append("g")
      .attr("transform", `translate(0, 0)`);

    var xAxis = d3
      .axisBottom(x)
      .ticks(6)
      .tickFormat(function(d) {
        if (d < 0) d = -d; // No negative labels
        return formatter(d);
      });

    var yAxis = d3.axisLeft(y).tickSize(0);

    // add the tooltip area to the svg
    var tooltip = null;
    var main_svg = d3
      .select(element)
      .append("svg")
      .attr("width", this.width + this.margin.left + this.margin.right)
      .attr("height", this.height + this.margin.top + this.margin.bottom);

    this.svg = main_svg
      .append("g")
      .attr(
        "transform",
        "translate(" + this.margin.left + "," + this.margin.top + ")"
      );

    //To remove tooltip when mouse moved out of the tornado chart
    var isOnTooltip = false;
    d3.select(element).on("mouseleave", function(e) {

        if(d3.event.relatedTarget && d3.event.relatedTarget != null && d3.event.relatedTarget.id == "tornado_tooltip")
          isOnTooltip =true;

        if(!isOnTooltip){
          d3.select("body")
            .selectAll(".tornado_tooltip")
            .remove();
          tooltip = null;
        }
    }).on("wheel", function(e) {
        d3.select("body")
          .selectAll(".tornado_tooltip")
          .remove();
        tooltip = null;
    });

    var complianceMaxValue = Math.max.apply(
      Math,
      this.data.map(function(d) {
        return d.compliance;
      })
    );
    var qualityMaxValue = Math.max.apply(
      Math,
      this.data.map(function(d) {
        return d.quality;
      })
    );

    x.domain([complianceMaxValue * -1, qualityMaxValue]);

    y.domain(
      this.data.map(function(d) {
        return d.site.no + ":" + d.study.name;
      })
    );

    yAxis
      .tickPadding(Math.abs(x(complianceMaxValue) - x(0)) + 10)
      .tickFormat(function(d, i) {
        return d.split(":")[0];
      });

    var bar = this.svg.selectAll(".bar").data(this.data);

    //draw compliance rectangle
    bar
      .enter()
      .append("rect")
      .attr("class", "bar--compliance")
      .attr("x", function(d) {
        return x(Math.min(0, d.compliance * -1));
      })
      .attr("y", function(d) {
        return y(d.site.no + ":" + d.study.name);
      })
      .attr("width", function(d) {
        return Math.abs(x(d.compliance) - x(0));
      })
      .attr("height", y.bandwidth())
      .on("mouseover", function(d) {
        d3.select("body").selectAll(".tornado_tooltip").remove();
        //add the tooltip area to the svg
        tooltip = d3.select("body").append("div")
                    .attr("id", "tornado_tooltip")
                    .attr("class", "tornado_tooltip")
                    .on("mouseover", function(d){
                      isOnToolTip = true;
                    })
                    .on("mouseleave", function(d) {
                          d3.select("body").selectAll(".tornado_tooltip").remove();
                          tooltip = null;
                          isOnTooltip = false;
                      });

        var studyHtml =
          "<a href='/dashboard/study/" +
          d.study.id +
          "'>" +
          d.study.name +
          "</a>";
        var siteHtml =
          "<a href='dashboard/study/" +
          d.study.id +
          "/site/" +
          d.site.id +
          "'>" +
          d.site.no +
          "</a>";

        //var mouseCoords = d3.mouse(tooltip.node().parentElement);

        tooltip
          .html(
            "<span class='study-style'>" +
              studyHtml +
              " | " +
              siteHtml +
              "</span>" +
              "<br/>Compliance: " +
              d.compliance +
              "<br/> Quality: " +
              d.quality +
              "<br/> Overall: " +
              d.overall
          )
          .style('left', (d3.event.pageX) + 10 + 'px')
          .style('top', (d3.event.pageY) - 5 + 'px');
      });

    //draw quality rectangle
    bar
      .enter()
      .append("rect")
      .attr("class", "bar--quality")
      .attr("x", function(d) {
        return x(Math.min(0, d.quality));
      })
      .attr("y", function(d) {
        return y(d.site.no + ":" + d.study.name);
      })
      .attr("width", function(d) {
        return Math.abs(x(d.quality) - x(0));
      })
      .attr("height", y.bandwidth())
      .on("mouseover", function(d) {
        d3.select("body").selectAll(".tornado_tooltip").remove();
        //add the tooltip area to the svg
        tooltip = d3.select("body").append("div")
                    .attr("id", "tornado_tooltip")
                    .attr("class", "tornado_tooltip")
                    .on("mouseover", function(){
                      isOnToolTip = true;
                    })
                    .on("mouseleave", function(d) {
                          d3.select("body").selectAll(".tornado_tooltip").remove();
                          tooltip = null;
                          isOnTooltip = false;
                      });

        var studyHtml =
          "<a href='/dashboard/study/" +
          d.study.id +
          "'>" +
          d.study.name +
          "</a>";
        var siteHtml =
          "<a href='dashboard/study/" +
          d.study.id +
          "/site/" +
          d.site.id +
          "'>" +
          d.site.no +
          "</a>";

        //var mouseCoords = d3.mouse(tooltip.node().parentElement);

        tooltip
          .html(
            "<span class='study-style'>" +
              studyHtml +
              " | " +
              siteHtml +
              "</span>" +
              "<br/>Compliance: " +
              d.compliance +
              "<br/> Quality: " +
              d.quality +
              "<br/> Overall: " +
              d.overall
          )
          .style('left', (d3.event.pageX) + 10 + 'px')
          .style('top', (d3.event.pageY) - 5 + 'px');
      });

    // x-axis
    this.svg
      .append("g")
      .attr("class", "ms-axis")
      .attr("transform", "translate(0," + this.height + ")")
      .call(xAxis);

    // y-axis
    this.svg
      .append("g")
      .attr("class", "ms-axis")
      .attr("transform", "translate(" + x(0) + ",0)")
      .call(yAxis);

    // text label for the x axis
    this.svg
      .append("text")
      .attr("class", "axis-label")
      .attr("alignment-baseline", "baseline")
      .attr(
        "transform",
        "translate(" +
          this.width / 2 +
          " ," +
          (this.height + this.margin.top + 5) +
          ")"
      )
      .style("text-anchor", "middle")
      .text(this.xAxisLabel);

    // text label for the y axis
    this.svg
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("class", "axis-label")
      .attr("alignment-baseline", "baseline")
      .attr("y", 0 - this.margin.left)
      .attr("x", 0 - this.height / 2)
      .attr("dy", "1em")
      .style("text-anchor", "middle")
      .text(this.yAxisLabel);

    this.drawLegend(this.width, lHeight);
  }

  drawLegend(width, height) {
    // parametric legend
    const tWidth = (width - 50) * legendWidthPct;
    const bWidth = Math.floor(tWidth / 2);
    const lx = (width - tWidth) / 2;
    const l_height = height - 2 * legendPadding;
    const lSide = l_height - l_height / 2;
    const ox = lx + axisSx + lSide + l_height / 4;
    const legend_details = [
      { legend: "Compliance", color_class: "bar--compliance" },
      { legend: "Quality", color_class: "bar--quality" }
    ];

    // background
    this.legendSvg
      .selectAll(".background")
      .data([0])
      .enter()
      .append("rect")
      .attr("x", lx + axisSx)
      .attr("y", 0)
      .attr("width", tWidth)
      .attr("height", l_height)
      .attr("class", "legend-box");

    // legend color boxes
    this.legendSvg
      .selectAll(".legend")
      .data(legend_details)
      .enter()
      .append("rect")
      .attr("class", d => d.color_class)
      .attr("x", (d, i) => ox + i * bWidth - lSide / 2)
      .attr("y", (d, i) => (l_height - lSide) / 2)
      .attr("width", lSide)
      .attr("height", lSide);

    this.legendSvg
      .selectAll(".legendText")
      .data(legend_details)
      .enter()
      .append("text")
      .attr("class", "legend-text")
      .attr("x", (d, i) => lSide + ox + i * bWidth)
      .attr("y", (d, i) => l_height / 2)
      .attr("text-anchor", "start")
      .attr("alignment-baseline", "middle")
      .text((d, i) => d.legend);
  }
}
