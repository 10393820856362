import { Observable } from 'rxjs';
import { EndpointService } from '@app/shared/services/endpoint.service';
import { DataService } from '@app/shared/services/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GenerateTaskComponent } from '@app/alerts/modal/generate-task/generate-task.component';
import { SetBreadCrumbs } from '@app/core/store/breadcrumbs/breadcrumbs.actions';
import { Store } from '@ngrx/store';
import { ActionAppSetAlerts, ActionAppSetTasks } from '@app/core/store/app.actions';

@Component({
  selector: 'ert-alert-message-detail',
  templateUrl: './message-detail.component.html',
  styleUrls: ['./message-detail.component.scss']
})
export class AlertMessageDetailComponent implements OnInit {
  from = '';
  item$: Observable<any>;
  item: any;
  loading = true;
  alertId;
  constructor(
    private route: ActivatedRoute,
    private dataService: DataService<any>,
    private endpointService: EndpointService,
    public dialog: MatDialog,
    public router: Router,
    private store: Store<any>,
  ) { }

  mitigationStrategies = {
    'dataReview': 'Data Review',
    'onSiteMonitoring': 'On Site Monitoring Visit',
    'remoteMonitoring': 'Remote Monitoring Visit',
    'training': 'Training',
    'contactSite': 'Contact Site',
    'other': 'Other',
  };

  ngOnInit() {
    this.route.url.subscribe(url => {
      this.from = '/dashboard/alerts';
      this.route.params.subscribe(params => {
        const endpoint = this.endpointService.getEndpoint('singleAlert', params.id);

        this.item$ = this.dataService.getOne(endpoint);
        this.item$.subscribe(data => {
          if (data.result[0]) {
            const resultData = this.formatResult(data.result[0]);
            this.item = resultData;
            this.store.dispatch(new SetBreadCrumbs({
              crumbs: [
                { text: 'Alerts', url: 'alerts' },
                { text: `${resultData.alertTitle}`, url: `alerts/detail/${params.id}` },
              ],
            }));

            this.alertId = params.id;
            if (!this.item.read) {
              const readEndpoint = this.endpointService.getEndpoint('singleAlert', params.id);
              this.dataService.update(readEndpoint, { 'action': 'read' }).subscribe(response => {
                const alertEndpoint = this.endpointService.getEndpoint('alerts');
                this.dataService.getAll(alertEndpoint).subscribe((alerts: any) => {
                  const count = alerts.result.filter(items => !items.read).length;
                  this.store.dispatch(new ActionAppSetAlerts(count));
                });
              });
            }

            this.loading = false;
          }
        });
      });
    });

  }

  makeAlertTitle(data) {
    return data.isUserGenerated ? 'User ' : '' + 'Generated Alert';
  }

  formatCalcValue(val, units) {
    let tval = null;
    if (val) {
      switch (units) {
        case '%':
          tval = Math.round(+val * 100).toString();
          break;

        case 'Days':
        case 'Subjects':
        case 'Sites':
          tval = Math.round(+val).toString() + ' ';
          break;

        default:
          tval = ((parseFloat(val)).toFixed(2)).toString() + ' ';
      }
      tval += ' ' + units;
    }
    return tval;
  }

  makeAlertDescription(data) {
    let desc = '';
    if (data.thresholdOperator) {
      desc = ('Value ' + ((data.thresholdOperator === 'GTE') ? 'above' : 'below') + ' specified threshold.');
    } else {
      if (data.msgTemplate === 'MILESTONE') {
        desc = 'Expected date approaching and MILESTONE not met.';
      } else {
        desc = data.msgTemplate + ' reached';
      }
    }
    return desc;
  }

  formatResult(data) {
    if (data.isUserGenerated) {
      data.subject = 'User Generated Alert';
      data.alertTitle = data.alertMessageData.alertTitle;
      data.alertMessage = data.alertMessageData.alertMessage;
    } else {
      data.subject = 'System Generated Alert';
      data.alertTitle = data.alertMessageData.msgTemplate + ' Event';
      data.alertMessage = this.makeAlertDescription(data.alertMessageData);
      data.thresholdShortVal = this.formatCalcValue(data.alertMessageData.thresholdValue, data.alertMessageData.units);
      data.metricValue = data.alertMessageData.calcValue!==null ?
        this.formatCalcValue(data.alertMessageData.calcValue, data.alertMessageData.units) : data.alertMessageData.projectedDate;

      //cleanup the mitigation strategy so we can display a nicely formated version to the front end
      data.mitigationStrategyNice = this.mitigationStrategies[data.alertMessageData.mitigationStrategy];
    }
    return data;
  }

  ignoreAlert(item) {
    const readEndpoint = this.endpointService.getEndpoint('singleAlert', item.studyAlertLogId);
    this.dataService.update(readEndpoint, { 'action': 'ignore' }).subscribe(response => { });
    this.router.navigate(['/dashboard/alerts']);
  }

  generateTask(item) {
    const dialogRef = this.dialog.open(GenerateTaskComponent, {
      data: item
    });

    dialogRef.afterClosed().subscribe(result => {
      this.router.navigate([`/dashboard/alerts/detail/${this.alertId}`]);
    });
  }

}
