import { AfterViewInit, Component, Input, OnInit, OnChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import * as CodeMirror from 'codemirror';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/sql/sql';
import 'codemirror/addon/lint/lint';
import { CodeMirrorService } from '@shared/code-mirror/code-mirror.service';

// If additional modes(languages) is needed import the mode file above and add it here
export const CODE_MIRROR_MODES = {
  SQL: 'text/x-sql',
  PGSQL: 'text/x-pgsql',
  JS: 'text/javascript',
};

interface CodeMirrorConfig {
  mode: object;
  value: string;
  indentUnit?: number;
  tabSize?: number;
  lineWrapping?: boolean;
  lineNumbers?: boolean;
  height?: string;
  width?: string;
  readOnly?: boolean;
  spellcheck?: boolean;
}

@Component({
  selector: 'ert-code-mirror',
  templateUrl: './code-mirror.component.html',
  styleUrls: [ './code-mirror.component.scss' ],
  encapsulation: ViewEncapsulation.None,
})
export class CodeMirrorComponent implements OnInit, AfterViewInit, OnChanges {
  codeMirror = CodeMirror;
  instance;
  defaultConfig = {
    value: '',
    mode: CODE_MIRROR_MODES.PGSQL, // Set Editor Language
    indentUnit: 2,
    // theme: 'material',// view more themes on https://codemirror.net/demo/theme.html#material-ocean and import the correct theme.css
    tabSize: 4,
    lineWrapping: true,
    lineNumbers: true,
    spellcheck: true,
    smartIndent: true,
    height: '220px',
  };

  @ViewChild('textarea') textArea;
  @Input() config: CodeMirrorConfig;
  @Input() codeMirrorId: string;

  constructor(
    private codeMirrorDataService: CodeMirrorService
  ) {
  }

  ngOnInit() {
    this.getAllowedModes();

  }

  ngAfterViewInit() {
    this.config = { ...this.defaultConfig, ...this.config };
    // this.codemirrorInit(this.config);
    this.setupCodeMirror();
  }


  ngOnChanges(changes) {
      setTimeout(() => {
      if (this.instance && !changes.config.firstChange) {
      this.config = { ...this.defaultConfig, ...this.config };
      if (this.config.value) {
        this.instance.doc.setValue(this.config.value);
        this.codeMirrorDataService.setData(this.codeMirrorId, this.config.value);
      }
    }
    });
  }

  setupCodeMirror() {
    this.instance = this.codeMirror.fromTextArea(this.textArea.nativeElement, this.config);
    this.instance.setSize(this.config.width, this.config.height);

    this.instance.doc.setValue(this.config.value);
    this.codeMirrorDataService.setData(this.codeMirrorId, this.config.value);

    this.instance.on('change', () => {
      this.codeMirrorDataService.setData(this.codeMirrorId, this.getInstanceValue(), true);
    });
    //
    // this.instance.on('focus', (instance, event) => {
    //   this.focus.emit({instance, event});
    // });
    //
    // this.instance.on('cursorActivity', (instance) => {
    //   this.cursorActivity.emit({instance});
    // });
    //
    // this.instance.on('blur', (instance, event) => {
    //   this.blur.emit({instance, event});
    // });
  }

  private getAllowedModes() {
    // console.log(this.codeMirror.modes);
    // console.log(this.codeMirror.mimeModes);
    // console.log(this.codeMirror.modeInfo);
  }

  // remove comments from sql
  getInstanceValue() {
    return this.instance.doc.getValue().replace(/\/\*.*?\*\/|--.*?\n/gsm, '').trim();
  }
}
