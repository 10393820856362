import { HeaderDropdownLink } from './../model/header-dropdown.interface';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ert-header-dropdown',
  templateUrl: './header-dropdown.component.html',
  styleUrls: ['./header-dropdown.component.scss']
})
export class HeaderDropdownComponent implements OnInit {
  dropdownOpen = false;
  @Input() title: string;
  @Input() links: HeaderDropdownLink[];
  @Input() icon = 'es-icon-application-mode';

  constructor() { }

  ngOnInit() {
  }

}
