import { Environment } from './models/environment.interface';
// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=dev` then `environment.dev.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
const isDataInsights = (window.location.hostname.includes('datainsights') || (window.location.hostname.includes('localhost')));
export const environment: Environment = {
  appName: 'Data Insights DEV Environment',
  envName: 'DEV',
  production: false,
  apiBase: isDataInsights ? 'https://api.datainsights.dev.ert.com' : 'https://api.dev.trialoversight.io',
  version: 'develop-13122024-10:48:05',
};
