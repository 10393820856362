<div class="metric__info-banner">
    <h2 class="metric__info-banner-heading">{{heading}}</h2>
    <div class="metric__info-banner-scores">
                    <h2 *ngIf="metricScore" class="metric__info-banner-bucket"><span class="metric__info-banner_score"
                                    [ngStyle]="{'background': getScoreColor(metricScore)}">{{metricScore}}</span>
                            {{metricName}} </h2>
                    <h2 *ngIf="!metricScore" class="metric__info-banner-bucket"><span class="metric__info-banner_score"
                                    [ngStyle]="{'background': getScoreColor(null), 'color': '#000'}"><ert-no-value></ert-no-value></span> {{metricName}}
                    </h2>
    </div>
</div>