import {
  Directive,
  ElementRef,
  HostListener,
  Input
} from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[ErtDurationTime]'
})
export class DurationTimeDirectiveDirective {
  private navigationKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', ':'];
  @Input()
  picker: HTMLInputElement;

  constructor(
    public el: ElementRef,
    private formField: NgControl
    ) {
    this.picker = el.nativeElement;
    //this.picker.value = '00:05';
    // this.picker.style.textAlign = 'right';
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent) {
    if (this.navigationKeys.indexOf(e.key) > -1) {
      // let it happen, don't do anything
      return;
    }
    if (e.key === ' ' || isNaN(Number(e.key))) {
      e.preventDefault();
    }
  }

  @HostListener('change', ['$event']) ngOnChanges(e) {
    this.validateInput(e);
  }

  @HostListener('click', ['$event']) ngOnClick(e) {
    this.selectFocus(e);
  }

  @HostListener('blur', ['$event']) ngBlur(e) {
    this.validateInput(e);
  }

  validateInput(event) {
    const sectioned = event.target.value.split(':');
    if (sectioned.length !== 2) {
      sectioned[0] = '00';
      sectioned[1] = '01';
      return;
    }
    if(parseInt(sectioned[0]) + parseInt(sectioned[1]) == 0) {
      sectioned[0] = '00';
      sectioned[1] = '01';
    }

    if (isNaN(sectioned[0])) {
      sectioned[0] = '00';
    }
    if (isNaN(sectioned[1]) || sectioned[1] < 0) {
      sectioned[1] = '01';
    }
    if (sectioned[1] > 59 || sectioned[1].length > 2) {
      sectioned[1] = '59';
    }
    event.target.value = sectioned.join(':');
    this.formField.control.setValue(event.target.value);
  }

  selectFocus = event => {
    const cursorPosition = event.target.selectionStart;
    // '00:00' this is the format used to determine cursor location
    const hourMarker = event.target && event.target.value && event.target.value.indexOf(':');
    if (!hourMarker || hourMarker < 0) {
      return;
    }
    if (cursorPosition < hourMarker) {
      event.target.selectionStart = 0; // hours mode
      event.target.selectionEnd = hourMarker;
    }
    if(cursorPosition > hourMarker) {
      event.target.selectionStart = hourMarker + 1;
      event.target.selectionEnd = event.target.value.length;
    }
   };
}
