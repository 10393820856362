<div style="position: relative;">
    <div class="dialog-title">
        <h4>CLEAR CALC LOCK</h4>
        <span class="cancel_icon">
            <button class="icon-button cursor-pointer" (click)="onCancel()">
                <svg viewBox="0 0 384 512" fill="currentColor">
                    <path d="M345 137c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-119 119L73 103c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l119 119L39 375c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l119-119L311 409c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-119-119L345 137z"/>
                </svg>
            </button>
        </span>
    </div>
    <p>Are you sure to clear the calc lock?</p>
    <div class="action_buttons">
        <button class="button cursor-pointer" data-type="negative" type="button" (click)="clearCalcLock()" [disabled]="selectedConfirm == 'selected'" >Confirm</button>
        <button class="button cursor-pointer" style="margin-left: 15px;" data-type="clear" type="button" (click)="onCancel()">Cancel</button>
    </div>
</div>
