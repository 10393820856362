export interface Point {
  x: number;
  y: number;
}
export interface LineChartOptions {
  legend?: string;
  units?: string;
  lineWidth?: number;
  dashPattern?: string;
  color?: string;
  curveType?: number;
  dotRadius?: number;
  showProjection?: boolean,
  dotColor?: (score: number) => string;
  data: Point[];
}
