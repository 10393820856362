// import { CompileShallowModuleMetadata } from '@angular/compiler';
import {Component, Inject, OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA  } from '@angular/material/dialog';
@Component({
  selector: 'ert-latest-refresh-dialog',
  templateUrl: './latest-refresh-dialog.html',
  styleUrls: ['./latest-refresh-dialog.scss']
})

export class LatestRefreshDialog implements OnInit{
    dataTitle : any;
    parsedJobDetail : {"jobs":[]} ;
    resultJobs : any = [];
    refreshData = {
        notes:'',
        datasourceId:'',
        datasourceName:'',
        refreshJobId:'',
        refreshJobMode:'',
        refreshJobType:'',
        progress:'',
        createdAt:'',
        startedAt:'',
        completedAt:'',
    }
  constructor(
    public dialogRef: MatDialogRef<LatestRefreshDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.parsedJobDetail = this.data.dialogData;
    if(this.parsedJobDetail.jobs.length > 0){
      for(var x = 0; x < this.parsedJobDetail.jobs.length; x++) {
        this.resultJobs.push(JSON.parse(this.parsedJobDetail.jobs[x]));
      }
    }
  }
  
  closeDownloadDialog(option:string) {
    this.dialogRef.close(option);
  }
}
