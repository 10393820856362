import { DataService, EndpointService } from '@shared/services';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UpdateToolbar } from '@app/core/store/toolbar/toolbar.actions';
import { SetBreadCrumbs } from '@app/core/store/breadcrumbs/breadcrumbs.actions';
import dateFormat from 'dateformat';

@Component({
  selector: 'ert-message-list',
  templateUrl: './message-list.component.html',
  styleUrls: ['./message-list.component.scss']
})
export class AlertMessageListComponent<T> implements OnInit {

  displayedData;
  displayedColumns = ['studyAlertLogId', 'createdDate', 'studyProtocolNumber', 'siteNo', 'alertTitle', 'alertMessage', 'status'];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  pageEvent: PageEvent;
  selectedDetail = false;
  // dateformat = require('dateformat');

  constructor(
    private store: Store<any>,
    private endpointService: EndpointService,
    private dataService: DataService<any>,
    public dialog: MatDialog

  ) { }

  ngOnInit() {
    const alertsEndpoint = this.endpointService.getEndpoint('alerts');
    this.dataService.getAll(alertsEndpoint).subscribe((alerts: any) => {
      const mapped = this.formatColumns(alerts.result);
      this.initUserTable(mapped);
    });

    this.store.dispatch(new UpdateToolbar({
      title: 'Alerts',
    }));
    this.store.dispatch(new SetBreadCrumbs({
      crumbs: [
        { text: 'Alerts', url: 'alerts' },
      ],
    }));
  }

  makeAlertTitle(data) {
    return 'Study: ' + data.srcStudyId + ' ' + (data.calcTypeName || data.msgTemplate);
  }

  makeAlertDescription(data) {
    let desc = data.msgTemplate;
    if (data.msgTemplate === 'THRESHOLD' ) {
      let tval = '';
      switch (data.units) {
        case '%':
          tval = Math.round(+data.thresholdValue * 100).toString();
          break;

        case 'Days':
        case 'Subjects':
        case 'Sites':
          tval = Math.round(+data.thresholdValue).toString() + ' ';
          break;

        default:
          tval = (+data.thresholdValue.toFixed(2)).toString() + ' ';
      }
      desc += '= ' + tval + data.units + ' Value= ' + data.calcShortDisplayValue;
    } else if(data.msgTemplate === 'GOAL') {
      desc += ' reached';
    } else {
      desc += ' not met'
    }
    return desc;
  }

  formatColumns(data) {
    return data.map(e => {
      if (e.isUserGenerated) {
        return {
          studyAlertLogId: e.studyAlertLogId,
          studyProtocolNumber: e.studyProtocolNumber,
          siteNo: e.siteNo,
          alertTitle: e.alertMessageData.alertTitle,
          createdDate: e.createdDate,
          read: e.read,
          status: e.read ? 'Read' : 'New',
          alertMessage: e.alertMessageData.alertMessage
        };
      } else {
        return {
          studyAlertLogId: e.studyAlertLogId,
          createdDate: e.createdDate,
          siteNo: e.siteNo,
          studyProtocolNumber: e.studyProtocolNumber,
          read: e.read,
          status: e.read ? 'Read' : 'New',
          alertMessage: this.makeAlertDescription(e.alertMessageData),
          alertTitle: this.makeAlertTitle(e.alertMessageData)
        };
      }
    });
  }

  initUserTable(alerts) {
    this.displayedData = new MatTableDataSource(alerts);
    // Custom Filter
    this.displayedData.filterPredicate = (data, filter: String) => {
      const lowerFilter = filter.trim().toLowerCase();
      return this.checkDate(data.createdDate, lowerFilter) || this.checkFilter(data.alertTitle, lowerFilter) ||
        this.checkFilter(data.alertMessage, lowerFilter) || this.checkFilter(data.status, lowerFilter)
        || this.checkFilter(data.studyAlertLogId, lowerFilter);
    };
    this.displayedData.paginator = this.paginator;
    this.displayedData.sort = this.sort;
    this.displayedData.sortingDataAccessor = (datas: any, header: string): any => {
       if (typeof datas[header] === 'string') {
        return datas[header].trim().toLocaleLowerCase();
      } else {
        return datas[header];
      }
    };
  }

  checkFilter(item, lowerFilter) {
    if (item === 'string') {
      return item.trim().toLowerCase().indexOf(lowerFilter) !== -1;
    } else {
      return item.toString().trim().toLowerCase().indexOf(lowerFilter) !== -1;
    }
  }

  checkDate(item, lowerFilter) {
    return dateFormat(item, 'dd-mmm-yyyy').toString().trim().toLowerCase().indexOf(lowerFilter) !== -1;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.displayedData.filter = filterValue;
  }

}
