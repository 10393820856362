import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ViewChild,
  ElementRef,
  ViewEncapsulation
} from "@angular/core";
import * as d3 from "d3";
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: "ert-metric-chart",
  templateUrl: "./metric-chart.component.html",
  styleUrls: ["./metric-chart.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class MetricChartComponent implements OnInit {
  @ViewChild("metric", { static: true, read: ElementRef })
  private chartContainer: ElementRef;
  firstDraw: boolean = true;
  @Input() data: any;
  @Input() private xAxisLabel: string;
  @Input() private yAxisLabel: string;
  @Input() private titleLabel: string;
  private svg: any = null;
  private margin: any = { top: 20, right: 60, bottom: 30, left: 60 };
  private width: number;
  private height: number;
  private studyId: number;
  private siteId: number;

  private xScale: any;
  private yScale: any;

  constructor(
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe(params => {
      this.studyId = +params.studyId;
      this.siteId = +params.siteId;
    });
  }

  ngOnInit() {
    this.firstDraw = false;
    if(this.data){
      this.drawChart();
    }
    window.addEventListener("resize", () => {
      if(this.data) {
        this.drawChart.bind(this);
      }
    });
  }

  ngOnChanges(changes) {    
    if(this.data){
      this.drawChart();
    }
  }

  drawChart() {
    const element = this.chartContainer.nativeElement;
    const maxWidth = 75 * this.data.length;
    const responseWidth =
      element.offsetWidth - this.margin.left - this.margin.right;
    this.width = responseWidth > maxWidth ? maxWidth : responseWidth;
    this.height = element.offsetWidth > 230 ? 230 : element.offsetWidth;

    if (!this.firstDraw) {
      d3.select(element)
        .selectAll("svg")
        .remove();
      this.svg = null;
    }

    // create scales
    this.xScale = d3
      .scaleBand()
      .padding(0.3)
      .domain(d3.range(this.data.length))
      .rangeRound([0, this.width]);

    this.yScale = d3
      .scaleLinear()
      .domain([0, 100])
      .range([0, this.height]);

    const xAxis = d3.axisBottom(this.xScale).tickSize(0);

    this.svg = d3
      .select(element)
      .append("svg")
      .attr("class", "bar-chart")
      .attr("width", this.width)
      .attr("height", this.height + 100);

    var chart = this.svg
      .selectAll("rect")
      .data(this.data)
      .enter();

    //Create score rectangles      
    chart
      .append("rect")
      .attr("fill", d => d.color)
      .attr("height", d => this.yScale(d.score))
      .attr("y", d => this.height - this.yScale(d.score))
      .attr("x", (d, i) => this.xScale(i))
      .attr("width", this.xScale.bandwidth());

    //Create ERT logo colored rect       
    chart
      .append("rect")
      .attr("class", "ert-rect")      
      .attr("height", d => this.yScale(100))
      .attr("y", d => this.height - this.yScale(100) - this.yScale(d.score))
      .attr("x", (d, i) => this.xScale(i))
      .attr("width", this.xScale.bandwidth());

    //Create score text
    chart
      .append("text")
      .text(function(d) {
        return d.score;
      })
      .attr("class", "score-label")
      .attr("text-anchor", "middle")
      .attr("x", (d, i) => this.xScale(i) + this.xScale.bandwidth() / 10 + 18)
      .attr("y", d =>
        d.score < 94
          ? this.height - this.yScale(d.score) - 5
          : this.height - this.yScale(d.score) + 15
      );

    //Create X-axis labels
    this.svg
      .append("g")
      .attr("alignment-baseline", "baseline")
      .attr("class", "tick")
      .attr("text-anchor", "middle")
      .attr("transform", `translate(${0}, ${this.height - 1})`)
      .call(xAxis.tickFormat((d, i) => this.data[i].metricName))
      .call(g => g.select(".domain").remove())
      .selectAll(".tick text")
      .attr("class", "x-bar-label")
      .attr("url", (d, i) => "/dashboard/study/" + this.studyId + "/site/" + this.siteId + "/metric/" + this.data[i].id)
      .call(this.wrap, this.xScale.bandwidth());  
  }

  wrap(text, width) {
    text.each(function () {
      let text = d3.select(this), word, line = [], lineNumber = 0, lineHeight = 1.1; // ems
      const words = text.text().split(/\s+/).reverse();
      const y = text.attr('y');
      let url = text.attr("url");
      const dy = parseFloat(text.attr('dy'));
      let anchor = text.text(null).append('a').attr('class', 'metric-chart-x-access').attr('href',url);
      let tspan = anchor.append('tspan').attr('x', 0).attr('y', y).attr('dy', dy + 'em');
      while (word = words.pop()) {
        line.push(word)
        tspan.text(line.join(' '))
        if (tspan.node().getComputedTextLength() > width) {
          line.pop();
          tspan.text(line.join(' '));
          line = [word];
          tspan = anchor.append('tspan').attr('x', 0).attr('y', y).attr('dy', `${++lineNumber * lineHeight + dy}em`).text(word)
        }
      }
    });
  }

  ngOnDestroy() {
    window.removeEventListener("resize", this.drawChart.bind(this));
  }
}
