import { Threshold } from './../models/threshold.model';
import { ActivatedRoute } from '@angular/router';
import { StudyService } from '@shared/services/study.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnChanges, Inject, DoCheck, KeyValueDiffer, KeyValueDiffers, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormControl, FormArray } from '@angular/forms';
import { distinctUntilChanged } from 'rxjs/operators';


// map controlling what units to show, and min/max for threshold
const calcDataConfig = {
  validation: {
    // forbid negative values from any calc types
    shared: {
      min: 0,
      max: 1000000,
    },
  },
  // behavior for slider
  sliderConfig: {
    shared: {
      min: 0,
      max: 100,
      step: 1,
    },
    rate: {
      max: 10,
      step: .1,
    },
    number: {
      max: 1000,
    },
    duration: {
      max: 50,
    },
  }
};

@Component({
  selector: 'ert-threshold-config',
  templateUrl: './threshold-config.component.html',
  styleUrls: ['./threshold-config.component.scss']
})
export class ThresholdConfigComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() name: string;
  @Input() isSavingThreshold: boolean;
  @Input() studyId: number;
  @Input() threshold: any;
  @Input() type;
  @Output() submitThreshold = new EventEmitter();

  @ViewChild('formElem') formElem;

  // min date selectable from date pickers
  minDate: Date = new Date();
  // threshold: any;

  form: UntypedFormGroup;
  thresholdValue;

  rangeThreshold = { min: 0, max: 1 };

  shouldGenerateAlert = false;
  buttonText;
  buttonActive = false;
  otherMitigationStrategies: string;

  // values put in here for now
  mitigationStrategies = [
    { value: 'dataReview', display: 'Data Review' },
    { value: 'onSiteMonitoring', display: 'On Site Monitoring Visit' },
    { value: 'remoteMonitoring', display: 'Remote Monitoring Visit' },
    { value: 'training', display: 'Training' },
    { value: 'contactSite', display: 'Contact Site' },
    { value: 'other', display: 'Other' },
  ];

  constructor(
    public dialogRef: MatDialogRef<ThresholdConfigComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ref: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.otherMitigationStrategies = this.threshold.otherMitigationStrategy;
    this.buttonText = (this.threshold) ? 'Update' : 'Create';
    // this.rangeThresholdDiffer = this.differs.find(this.rangeThreshold).create();
    if (this.threshold) {
      this.thresholdValue = this.threshold.displayValue
        ? this.roundThresholdValue(parseFloat(this.threshold.displayValue)) : '';

      // this.onThresholdChange(this.threshold);
      this.onStrategyChanged();
    }
  }

  ngAfterViewInit() {
    this.ref.detectChanges();
  }

  initForm() {
    this.form = new UntypedFormGroup({
      // thresholdFields: new FormArray([]),
      active: new UntypedFormControl(true, []),
      thresholdValue: new UntypedFormControl('' , [Validators.required, Validators.min(0)]),
      thresholdRangeMin: new UntypedFormControl('', this.name == 'KRI' ? [Validators.required] : []), // ThresholdRange is input for max only
      thresholdRangeMax: new UntypedFormControl('', this.name == 'KRI' ? [Validators.required] : []), // ThresholdRange is input for max only
      thresholdOperator: new UntypedFormControl('', this.name == 'KRI' ? [Validators.required] : []),
      mitigationStrategy: new UntypedFormControl('', [Validators.required]),
      // if "other" is selected
      otherMitigationStrategy: new UntypedFormControl(
        this.threshold.otherMitigationStrategy === null ? null : this.threshold.otherMitigationStrategy, []),
      reasonForChange: new UntypedFormControl('', [Validators.required]),
      weight: new UntypedFormControl('', [Validators.required]),
      alertStartDate: new UntypedFormControl(this.threshold.alertStartDate === null ? '' : this.threshold.alertStartDate, [])

    });
  }

  // correctly round to 2 decimal place, otherwise input shows 2.90000000004
  roundThresholdValue(value: number): number {
    return Math.round(value * 100) / 100;
  }

  // update threshold value on screen real-time as user moves slider
  onSliderChange(event: any) {
    const { value: thresholdValue } = event;
    this.thresholdValue = this.roundThresholdValue(thresholdValue);
  }

  // necessary because ngModel of input uses decimal pipe so can't do [(ngModel)]
  onInputChange(event: any) {
    this.thresholdValue = +event.target.value;
  }

  onSliderThresholdChange(event: any) {
    this.rangeThreshold.max = this.roundThresholdValue(this.rangeThreshold.max);
  }

  ngOnChanges(changes) {
    // setup form if not already created.  ngOnChanges called before ngOnInit
    if (!this.form) {
      this.initForm();
    }
    this.form.valueChanges
      .pipe(
        distinctUntilChanged()
      )
      .subscribe((data) => {
        const reasonForChangeValidators = [
          Validators.required,
          Validators.maxLength(250),
        ];
        this.form.controls.reasonForChange.setValidators(reasonForChangeValidators);

        setTimeout(() => {
          this.form.controls.otherMitigationStrategy.setValidators([Validators.required]);
          if (
             (this.thresholdValue < this.rangeThreshold.min || this.thresholdValue > this.rangeThreshold.max)
            && this.name === 'KRI'
           ) {
             this.form.controls['thresholdValue'].setErrors({ 'incorrectValue': true });
             this.form.controls['thresholdValue'].markAsTouched();
           } else if (this.form.controls['thresholdValue'].invalid) {
             this.form.controls['thresholdValue'].setErrors(null);
           }
        }, 10);

      });

    // if threshold is changed
    if (changes.threshold && changes.threshold.currentValue) {
      this.onThresholdChange(changes.threshold.currentValue);
    }

    if (changes.isSavingThreshold) {
      const isSavingThreshold = changes.isSavingThreshold.currentValue;

      this.buttonActive = isSavingThreshold;

      if (isSavingThreshold) {
        this.form.disable();
      } else {
        this.form.enable();
      }
    }
  }

  onTresholdRangeChange() {
    // update validators of threshold
    const thresholdMinValidators = [
      Validators.required,
      Validators.min(0),
      Validators.max(this.rangeThreshold.max),
    ];
    // update validators of threshold
    const thresholdMaxValidators = [
      Validators.required,
      Validators.min(this.rangeThreshold.min),
    ];

    this.form.controls.thresholdRangeMin.setValidators(thresholdMinValidators);
    this.form.controls.thresholdRangeMax.setValidators(thresholdMaxValidators);
    setTimeout(() => {
      if((this.rangeThreshold.min > this.rangeThreshold.max) && this.name=== 'KRI') {
        this.form.controls['thresholdRangeMax'].setErrors({ 'incorrectValue': true });
        this.form.controls['thresholdRangeMax'].markAsTouched();
      }
    }, 10);
    this.form.controls.thresholdRangeMin.updateValueAndValidity();
    this.form.controls.thresholdRangeMax.updateValueAndValidity();
  }

  // when different threshold is selected and passed into component
  onThresholdChange(threshold) {
    this.threshold = this.formatThreshold(threshold);

    // Change Treshold Range values to correspondent sliderConfig defaults
    this.rangeThreshold.min = this.roundThresholdValue(this.threshold.thresholdMin || this.threshold.sliderConfig.min);
    this.rangeThreshold.max = this.roundThresholdValue(this.threshold.thresholdMax || this.threshold.sliderConfig.max);

    // update validators of threshold
    const thresholdValueValidators = [
      Validators.required,
      Validators.min(this.rangeThreshold.min),
      Validators.max(this.rangeThreshold.max),
    ];

    this.form.controls.thresholdValue.setValidators(thresholdValueValidators);
    this.form.updateValueAndValidity();
    this.resetFormValues();
  }

  formatThreshold(threshold) {
    let calcValueDataType = threshold.calcValueDataType.toLowerCase();
    calcValueDataType = (calcValueDataType && threshold.units !== '%') ? calcValueDataType : '';

    const validation = { ...calcDataConfig.validation.shared, ...calcDataConfig.validation[threshold.calcTypeId] };
    const sliderConfig = { ...calcDataConfig.sliderConfig.shared, ...calcDataConfig.sliderConfig[calcValueDataType] };

    return { ...threshold, calcValueDataType, validation, sliderConfig };
  }

  toggleGenerateAlert() {
    this.shouldGenerateAlert = !this.shouldGenerateAlert;
    this.onAlertCheckboxChanged(this.shouldGenerateAlert);
  }

  onAlertCheckboxChanged(shouldGenerateAlert: boolean) {
    // if should generate alert, need to update form to require alert start date field
    if (this.shouldGenerateAlert) {
      this.form.controls.alertStartDate.setValidators([Validators.required]);
      this.form.controls.alertStartDate.updateValueAndValidity();
    } else {
      this.form.controls.alertStartDate.clearValidators();
      this.form.controls.alertStartDate.updateValueAndValidity();
    }
  }

  onStrategyChanged() {
    // if "other" strategy selected, need to require free text field
    if (this.threshold.mitigationStrategy === 'other' && this.threshold.otherMitigationStrategy === null) {
      this.form.controls['otherMitigationStrategy'].setValidators([Validators.required]);
      this.form.controls.otherMitigationStrategy.updateValueAndValidity();
    } else {
      this.form.controls.otherMitigationStrategy.clearValidators();
      this.form.controls.otherMitigationStrategy.updateValueAndValidity();
    }
    // for reseting other value
    if ((this.form.controls['mitigationStrategy'].touched && this.threshold.mitigationStrategy === 'other')
    || this.form.controls['mitigationStrategy'].value === 'other') {
      this.form.controls['otherMitigationStrategy'].setValidators([Validators.required]);
      this.form.controls['otherMitigationStrategy'].reset();
    }
  }

  onFormSubmit() {
    const threshold = this.formatThresholdForDatabase(this.form.value);
    this.submitThreshold.emit(threshold);
  }

  formatThresholdForDatabase(threshold) {
    const { thresholdValue } = threshold;
    const { active, thresholdOperator, reasonForChange, weight, alertStartDate, mitigationStrategy, otherMitigationStrategy } = threshold;

    // if mitigation strategy is "other" use what user enters in free text
    const mitigationStrategyValue = mitigationStrategy;
    const otherStrategy = otherMitigationStrategy;

    return {
      calcTypeId: this.threshold.calcTypeId,
      // database has thresholdValue as string for now
      thresholdValue: this.roundThresholdValue(thresholdValue),
      thresholdMin: this.rangeThreshold.min,
      thresholdMax: this.rangeThreshold.max,
      thresholdOperator,
      ...(reasonForChange ? { thresholdNote: reasonForChange } : {}),
      ...(weight ? { calcWeight: weight } : {}),
      mitigationStrategy: mitigationStrategyValue,
      otherMitigationStrategy: otherStrategy,
      generateAlert: this.shouldGenerateAlert,
      active: this.type === 'Add' ? true : active,
      ...(alertStartDate ? { alertStartDate } : {}),
    };
  }

  resetFormValues() {
    const values = ['mitigationStrategy', 'thresholdOperator', 'weight',];

    values.forEach((value: any) => {
      this.form.controls[value].setValue(null);
      this.form.controls[value].markAsUntouched();
    });
    this.shouldGenerateAlert = false;
    this.onAlertCheckboxChanged(this.shouldGenerateAlert);
    this.thresholdValue = 0;
  }

  onClose() {
    this.dialogRef.close();
  }

  resetForm() {
    this.formElem.resetForm();
  }

}
