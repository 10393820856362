import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import * as d3 from 'd3';


@Component({
  selector: 'ert-sparkline',
  templateUrl: './sparkline.component.html',
  styleUrls: ['./sparkline.component.scss']
})
export class SparklineComponent implements OnInit {
  @ViewChild('sparkline', { static: true, read: ElementRef })
  private chartContainer: ElementRef;
  @Input() private data;
  @Input() private width;
  @Input() private height;
  firstDraw: boolean = true;
  margin = { top: 5, right: 5, bottom: 5, left: 10 };
  datasource = [];

  constructor() { }

  ngOnInit() {
    for(var i in this.data) {
      this.datasource[i] = this.formatData(this.data[i])
    }
    this.drawChart()
    this.firstDraw = false;
    window.addEventListener('resize', () => {
      if(this.datasource) {
        this.drawChart.bind(this);
      }
    });
  }

  formatData(data) {
    return {
      date: +(data[0]),
      score: +data[1]
    } 
  }

  drawChart() {
    const element = this.chartContainer.nativeElement;
    if (!this.firstDraw) {
      d3.select(element).selectAll('svg').remove();
    }
    var data = this.datasource;
    var areaColor = '#DBDBDB';
    var gradientURL = 'greyGradient'
    if(data.length > 0) {
      var first = data[0].score;
      var last = data[data.length-1].score;
      if(first < last) {
        areaColor = '#16A81B';
        gradientURL = 'greenGradient';
      } else if (first > last) {
        areaColor = '#D92A26';
        gradientURL = 'redGradient';
      }; 
    }
    
    // Setting the width and height of the chart
    const contentWidth = this.width - this.margin.left - this.margin.right;
    const contentHeight = this.height - this.margin.top - this.margin.bottom;

    // set the ranges
    var x = d3.scaleTime().range([0, contentWidth]);
    var y = d3.scaleLinear().range([contentHeight, 0]);

    // Defining the area
    var area = d3.area()
                  .x((d) => { return +x(d.date) ; })
                  .y0(contentHeight)
                  .y1((d) => { return +y(d.score); });

    // Defining the line
    var line = d3.line()
                  .x((d) => { return +x(d.date); })
                  .y((d) => { return +y(d.score); })
                  .curve(d3.curveMonotoneX);

    // Defining the domain 
    x.domain(d3.extent(data, (d) => { return (d.date); }));
    y.domain([0,100]);


    // Appending the SVG
    var svg = d3.select(element).append('svg')
      .attr('width', contentWidth)
      .attr('height', contentHeight);
    
    // Appending g(graphics) to the svg to draw the charts
    var g = svg.append('g').attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')')
            .attr('class', 'plot');

    var defs = svg.append('defs');

    var gradient = defs.append('linearGradient')
       .attr('id', gradientURL)
       .attr('x1', '10%')
       .attr('x2', '100%')
       .attr('y1', '10%')
       .attr('y2', '100%');
  
    gradient.append('stop')
       .attr('class', 'start')
       .attr('offset', '10%')
       .attr('stop-color', areaColor)
       .attr('stop-opacity', 1);

    gradient.append('stop')
       .attr('class', 'end')
       .attr('offset', '100%')
       .style('stop-color', '#F5F5F5')
       .attr('stop-opacity', 1);
    
    // Add the area
    g.append('path')
        .data([data])
        .attr('class', 'area')
        .attr('d', area)
        .style('opacity', 0.5)
        .style('fill', `url(#${gradientURL})`);

    // Appending the lines
      g.append('path')
        .datum(data)
        .attr('class', 'line')
        .attr('d', line)
        .style('fill', 'none')
        .style('stroke-width', 2)
        .style('stroke', '#003865');
        

      // Appending line for Score=50
      g.append('line')
      .attr("x1", 0)
      .attr("y1", y(50))
      .attr("x2", contentWidth)
      .attr("y2", y(50))
      .style("stroke-width", 2)
      .style("stroke", "#98999A")
      .style("fill", "none")
      .style('stroke-dasharray','4')
      .style("opacity", '0.5');
  }

}
