import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'thresholdValue'
})
export class ThresholdValuePipe implements PipeTransform {
  // necessary to fix 2.9 on front end being shown as 2.900000000000004 (javascript decimal handling)
  transform(value: number): number {
    // round to 2 decimal places
    return Math.round(value * 100) / 100;
  }
}
