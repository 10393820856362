<div class="h-stacked-bar-wrapper">
    <div class="h-stacked-bar-left">
        <div #hStackedBarChartLeft class="h-stacked-bar-content" id="hstackedbarchartleft"></div>
    </div>
    <div class="h-stacked-bar-right">
        <div #hStackedBarChartRight class="h-stacked-bar-right-wrapper" >
            <div class="h-stacked-bar-right-top">
                <div #hStackedBarChartTop class="h-stacked-bar-content-top" id="hstackedbarcharttop"></div>
            </div>
            <div class="h-stacked-bar-right-middle">
                <div #hStackedBarChartMiddle class="h-stacked-bar-content-middle" id="hstackedbarchartmiddle"></div>
                <div #hStackedBarChartMiddleTooltipWrapper id="hStackedBarChartMiddleTooltipWrapper"></div>
            </div>
            <div class="h-stacked-bar-right-bottom">
                <div #hStackedBarChartBottom class="h-stacked-bar-content-bottom" id="hstackedbarchartbottom"></div>
            </div>
        </div>
    </div>
</div>
