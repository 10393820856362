import { Component, OnInit, ViewChild, ElementRef, ViewEncapsulation, Input, AfterContentInit } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'horizontal-stacked-bar',
  templateUrl: './horizontal-stacked-bar.component.html',
  styleUrls: ['./horizontal-stacked-bar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HorizontalStackedBarComponent implements OnInit, AfterContentInit {

  @ViewChild('barChart', {static: true, read: ElementRef })
  private chartContainer: ElementRef;
  @Input() private data;
  @Input() private height: number;
  @Input() private width: number;
  @Input() studyStartDate: Date;
  @Input() keys;
  @Input() colorKeys;
  @Input() lastUpdatedDate: Date;
  @Input() milestoneTooltip: any;


  margin = { top: 10, right: 10, bottom: 10, left: 40 };
  padding = 30;
  firstDraw: boolean = true;


  constructor() { }

  ngOnInit() {
  }

  ngAfterContentInit() {
    this.drawChart()
    this.firstDraw = false;
    window.addEventListener('resize', () => {
      if(this.data) {
        this.drawChart.bind(this);
      }
    });
  }

  private drawChart() {
    // Load data and initialization
    const studyStartDate = this.studyStartDate;
    const element = this.chartContainer.nativeElement;
    var data = this.data;
    var tooltipHeading;

    // Redrawing for everytime the window is resized
    if (!this.firstDraw) {
      d3.select(element).selectAll('svg').remove();
    }

    // Setting width as per window size
    this.width = window.innerWidth - 200;

    // Setting the width and height of the chart
    const contentWidth = this.width - this.margin.left - this.margin.right;
    const contentHeight = this.height - this.margin.top - this.margin.bottom;

    // Setting the y scale(Ordinal Scale), range and padding
    var y = d3.scaleBand()
      .rangeRound([contentHeight, 0])
      .paddingInner(0.005)
      .align(0.1);

    // Setting the x scale(Time Scale), range
    var x = d3.scaleTime()
      .range([140, contentWidth]);

    // Setting the scale for color codes
    var z = d3.scaleOrdinal()
      .range(this.colorKeys);


    // Pushing all the dates on to one array
    var dateList = [];
    data.forEach(element => {
      this.keys.forEach(key => {
        dateList.push(element[key])
      })
    });
    dateList.push(studyStartDate);

    // Initializing the domains for x, y and z scales
    x.domain(d3.extent(dateList, function(d) { return (d); }));
    y.domain(data.map(d => (d.milestoneDateType)));
    z.domain(this.keys);

    // Appending the SVG Element to the main element
    var svg = d3.select(element).append('svg')
      .attr('width', 0)
      .attr('height', this.height);

    // Appending g(graphics) to the svg to draw the charts
    var g = svg.append("g").attr("transform", "translate(" + this.margin.left + "," + this.margin.top + ")")
            .attr('class', 'plot');
    g.append("g")
      .selectAll("g")
        .data(d3.stack().keys(this.keys)(data)) // Creating stack to build a stacked bar chart
        .enter().append("g")
        .attr("fill", (d) => z(d.key))
        .selectAll("rect")
          .data((d) => change(d))
            .attr('class', (d) => d.data.milestoneDateType)
            .enter().append("rect")
            .style('stroke-width', '1')
            .style('stroke-opacity','0.9')
            .style('stroke','#f4f4f4')
            .attr("y", (d) => y(d.data.milestoneDateType))
            .attr("x", (d) => x((d[0])))
            .attr("width", (d) => { return x((d[1])) - x((d[0])); } )
            .attr("height", y.bandwidth() - 10)
        .on("mouseover", () => { tooltip.style("display", null); })
        .on("mousemove", (d) => {
          tooltip.style('display', null)
          var name;
          Object.keys(d.data).forEach(element => {
            if(d.data[`${element}`] === d[1]) {
              name = element;
            }
            if(d.data.milestoneDateType === 'Target Date'){
              tooltipHeading = `Target Date(${name})  ${d3.timeFormat("%d-%b-%Y")(d[1])}`;
            } else if (d.data.milestoneDateType === 'Actual/Projected Date') {
              tooltipHeading = `${this.milestoneTooltip[name]} (${name}) ${d3.timeFormat("%d-%b-%Y")(d[1])}`;
            }
          });
          var xPosition = d3.mouse(d3.event.currentTarget)[0] - 5;
          var yPosition = d3.mouse(d3.event.currentTarget)[1] - 5;
          tooltip.attr("transform", "translate(" + xPosition + "," + yPosition + ")");
          tooltip.select("text").text(tooltipHeading)
          .attr('xml:space', 'preserve');
        })
        .on("mouseout", function() { tooltip.style("display", "none"); });

    // Appending and styling the tooltip to the g
    var tooltip = svg.append('g')
    .attr("class", "tooltip")
    .style("display", "none");

    tooltip.append("rect")
      .attr("width", 250)
      .attr("height", 20)
      .attr('rx', 10)
      .attr('ry', 10)
      .attr("fill", "rgb(196, 196, 196)")
      .style("opacity", 0.8);

    tooltip.append("text")
      .attr("x", 120)
      .attr("dy", "1.2em")
      .style("text-anchor", "middle")
      .attr("font-size", "12px")
      .attr("font-weight", "bold");

    // Appending the short milestone name to the relative rect for two y scales
    g.append("g")
      .selectAll("g")
      .data(d3.stack().keys(this.keys)(data))
      .enter().append("g")
      .append("text").text((d) => {return getText(d, 0)})
      .style('font-size', '1.2rem')
      .style('font-weight', '600')
      .style('font-family', '"Noto Sans", Helvetica Neue, Helvetica, Arial, sans-serif')
      .style('margin', '0 0 0.5rem')
      .style('fill','#f4f4f4')
      .attr('dx', '3')
      .attr("y", contentHeight-y( 'Target Date') - 25 )
      .attr("x", (d) => {change(d); return x(new Date(d[0][1])) - getTextLength(getText(d, 0))});
    g.append("g")
    .selectAll("g")
      .data(d3.stack().keys(this.keys)(data))
      .enter().append("g")
      .append("text").text(d => {return getText(d, 1)}) //{ return milestonesShortNames[d.key]})
      .style('font-size', '1.2rem')
      .style('font-weight', '600')
      .style('font-family', '"Noto Sans", Helvetica Neue, Helvetica, Arial, sans-serif')
      .style('margin', '0 0 0.5rem')
      .style('fill','#f4f4f4')
      .attr('dx', '3')
      .attr("y", contentHeight-y( 'Actual/Projected Date')-25)
      .attr("x",d => { change(d); return x(new Date(d[1][1])) - getTextLength(getText(d, 1)) });

    // Appending the text to Y scales
    svg.append("text").text('Actual/Projected Date')
    .style('font-size', '1.2rem')
    .style('font-weight', '600')
    .style('font-family', '"Noto Sans", Helvetica Neue, Helvetica, Arial, sans-serif')
    .style('margin', '0 0 0.5rem')
    .style('fill','#494a4b')
    .attr("y", contentHeight-y('Target Date') - 14 )
    .attr("x", x(studyStartDate)-98)

    svg.append("text").text('Target Date')
      .style('font-size', '1.2rem')
      .style('font-weight', '600')
      .style('font-family', '"Noto Sans", Helvetica Neue, Helvetica, Arial, sans-serif')
      .style('margin', '0 0 0.5rem')
      .attr('fill','#494a4b')
      .attr("y", y('Actual/Projected Date') -14 )
      .attr("x", x(studyStartDate)-35);

    // Appending the dashed line and text for Current Date marker
    var today = new Date();
    svg.append('line')
      .attr("x1", x(this.lastUpdatedDate))
      .attr("y1", -15)
      .attr("x2", x(this.lastUpdatedDate))
      .attr("y2", contentHeight + 5)
      .style("stroke-width", 2)
      .style("stroke", "#494a4b")
      .style("fill", "none")
      .style("opacity", '0.8');

    svg.append("text").text(`Last Updated (${d3.timeFormat("%d-%b-%Y")(this.lastUpdatedDate)})`)
      .style('font-size', '1.1rem')
      .style('font-weight', '600')
      .style('xml:space', 'preserve')
      .style('font-family', '"Noto Sans", Helvetica Neue, Helvetica, Arial, sans-serif')
      .style('margin', '0 0 0.5rem')
      .style('fill','#494a4b')
      .attr("y", 8 )
      .attr("x", x(this.lastUpdatedDate)+3);

    // Appending the dashed line and text for Start date marker
    svg.append('line')
      .attr("x1", x(studyStartDate) + 40)
      .attr("y1", 0)
      .attr("x2", x(studyStartDate) + 40)
      .attr("y2", contentHeight +15)
      .style("stroke-width", 2)
      .style("stroke", "#494a4b")
      .style("fill", "none")
      .style("opacity", '0.5');

    svg.append("text").text(`Start Date(${d3.timeFormat("%d-%b-%Y")(studyStartDate)})`)
      .style('font-size', '1.1rem')
      .style('font-weight', '600')
      .style('xml:space', 'preserve')
      .style('font-family', '"Noto Sans", Helvetica Neue, Helvetica, Arial, sans-serif')
      .style('margin', '0 0 0.5rem')
      .style('fill','#494a4b')
      .attr("y", contentHeight +15 )
      .attr("x", x(studyStartDate)+43);

    // Appending the dashed line and text for End date marker
    svg.append('line')
      .style('stroke-dasharray','4')
      .attr("x1", x(d3.max(dateList)) + 40)
      .attr("y1", 0)
      .attr("x2", x(d3.max(dateList)) + 40)
      .attr("y2", contentHeight +15)
      .style("stroke-width", 2)
      .style("stroke", "#494a4b")
      .style("fill", "none")
      .style("opacity", '0.5');

    svg.append("text").text(`End Date(${d3.timeFormat("%d-%b-%Y")(d3.max(dateList))})`)
      .style('font-size', '1.1rem')
      .style('font-weight', '600')
      .style('xml:space', 'preserve')
      .style('font-family', '"Noto Sans", Helvetica Neue, Helvetica, Arial, sans-serif')
      .style('margin', '0 0 0.5rem')
      .style('fill','#494a4b')
      .attr("y", contentHeight +15 )
      .attr("x", x(d3.max(dateList))-90);

    // SVG trasnsition to open gradually
    svg.transition()
      .attr('width', element.offsetWidth )
      .duration(3500);

    // Hiding text if rect width is not enough
    function getText(d, i) {
      d = change(d);
      const fontSize = 24;
      const pad = 2;
      const ellipsis = 2;
      const dl = Math.floor((x((d[i][1])) - x((d[i][0]))) / (fontSize / 2)) - pad - ellipsis;
      if (dl < 0) {
        return null;
      } else {
        return d.key;
      }
    }

    // Get the length of text
    function getTextLength(d) {
      var padding = 5;
      var ellipsis = 5;
      if(d) {
        var canvas = document.createElement('canvas');
        var context = canvas.getContext('2d');
        context.font = 24 + 'px ' + '"Noto Sans", Helvetica Neue, Helvetica, Arial, sans-serif';
        return context.measureText(d.trim()).width/2 + padding + ellipsis;
      } else
      return 0;
    }

    // Fixing the data to append stacked rect
    function change(data) {
      if(data.key === 'FPFV') {
        data[0][0] = studyStartDate;
        data[0][1] = dateList[0];
        data[1][0] = studyStartDate;
        data[1][1] = dateList[7];
      }
      if(data.key === 'FPI') {
        data[0][0] = dateList[0];
        data[0][1] = dateList[1];
        data[1][0] = dateList[7];
        data[1][1] = dateList[8];
      }
      if(data.key === 'LPI') {
        data[0][0] = dateList[1];
        data[0][1] = dateList[2];
        data[1][0] = dateList[8];
        data[1][1] = dateList[9];
      }
      if(data.key === 'LPLV') {
        data[0][0] = dateList[2];
        data[0][1] = dateList[3];
        data[1][0] = dateList[9];
        data[1][1] = dateList[10];
      }
      if(data.key === 'LPO') {
        data[0][0] = dateList[3];
        data[0][1] = dateList[4];
        data[1][0] = dateList[10];
        data[1][1] = dateList[11];
      }
      if(data.key === 'DB Lock') {
        data[0][0] = dateList[4];
        data[0][1] = dateList[5];
        data[1][0] = dateList[11];
        data[1][1] = dateList[12];
      }
      if(data.key === 'eTMF') {
        data[0][0] = dateList[5];
        data[0][1] = dateList[6];
        data[1][0] = dateList[12];
        data[1][1] = dateList[13];
      }
      return data;
    }
  }
}
