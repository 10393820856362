<div (click)="dropdownOpen = !dropdownOpen" class="es-dropdown" [ngClass]="{'-es-open' : dropdownOpen === true}"
  data-es-dropdown="">
  <button type="button" class="es-dropdown-icon">
    <span class="es-icon {{icon}}"></span>
    <label class="es-label-stack">
      <span>{{title}}</span>
    </label>
  </button>
  <nav>
    <ul>
        <li>
            <a>Test Item</a>
          </li>
      <ng-container *ngFor="let link of links">
        <li>
          <a [routerLink]="link.location">{{link.text}}</a>
        </li>
        <li *ngIf="link.spacerAbove" class="es-divider"></li>
      </ng-container>
    </ul>
  </nav>
</div>
