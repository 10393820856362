import { UtilsService } from './../../services/utils.service';
import { Component, OnInit, OnChanges, ViewChild, ElementRef, Input, ViewEncapsulation, OnDestroy } from '@angular/core';
import * as d3 from 'd3';
import {PointFilter} from './pt.filter';

@Component({
  selector: 'ert-sb-trendline',
  templateUrl: './sb-trendline.component.html',
  styleUrls: ['./sb-trendline.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SbTrendlineComponent implements OnInit, OnDestroy {
  @ViewChild('sbtrendline', {static: true}) private chartContainer: ElementRef;
  @Input() private data: any;
  @Input() private height: number;
  @Input() private minWidth: number;
  @Input() private maxDotRadius: number;
  @Input() private maxPoints: number;

  private margin: any = { top: 1, bottom: 1, left: 1, right: 1};
  private chart: any;
  private firstDraw: boolean;
  private tip: any;
  private lineWidth = 1;

  constructor() {

  }

  ngOnInit() {
    if (this.data) {
      // reduce to maxpoints
      if (! this.maxPoints) {
        this.maxPoints = 6;
      }
      if (this.data.length > this.maxPoints) {
        // map to date value
        const dv = this.data.map(e => [e[0], e[1]]);
        const flt = new PointFilter(dv, this.maxPoints, 0);
        this.data = flt.ravg(.632);
      }
      this.tip = d3.select('body').append('div')
        .attr('class', 'chartToolTip')
        .style('opacity', 0);
      if (! this.maxDotRadius) {
        this.maxDotRadius = 7;
      }
      if (! this.minWidth) {
        this.minWidth = 120;
      }
      this.drawChart();
      this.firstDraw = false;
      window.addEventListener('resize', this.drawChart.bind(this));
    }
  }

  min(x, y) {
    return x < y ? x : y;
  }

  max(x, y) {
    return x > y ? x : y;
  }

  drawChart() {
    const element = this.chartContainer.nativeElement;
    const width = this.max(this.minWidth, element.offsetWidth - this.margin.left - this.margin.right);
    const height = this.height - this.margin.top - this.margin.bottom;
    const dotRadius = this.min(this.maxDotRadius, Math.floor((width / this.data.length) / 3));
    const pad = 2 * dotRadius;
    // if (width > 80) {
    //   dotRadius = dotRadius / 3;
    // }
    // for making line chart width consistent with balls radius
    // if (width > 170) {
    //   width = 170;
    // }
    if (!this.firstDraw) {
      d3.select(element).selectAll('svg').remove();
    }
    const xS = d3.scaleTime().range([pad, width - pad]);
    const yS = d3.scaleLinear().range([height - pad, pad]);

    xS.domain(d3.extent(this.data, d => d[0]));
    // yS.domain(d3.extent(this.data, d => d[1]));
    yS.domain([0, 100]);
    const line = d3.line()
      .x(d => xS(d[0]))
      .y(d => yS(d[1]))
      .curve(d3.curveLinear);

    const svg = d3.select(element).append('svg')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .style('style', 'chartSvg')
      .attr('transform', `translate(${this.margin.left}, ${this.margin.top})`);
    /*
    const rectangle = svg.append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', width)
      .attr('height', height)
      .style('opacity', 0.5)
      .style('fill', '#ededed');
    */

    const path = svg.append('path')
      .datum(this.data)
      .attr('fill', 'none')
      .attr('stroke', 'black')
      .attr('stroke-width', 0)
      .attr('d', line)
      .transition()
      .delay((d, i) => i * 100)
      .attr('stroke-width', this.lineWidth);

    svg.selectAll('dot')
      .data(this.data)
      .enter().append('circle')
      .attr('fill', d => UtilsService.getColorFromScore(d[1]))
      .attr('stroke', 'black')
      .attr('stroke-width', 1)
      .attr('r', 0)
      .attr('cx', d => xS(d[0]))
      .attr('cy', d => yS(d[1]))

      .on('mouseover', d => this.tooltipShow(d))
      .on('mouseout', d => this.tooltipOff())
      .on('click', d => this.tooltipOff())

      .transition()
      .delay((d, i) => i * 5)
      .attr('r', dotRadius);
  }

  tooltipShow(d) {
    this.tip.transition()
      .duration(300)
      .style('opacity', .9);
    this.tip
      .html(() => {
        const d0 = d3.timeFormat('%d-%b-%Y')(d[0]);
        return `<table class="toolTip">
        <tr align="left">
        <td class="toolTip" colspan="2">Date:</td>
        <td class="toolTip" colspan="2">${d0}</td>
        </tr>
        <tr align="left">
        <td class="toolTip" colspan="2">Score:</td>
        <td class="toolTip" colspan="2">${d[1]}</td>
        </tr>
        </table>`;
      })
      .style('display', 'inline-block')
      .style('left', (d3.event.pageX - 80) + 'px')
      .style('top', (d3.event.pageY - 50) + 'px');
    }

  tooltipOff() {
    this.tip.transition()
      .duration(300)
      .style('opacity', 0);
  }

  ngOnDestroy() {
    this.tooltipOff();
  }

}
