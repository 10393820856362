import {
  Directive,
  ElementRef,
  HostListener,
  Input
} from '@angular/core';

@Directive({
  selector: '[MultiSelectClose]'
})
export class MouseleaveCloseDirective {
  @Input('MultiSelectClose') multiselect;

  constructor(
    private _elementRef: ElementRef
  ) {
  }

  @HostListener('mouseenter')
  public onMouseLeave() {
    this.multiselect.openedChange.subscribe(opened => {
      if (opened) {
        this.multiselect.panel.nativeElement.addEventListener('mouseleave', () => {
          this.multiselect.close();
        });
      }
    });
  }
}
