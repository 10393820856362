<h2 class="generate-task__header">Generate Task</h2>
<table class="alert-table">
  <tr>
    <td><b>Source Alert:</b></td>
    <td class="item">{{data.alertTitle}}</td>
  </tr>
  <tr>
    <td><b>Created:</b></td>
    <td class="item">{{data.createdDate | displayDate}}</td>
  </tr>
  <tr>
    <td colspan="2"><b>Description:</b></td>
  </tr>
  <tr>
    <td colspan="2" class="desc">
      {{data.alertMessage}}
    </td>
  </tr>
</table>

<form class="example-form" [formGroup]="createTaskForm" (ngSubmit)="onFormSubmit(createTaskForm)">
  <div>
    <mat-form-field class="example-full-width">
      <input type="text" placeholder="Select User" aria-label="User" matInput [formControl]="forUser" [matAutocomplete]="auto">
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
        <mat-option *ngFor="let user of filteredUsers | async" [value]="user">
          {{user.fullName}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>

  <div>
    <mat-form-field class="example-full-width">
      <mat-select placeholder="Impact" formControlName="impactLevel">
        <mat-option *ngFor="let impact of impacts" [value]="impact.value">
          {{ impact.viewValue }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div>
    <mat-form-field class="example-full-width">
      <input matInput [matDatepicker]="picker"  [min]="today" placeholder="Choose a Due Date" (click)="picker.open()" formControlName="dueDate">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker  #picker></mat-datepicker>
    </mat-form-field>
  </div>

  <div>
    <mat-form-field class="example-full-width">
      <input matInput placeholder="Task Title" value="" formControlName="taskTitle" onkeyup="this.value = this.value[0].toUpperCase() + this.value.substr(1)">
    </mat-form-field>
  </div>

  <div>
    <mat-form-field class="example-full-width">
      <textarea matInput placeholder="Task Description" formControlName="taskDescription" cols="45" onkeyup="this.value = this.value[0].toUpperCase() + this.value.substr(1)"></textarea>
    </mat-form-field>
  </div>
<button class="es-button -es-primary" type="submit" [disabled]="!createTaskForm.valid">Create Task</button>
</form>
