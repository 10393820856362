import {Pipe, PipeTransform} from '@angular/core';

type caseType = 'camelCase' | 'stringTitleCase';

@Pipe({
  name: 'caseConverter'
})
export class CaseConverterPipe implements PipeTransform {

  transform(value: any, caseName: caseType = 'stringTitleCase', ...args: any[]): any {

    switch (caseName) {
      case 'camelCase':
        return value.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());

      case 'stringTitleCase':
        return value
          .replace(/([A-Z])/g, (match) => ` ${match}`)
          .replace(/^./, (match) => match.toUpperCase())
          .trim();

    }

  }

}

