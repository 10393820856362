<mat-drawer-container autosize>
  <mat-drawer class="sidenav" #drawer opened mode="side">
    <mat-list class="full-width vert-align" role="list">
      <div>
        <button color="primary" mat-button [routerLink]="['/dashboard/alerts']">
          <mat-icon aria-label="Alerts" svgIcon="alerts"></mat-icon>Alerts
        </button>
      </div>
      <div>
        <button mat-button [routerLink]="['/dashboard/tasks']">
          <mat-icon aria-label="Tasks" svgIcon="tasks"></mat-icon>Tasks
        </button>
      </div>
    </mat-list>
  </mat-drawer>

  <mat-card class="dashboard__card" *ngIf="!selectedDetail">
    <div>
      <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
      </mat-form-field>
      <mat-paginator #paginator [pageSize]="20" [pageSizeOptions]="[10, 20, 50, 100]" class="paginator-alerts">
      </mat-paginator>
    </div>

    <mat-table #table [dataSource]="displayedData" class="ert-table" matSort>

      <ng-container matColumnDef="createdDate">
        <mat-header-cell style="flex: 0 0 10%" *matHeaderCellDef mat-sort-header> Created</mat-header-cell>
        <mat-cell style="flex: 0 0 10%" *matCellDef="let alerts" [class.newAlert]="!alerts.read"> {{alerts.createdDate
          | displayDate}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="studyProtocolNumber">
          <mat-header-cell style="flex: 0 0 8%" *matHeaderCellDef mat-sort-header> Protocol #</mat-header-cell>
          <mat-cell style="flex: 0 0 8%" *matCellDef="let alerts" [class.newAlert]="!alerts.read"> {{alerts.studyProtocolNumber}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="siteNo">
          <mat-header-cell style="flex: 0 0 5%" *matHeaderCellDef mat-sort-header> Site #</mat-header-cell>
          <mat-cell style="flex: 0 0 5%" *matCellDef="let alerts" [class.newAlert]="!alerts.read"> {{alerts.siteNo}} </mat-cell>
        </ng-container>

      <ng-container matColumnDef="studyAlertLogId">
        <mat-header-cell style="flex: 0 0 10%" *matHeaderCellDef mat-sort-header> Alert ID</mat-header-cell>
        <mat-cell style="flex: 0 0 10%" *matCellDef="let alerts" [class.newAlert]="!alerts.read">
          {{alerts.studyAlertLogId}} </mat-cell>
      </ng-container>


      <ng-container matColumnDef="alertTitle">
        <mat-header-cell style="flex: 0 0 30%" *matHeaderCellDef mat-sort-header> Title
        </mat-header-cell>
        <mat-cell style="flex: 0 0 30%" *matCellDef="let alerts" [class.newAlert]="!alerts.read"> {{alerts.alertTitle}}
        </mat-cell>

      </ng-container>

      <ng-container matColumnDef="alertMessage">
        <mat-header-cell style="flex: 0 0 30%" *matHeaderCellDef mat-sort-header> Description
        </mat-header-cell>
        <mat-cell style="flex: 0 0 30%" *matCellDef="let alerts" [class.newAlert]="!alerts.read">
          {{alerts.alertMessage}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell style="flex: 0 0 10%" *matHeaderCellDef mat-sort-header> Status
        </mat-header-cell>
        <mat-cell style="flex: 0 0 10%" *matCellDef="let alerts" [class.newAlert]="!alerts.read"> {{alerts.status}}
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row [routerLink]="['detail', row.studyAlertLogId]" *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>

  </mat-card>

</mat-drawer-container>
