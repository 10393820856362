<div>
<div class="dialog-title">
  <h4>Select a sheet from this dashboard</h4>
  <span class="es-icon es-icon-close" (click)="closeDownloadDialog('close')"></span>
</div>
  <table>
    <tr>
      <button (click)="closeDownloadDialog('eventsByStudy')">Events Aging by Study</button>
    </tr>
    <tr>
      <button (click)="closeDownloadDialog('scoreTrend')">Score Trend - Portfolio</button>
    </tr>
    <tr>
      <button (click)="closeDownloadDialog('studiesByChange')">Studies by % of Change - Tooltip</button>
    </tr>
    <tr>
      <button (click)="closeDownloadDialog('studiesByScore')">Studies by Score</button>
    </tr>
    <tr>
      <button (click)="closeDownloadDialog('studiesByChangePieCharts')">Studies by Score % Change Pie Charts</button>
    </tr>
    <tr>
      <button (click)="closeDownloadDialog('studyListing')">Study Listing</button>
    </tr>
  </table>
</div>