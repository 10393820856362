import { Validators, AbstractControl, ValidationErrors } from '@angular/forms';

export interface ValidationsType {
  name: string;
  validator: (control: AbstractControl) => ValidationErrors | null;
  message?: string;
}
export interface FormOptions {
  submitBtnText?: string;
  cancelBtn?: boolean;
  formAutoComplete?: boolean;
  showInlineErrors?: boolean;
  subheading?: string;
  preview?: boolean;
  hideAllBtn?: boolean;
}

export interface FormDataType {
  fields: FieldBaseType[];
  options: FormOptions;
}

export const FormOptionsDefault = {
  submitBtnText: 'Submit',
  cancelBtn: false,
  formAutoComplete: false,
  showInlineErrors: true,
};

export interface FieldBaseType {
  value?: string;
  name: string;
  label: string;
  controlType?: ControlFieldTypes;
  required?: boolean;
  order?: number;
  fieldType?: InputFieldTypes;
  disabled?: boolean;
  checked?: boolean;
  rows?: number;
  hasSlider?: boolean;
  classes?: string;
  // placeholder?: string,
  ngModel?: any;
  events?: any;
  autoComplete?: boolean;
  conditionToShow?: any;
  validations?: ValidationsType[];
  fieldGroupName?: string;
  optionalText?: string;
  helperText?: string;
}

export interface FieldBaseDateType extends FieldBaseType {
  minDate?: Date;
  maxDate?: Date;
}
export interface FieldBaseSelectType extends FieldBaseType {
  optionsList?: { key: string, value: string }[];
}

export interface FieldGroupBaseType extends FieldBaseType {
  fieldGroupName?: string;
  fieldsList: FieldBaseType[];
}

export interface FieldSlideBaseType extends FieldBaseType {
  status: boolean;
  enableText?: string;
  disableText?: string;
}

export class FieldsBase <T> implements FieldBaseType, FieldBaseDateType, FieldBaseSelectType, FieldSlideBaseType {
  value: string;
  status: boolean;
  name: string;
  label: string;
  // placeholder: string
  required: boolean;
  order: number;
  fieldType: InputFieldTypes;
  controlType: ControlFieldTypes;
  disabled: boolean;
  checked: boolean;
  rows?: number;
  hasSlider: boolean;
  classes: string;
  ngModel: any;
  events: any;
  autoComplete: boolean;
  conditionToShow: any;
  validations: ValidationsType[]; // array of the validations for the field
  optionalText: string;
  helperText: string;

  constructor(options: FieldBaseType) {
    this.value = options.value;
    this.name = options.name.replace(/ /g, ''); // Remove white spaces
    this.label = options.label;
    this.required = !!options.required;
    this.order = options.order === undefined ? 1 : options.order;
    this.fieldType = options.fieldType || 'text';
    this.controlType = options.controlType || 'input';
    this.disabled = options.disabled || false;
    this.checked = options.checked || false;
    this.rows = options.rows || 1;
    this.hasSlider = options.hasSlider || false;
    this.classes = options.classes || '';
    this.ngModel = options.ngModel || '';
    this.events = options.events || {};
    this.autoComplete = options.autoComplete || false;
    this.conditionToShow = options.conditionToShow || true;
    // this.placeholder = options.placeholder || '';
    this.validations = options.validations || [];
    this.optionalText = options.optionalText || '';
    this.helperText = options.helperText || '';
  }

  getValidators() {
    const fieldValidators: Validators[] = this.validations.map((val) => val.validator);
    if (this.required) fieldValidators.push(Validators.required);
    return fieldValidators;
  }
}

/** FormGroop: contains multiple fields, used also for fieldsets **/
export class FieldBaseGroup extends FieldsBase<FieldBaseType> implements FieldGroupBaseType {
  fieldGroupName: string;
  fieldsList: FieldBaseType[];

  constructor(options: FieldGroupBaseType) {
    super(options);

    this.fieldGroupName = options.fieldGroupName;
    this.fieldsList = options.fieldsList;
  }
}

type ControlFieldTypes =
  | 'input'
  | 'textarea'
  | 'slider'
  | 'select'
  | 'datePicker'
  | 'checkbox'
  | 'radio'
  | 'textarea'
  | 'slide-toggle'
  | 'button'
  | 'fieldSet'
  | 'formGroup'
  | 'icon';

type InputFieldTypes =
  | 'hidden'
  | 'text'
  | 'number'
  | 'password'
  | 'tel'
  | 'date'
  | 'button'
  | 'email';

/** Mat Slider Field */
export class FieldSlider extends FieldsBase<FieldBaseType> {
  controlType: ControlFieldTypes = 'slider'; // not a native type
  min: number;
  max: number;
  units?: string;
  step: number;
  thumbLabel: boolean;
  displayValue: string;

  constructor(options) {
    super(options);

    this.min = options['min'] || 0;
    this.max = options['max'] || 1;
    this.step = options['step'] || .1;
    this.units = options['units'];
    this.thumbLabel = options['thumbLabel'] || false;
    this.displayValue = options['displayValue'] || '';
  }
}

/** Mat select field **/
export class FieldSelect extends FieldsBase<FieldBaseSelectType> {
  controlType: ControlFieldTypes = 'select';  // not a native type
  optionsList: { key: string, value: string }[] = [];

  constructor(options: FieldBaseSelectType) {
    super(options);

    this.optionsList = options['optionsList'] || [];
  }
}

/** Mat Date picker field **/
export class FieldDate extends FieldsBase<FieldBaseDateType> {
  controlType: ControlFieldTypes = 'datePicker';
  minDate?: Date;
  maxDate?: Date;

  constructor(options: FieldBaseDateType) {
    super(options);
    this.minDate = options['minDate'];
    this.maxDate = options['maxDate'];
  }
}

/** Mat Checkbox field **/
export class FieldCheckbox extends FieldsBase<FieldBaseType> {
  controlType: ControlFieldTypes = 'checkbox'; // not a native type
  checked: boolean;

  constructor(options: FieldBaseType) {
    super(options);
    this.checked = options['checked'] || false;
  }
}

/** textarea field **/
export class FieldTextArea extends FieldsBase<FieldBaseType> {
  controlType: ControlFieldTypes = 'textarea';  // not a native type
  rows?: number;

  constructor(options: FieldBaseType) {
    super(options);
    this.rows = options['rows'] || 1;
  }
}

/** Input form **/
export class FieldInput extends FieldsBase<FieldBaseType> {
  controlType: ControlFieldTypes = 'input';

  constructor(options: FieldBaseType) {
    super(options);
  }
}

/** Radio form **/
export class FieldRadio extends FieldsBase<FieldBaseSelectType> {
  controlType: ControlFieldTypes = 'radio';
  optionsList: { key: string, value: string }[] = [];

  constructor(options: FieldBaseSelectType) {
    super(options);
    this.optionsList = options['optionsList'] || [];
  }
}

/** Button form **/
export class FieldButton extends FieldsBase<FieldBaseType> {
  controlType: ControlFieldTypes = 'button';

  constructor(options: FieldBaseType) {
    super(options);
  }
}

/** Slide Toggle field **/
export class FieldSlideToggle extends FieldsBase<FieldSlideBaseType> {
  controlType: ControlFieldTypes = 'slide-toggle';
  enableText: string;
  disableText: string;
  status: boolean;

  constructor(options: FieldSlideBaseType) {
    super(options);
    this.status = options['status'];
    this.enableText = options['enableText'];
    this.disableText = options['disableText'];
  }
}

/** FieldSet field (contains an array of fields with group label) **/
export class FieldSetFields extends FieldBaseGroup {
  controlType: ControlFieldTypes = 'fieldSet';

  constructor(options: FieldGroupBaseType) {
    super(options);

    this.label = options['label'];
    this.fieldsList = options['fieldsList'];
    this.fieldGroupName = options['fieldGroupName'];
  }
}

/** FieldSet field (contains an array of fields with group label) **/
export class FieldFormGroupFields extends FieldBaseGroup {
  controlType: ControlFieldTypes = 'formGroup';

  constructor(options: FieldGroupBaseType) {
    super(options);

    this.label = options['label'];
    this.fieldsList = options['fieldsList'];
    this.fieldGroupName = options['fieldGroupName'];
  }
}
export class FieldIcon extends FieldsBase<FieldBaseSelectType> {
  controlType: ControlFieldTypes = 'icon';
  height: string;
  width: string;
  color: string;
  solidFill: boolean;
  iconDisplay: string;

  constructor(options) {
    super(options);

    this.height = options['height'] || '8px';
    this.width = options['width'] || '5px';
    this.color = options['color'] || '';
    this.solidFill = options['solidFill'] || false;    //default solid box fill is false
    this.iconDisplay = options['iconDisplay'] || 'rectangle'; //icon to be displayed. Default is a box
  }
}
