import { UserService } from './../../../shared/services/user.service';
import { DataService } from './../../../shared/services/data.service';
import { EndpointService } from '@app/shared/services';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ActionAppSetAlerts, ActionAppSetTasks } from '@app/core/store/app.actions';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
// import 'rxjs/add/operator/map';

interface PickUser {
  id: number;
  fullName: string;
  email: string;
}

@Component({
  selector: 'ert-generate-task',
  templateUrl: './generate-task.component.html',
  styleUrls: ['./generate-task.component.scss']
})
export class GenerateTaskComponent implements OnInit {

  createTaskForm: UntypedFormGroup;

  impacts = [
    { value: '1', viewValue: 'High' },
    { value: '2', viewValue: 'Medium' },
    { value: '3', viewValue: 'Low' }
  ];

  users: any[] = [];
  forUser: any;
  filteredUsers: Observable<PickUser[]>;
  date =  new Date();
  today;


  constructor(
    private endpointService: EndpointService,
    private dataService: DataService<any>,
    public dialogRef: MatDialogRef<GenerateTaskComponent>,
    public userService: UserService,
    public store: Store<any>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.today =  new Date(Date.UTC(this.date.getUTCFullYear(), this.date.getUTCMonth(), this.date.getUTCDate(),
    this.date.getUTCHours(), this.date.getUTCMinutes(), this.date.getUTCSeconds()));
   }

  private autocompleteSelectionValidator(control: UntypedFormControl) {
    const selection = control.value;
    if (typeof selection === 'string') {
      return { incorrect: {} };
    }
    return null;
  }

  ngOnInit() {
    this.createTaskForm = new UntypedFormGroup({
      taskTitle: new UntypedFormControl('', Validators.required),
      taskDescription: new UntypedFormControl('', Validators.required),
      forUser: new UntypedFormControl('', this.autocompleteSelectionValidator),
      impactLevel: new UntypedFormControl('', Validators.required),
      dueDate: new UntypedFormControl('', Validators.required),
    });
    this.forUser = this.createTaskForm.controls['forUser'];
    this.userService.getUsers().pipe(map(users => {
      this.users = users.map(u => {
        return {
          id: u.userId,
          fullName: u.fullName,
          email: u.email
        };
      });
      this.filteredUsers = this.forUser.valueChanges.pipe(
        startWith(''),
        map(value => this.userFlt(value)));
    })).subscribe(users => { });
  }

  private userFlt(val: any) {
    if ((typeof (val) === 'string') && (val.length > 0)) {
      return this.users.filter(u => {
        const str = u.fullName + u.email;
        return str.toLowerCase().includes(val.toLowerCase());
      });
    } else return [];
  }

  displayFn(user?: PickUser): string | undefined {
    return user ? user.fullName : undefined;
  }

  onFormSubmit(createTaskForm) {
    if (this.createTaskForm.valid) {
      const task = {
        srcStudyId: this.data.srcStudyId,
        srcSiteId: this.data.srcSiteId,
        studyAlertLogId: this.data.studyAlertLogId,
        dueDate: this.createTaskForm.value.dueDate,
        title: this.createTaskForm.value.taskTitle,
        description: this.createTaskForm.value.taskDescription,
        impact: this.createTaskForm.value.impactLevel,
        assignTo: this.createTaskForm.value.forUser.id,
        srcUrl: location.origin
      };
      const endpoint = this.endpointService.getEndpoint('task');
      this.dataService.post(endpoint, task).subscribe(response => {
        const taskEndpoint = this.endpointService.getEndpoint('tasks');
        this.dataService.getAll(taskEndpoint).subscribe((tasks: any) => {
          const count = tasks.result.filter(items => items.taskOrigin === 'assigned').length;
          this.store.dispatch(new ActionAppSetTasks(count));
        });
        this.dialogRef.close();
      }, (err) => {
        if (err.status === 401 || err.status === 403) { this.dialogRef.close(); throw err; }
      });
    }
  }
}
