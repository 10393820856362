import { Store } from '@ngrx/store';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Component, OnInit, ViewChild, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { StudyService } from '@shared/services/study.service';
import { Study } from '@shared/models/study.model';
import { UpdateToolbar } from '@app/core/store/toolbar/toolbar.actions';
import { SetBreadCrumbs } from '@app/core/store/breadcrumbs/breadcrumbs.actions';
import { MatDialog } from '@angular/material/dialog';
import { DialogAddStudyComponent } from './dialog-add-study/dialog-add-study.component';
import { AuthService } from '@core/services/auth.service';
import { catchError, takeUntil } from 'rxjs/operators';
import { forkJoin, of, Subject } from 'rxjs';
import { ToAdminService } from '@app/to-admin/to-admin.service';
import { ErrorNotificationService } from '@core/services/error-notification.service';
import { ToastrService } from 'ngx-toastr';
import { OrganizationService } from '../organization.service';
import { DisplayDatePipe } from '@app/shared/pipes/display-date.pipe';
import { GlobalHeaderService } from '@app/shared/services/global-header.service';
import { BreadcrumbConfigService } from '@app/shared/services/breadcrumb-config.service';
import { PermissionService } from '@shared/services/permisssion.service';
import { LatestRefreshDialog } from '@shared/modals/latest-refresh-dialog/latest-refresh-component-dialog';
import { SessionStorageService } from '@app/core/services/session-storage.service';
import dateFormat from 'dateformat';
import { DialogCalcResetWarningComponent } from './dialog-calc-reset-warning/dialog-calc-reset-warning.component';


@Component({
  selector: 'ert-studies',
  templateUrl: './studies.component.html',
  styleUrls: ['./studies.component.scss']
})
export class StudiesComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  dataSource;
  organizationInfo: any;
  hasStudies: boolean;
  studyList;
  studyName;
  dateformat = dateFormat;
  displayedColumns = [];
  rbm_auth;

  allowERTAdminAndClientAdmin = false;
  allowERTAdmin = false;
  isAdmin = false;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private studyService: StudyService,
    private route: ActivatedRoute,
    private router: Router,
    private displayDatePipe: DisplayDatePipe,
    private store: Store<any>,
    public dialog: MatDialog,
    private authService: AuthService,
    private toastr: ToastrService,
    private toAdminService: ToAdminService,
    private organizationService: OrganizationService,
    private errorNotificationService: ErrorNotificationService,
    private globalHeaderService: GlobalHeaderService,
    private brdCrmbConfig: BreadcrumbConfigService,
    private permService: PermissionService,
    private sessionStorageService: SessionStorageService
  ) {
    this.globalHeaderService.getHeader().then((header) => {
      header.addBreadcrumbs([
        this.brdCrmbConfig.getHomeBrdCrmb(),
        this.brdCrmbConfig.getOrgSettingBrdCrmb()
      ]);
      header.subscribe('logout', this.globalHeaderService.logoutHandler());
      header.subscribe('session-expired', this.globalHeaderService.sessionExpHandler());
      this.isAdmin = this.permService.hasPermission("toAdminLevel");
    });
    this.rbm_auth = sessionStorageService.getItem('RBM-AUTH');
    if(this.rbm_auth) {
      var adminLevel = this.rbm_auth.userAccess.toAdminLevel;
      var isErtDemo = this.rbm_auth.isErtDemo;
      if(adminLevel == 1 || isErtDemo){
        this.displayedColumns = [
          'studyName',
          'protocolNumber',
          // 'sponsorName',
          // 'phase',
          // 'therapeuticArea',
          'dataInsightsStatus',
          'dateEnabled',
          'studyStatus',
          'closedDate',
          'source',
          'loadDate'
        ];
      }else if(adminLevel == 2){
        this.displayedColumns = [
          'studyName',
          'protocolNumber',
          'dataInsightsStatus',
          'dateEnabled',
          'studyStatus',
          'closedDate',
          'source',
          'refresh',
          'loadDate'
        ];
      }
    }
  }

  ngOnInit() {
    if (this.rbm_auth.clientId) {
      this.studyService.getStudiesForAdmin().subscribe((studies) => this.setupStudiesTable(studies));
    }
    this.store.select('customerState')
      .pipe(takeUntil(this.unsubscribe$), catchError(error => of(`Bad Promise: ${error}`)))
      .subscribe(({ customers, isCustomerSelected }) => {
        this.organizationInfo = this.organizationService.customerDIStatus(customers);
        this.allowERTAdminAndClientAdmin = ((!this.organizationInfo.toBeEnabled && this.permService.hasPermission("ErtAdmin"))
                              ||(!this.organizationInfo.toBeEnabled && this.permService.hasPermission("clientAdmin")) || this.permService.hasPermission("ertDemoAccess"));  
        this.allowERTAdmin = (!this.organizationInfo.toBeEnabled && this.permService.hasPermission("ErtAdmin"));  
      });
  }

  setupStudiesTable(studies) {
    this.hasStudies = studies.length > 0;
    this.studyList = studies;
    this.studyList.forEach((study) => {
      study.dateEnabledUI = this.displayDatePipe.transform(study.dateEnabled);
      study.dataInsightsStatusUI = study.dataInsightsStatus ? 'Enabled' : 'Disabled';
    });
    this.dataSource = new MatTableDataSource(this.studyList);
    setTimeout(() => {
      if (this.dataSource) {
        this.dataSource.paginator = this.paginator;
        this.dataSource.filterPredicate = (study, filter) => {
          const lowerFilter = filter.trim().toLowerCase();
          return this.checkField(study.studyName, lowerFilter)
            || this.checkField(study.protocolNumber, lowerFilter)
            || this.checkField(study.dataInsightsStatusUI, lowerFilter)
            || this.checkField(study.dateEnabledUI, lowerFilter);
        };
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = (
          datas: any,
          header: string
        ): any => {
          if (typeof (datas[header]) === 'number') {
            return parseInt(datas[header], 10);
          }
          if (typeof datas[header] === 'string') {
            return datas[header].trim().toLocaleLowerCase();
          }
          else {
            return datas[header];
          }
        };
      }
    });
  }

  checkField(item, lowerFilter) {
    if (item) {
      if (typeof (item) === 'string') {
        return item.trim().toLowerCase().includes(lowerFilter);
      } else {
        return item.toString().trim().toLowerCase().includes(lowerFilter);
      }
    }
  }

  get hasNoStudies() {
    return (this.organizationInfo.toBeEnabled || this.hasStudies === false);
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogAddStudyComponent, {
      width: '250px',
      data: this.studyList
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        if(this.studyList == null) {
           this.studyList = [];
        }
        this.studyList.push(result);
        this.dataSource = new MatTableDataSource(this.studyList);
        this.hasStudies = true;
      }
    });
  }

  applyFilter(filterValue: string) {
    // remove white space and lowercase filter value
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  selectStudy(study: Study) {
    this.router.navigateByUrl(
      `/dashboard/settings/admin/studies/${study.srcStudyId}`
    );
  }

  runCalcEngine() {
    const clientId = this.authService.getOrganization().clientId;
    if (clientId) {
      this.toastr.clear();
      this.organizationService.runCalcEngineClient(clientId).subscribe((calcEngInfo) => {
        if (calcEngInfo.running) {
          return this.errorNotificationService.notifyWarning(`Calc Engine is already running for all studies. (${calcEngInfo.errorCode})`);
        }
        return this.toastr.success(`Calc Engine started successfully`, '', { timeOut: 10000 });
      }, err => {
        this.errorNotificationService.notifyError(`Error running calc engine.`, err);
      });
    }
  }

  refreshSource(study: Study) {
    this.toAdminService.refreshStudy(study).subscribe(
      (data) => {
        if(data == "study does not exists") {
          this.errorNotificationService.notifyError("Error refreshing the study.", {message : "Study does not exists in study service"});
        } else {
          this.toastr.success("Study refreshed successfully.");
          let refreshStudy = this.dataSource.data.find(x => x.srcStudyId === study.srcStudyId);
          if(data[0]) {
            refreshStudy.source = data[0].source;
          }
        }
      }, (err) => {
        this.errorNotificationService.notifyError("Error refreshing the study.", err);
    });
  }
  getLatestRefresh() {
    const clientId = this.authService.getOrganization().clientId;
    if (clientId) {
      this.organizationService.getLatestRefreshDetails(clientId).subscribe(refreshInfo => {
        const dialogRef = this.dialog.open(LatestRefreshDialog, {
          width: '1500px',
          disableClose: false,
          data: {dialogData: refreshInfo}
        });
      }, err => {
        this.errorNotificationService.notifyError(`Error getting refresh details.`, err);
      });
    }
  }

  openCalcLockReleaseDialog(): void {
    const dialogRef = this.dialog.open(DialogCalcResetWarningComponent, {
      width: '400px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        alert(result);
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

