<div class="dialog-title">
  <h4 class="h4">Please Confirm</h4>
</div>
<div class="dialog-content">
  <div>
    <span [innerHTML]="confirmMessage"></span>
    <span>{{confirmQuestion}}</span>
  </div>
</div>
<div class="dialog-buttons">
  <button class="button cursor-pointer" (click)="closeConfirmDialog(false)">{{buttonsText.cancel}}</button>
  <button class="button  confirm-button" data-type="primary" (click)=" closeConfirmDialog(true)">{{buttonsText.confirm}}</button>
</div>

<!-- <dialog class="dialog top-unset relative z-0" id="modal-title" role="dialog" open="">
  <form method="dialog">
      <header class="dialog__header repel">
          <div class="dialog__title">
              <svg class=" icon" aria-describedby="symbol-circle-info-solid-desc" aria-labelledby="symbol-circle-info-solid-desc" role="group">
            <desc id="symbol-circle-info-solid-desc-content">circle-info-solid icon</desc>
            <use xlink:href="#svg-circle-info-solid"></use>
        </svg>
              <div>
                  <h3>Dialog title</h3>
                  <h4 class="dialog__subtitle">Subtitle</h4>
              </div>
          </div>
          <button class="icon-button" data-size="lg"><svg class=" icon" aria-describedby="symbol-xmark-desc" aria-labelledby="symbol-xmark-desc" role="group">
            <desc id="symbol-xmark-desc-content">xmark icon</desc>
            <use xlink:href="#svg-xmark"></use>
        </svg></button>
      </header>
      <article class="dialog__article">
          <p>Dialog description offering user actions.</p>
      </article>
      <footer class="dialog__footer repel">
          <menu class="dialog__menu">
              <button class="button" data-type="clear" type="button">Cancel</button>
              <button class="button" data-type="primary" type="button">Continue</button>
          </menu>
      </footer>
  </form>
</dialog> -->