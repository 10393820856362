<div>
<div class="dialog-title">
  <h4>Select your file format</h4>
  <span class="es-icon es-icon-close" (click)="closeDownloadDialog('close')"></span>
</div>
  <table>
    <tr>
      <button (click)="closeDownloadDialog('image')">Image</button>
    </tr>
    <tr>
      <button (click)="closeDownloadDialog('crosstab')">Crosstab</button>
    </tr>
    <tr>
      <button (click)="closeDownloadDialog('pdf')">PDF</button>
    </tr>
    <tr>
      <button (click)="closeDownloadDialog('powerpoint')">Powerpoint</button>
    </tr>
  </table>
</div>