import {Component, Inject, OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'ert-tableau-crosstab-download-dialog',
  templateUrl: './tableau-crosstab-download-dialog.component.html',
  styleUrls: ['./tableau-crosstab-download-dialog.component.scss']
})

export class CrossTabDownloadDialogComponent implements OnInit{
  constructor(
    public dialogRef: MatDialogRef<CrossTabDownloadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
  }
  
  closeDownloadDialog(option:string) {
        this.dialogRef.close(option);

  }
}
