<div class="studies__table-card smallFontSize">
  <div>
    <div class = "admin-study-header-content">
      <div class="studies__table-control-filter">
        <div *ngIf="hasStudies">
          <div class="studies__table-control">
            <input type="search" (keyup)="applyFilter($event.target.value)" placeholder="Search">
          </div>
          
          <!-- <div class="studies__table-control center">
              <button class="studies__table-add-button es-button">Add</button>
              <button class="studies__table-add-button es-button">Clear All</button>
            </div> -->
          <mat-paginator #paginator [pageSize]="50" [pageSizeOptions]="[10, 20, 50, 100]"
                        class="studies__table-control paginator">
          </mat-paginator>
        </div>
      </div>
      <div class="studies-button-containers" *ngIf="!organizationInfo.toBeEnabled">
        <span *ngIf="allowERTAdmin"><button class="button cursor-pointer" (click)="openDialog()">Add Study</button></span>
        <button class="button cursor-pointer" (click)="runCalcEngine()" *ngIf="isAdmin">Run Calc Engine</button>
        <button class="button cursor-pointer" (click)="getLatestRefresh()" *ngIf="isAdmin">Data Refresh Details</button>
        <span *ngIf="allowERTAdmin"><button class="button cursor-pointer" (click)="openCalcLockReleaseDialog()">Clear Calc Lock</button></span>
      </div>
    </div>
    <div class="studies-table" *ngIf="dataSource">
      <mat-table #table [dataSource]="dataSource" class="ert-table" matSort >
        <!-- Name Column -->
        <ng-container matColumnDef="studyName">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Study Name </mat-header-cell>
          <mat-cell *matCellDef="let study" (click)="selectStudy(study)">
            <span *ngIf="study.studyName" class="link"><a>{{study.studyName}}</a></span>
            <ert-no-value *ngIf="!study.studyName "></ert-no-value>
          </mat-cell>
        </ng-container>
        <!-- Protocol Number Column -->
        <ng-container matColumnDef="protocolNumber">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Protocol #</mat-header-cell>
          <mat-cell *matCellDef="let study">
            <span *ngIf="study.protocolNumber">
              <p>{{study.protocolNumber }}</p>
            </span>
            <span *ngIf="!study.protocolNumber" [matTooltip]="'No data available'">
              <ert-no-value></ert-no-value>
            </span>
          </mat-cell>
        </ng-container>
        <!-- Sponsor Name Column -->
        <ng-container matColumnDef="sponsorName">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Sponsor </mat-header-cell>
          <mat-cell *matCellDef="let study">
            <span *ngIf="study.sponsorName">{{study.sponsorName}}</span>
            <ert-no-value *ngIf="!study.sponsorName "></ert-no-value>
          </mat-cell>
        </ng-container>
        <!-- Phase Column -->
        <ng-container matColumnDef="phase">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Phase </mat-header-cell>
          <mat-cell *matCellDef="let study">
            <span *ngIf="study.phase">{{study.phase}}</span>
            <ert-no-value *ngIf="!study.phase "></ert-no-value>
          </mat-cell>
        </ng-container>
        <!-- Therapeutic Area Column -->
        <ng-container matColumnDef="therapeuticArea">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Therapeutic Area </mat-header-cell>
          <mat-cell *matCellDef="let study">
            <span *ngIf="study.therapeuticArea">{{study.therapeuticArea}}</span>
            <ert-no-value *ngIf="!study.therapeuticArea "></ert-no-value>
          </mat-cell>
        </ng-container>
        <!-- Data Insights Status Column -->
        <ng-container matColumnDef="dataInsightsStatus">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Data Insights Status</mat-header-cell>
          <mat-cell *matCellDef="let study"> {{ study.dataInsightsStatusUI }} </mat-cell>
        </ng-container>
        <!-- Date Enabled Column -->
        <ng-container matColumnDef="dateEnabled">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Date Enabled </mat-header-cell>
          <mat-cell *matCellDef="let study">
            <span *ngIf="study.dateEnabled">{{study.dateEnabled | displayDate}}</span>
            <ert-no-value *ngIf="!study.dateEnabled "></ert-no-value>
          </mat-cell>
        </ng-container>
        <!-- Study Status Column -->
        <ng-container matColumnDef="studyStatus">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Study Status </mat-header-cell>
          <mat-cell *matCellDef="let study"> {{ study.studyStatus }} </mat-cell>
        </ng-container>
        <!-- Closed Date Column -->
        <ng-container matColumnDef="closedDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Closed Date </mat-header-cell>
            <mat-cell *matCellDef="let study">
              <span *ngIf="study.closedDate">{{study.closedDate | displayDate}}</span>
              <ert-no-value *ngIf="!study.closedDate "></ert-no-value>
            </mat-cell>
          </ng-container>
        <!-- Source Column -->
        <ng-container matColumnDef="source" *ngIf="allowERTAdminAndClientAdmin">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Source </mat-header-cell>
          <mat-cell *matCellDef="let study">
              <span *ngIf="study.source">{{study.source}}</span>
            <ert-no-value *ngIf="!study.source"></ert-no-value>
          </mat-cell>
        </ng-container>
        <!-- Refresh Column -->
        <ng-container matColumnDef="refresh" *ngIf="allowERTAdmin">
          <mat-header-cell *matHeaderCellDef mat-sort-header> </mat-header-cell>
          <mat-cell *matCellDef="let study">
              <div><button class="button cursor-pointer" (click)="refreshSource(study)">Refresh</button></div>
          </mat-cell>
        </ng-container>
        <!-- Last Calc Date Column -->
        <ng-container matColumnDef="loadDate">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Last Calc Date </mat-header-cell>
          <mat-cell *matCellDef="let study">
            <span *ngIf="study.loadDate">{{dateformat(study?.loadDate, 'dd-mmm-yyyy h:MM:ss TT').toString()}}</span>
            <ert-no-value *ngIf="!study.loadDate "></ert-no-value>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let study; columns: displayedColumns;"></mat-row>
      </mat-table>
    </div>
  </div>
  <ert-no-data *ngIf="hasNoStudies" message="No Studies enabled for this Organization"></ert-no-data>
</div>
