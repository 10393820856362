import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ordinal'
})
export class OrdinalPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let numberVal: number;
    if (typeof value !== 'number') {
      numberVal = Number(value);
    } else {
      numberVal = value;
    }
    if (isNaN(numberVal)) {
      return value;
    }
    return this.convertToOrdinal(numberVal);
  }

  private convertToOrdinal(i: number): string {
    const j: number = i % 10;
    const k: number = i % 100;
    if (j === 1 && k !== 11) {
      return i + 'st';
    }
    if (j === 2 && k !== 12) {
        return i + 'nd';
    }
    if (j === 3 && k !== 13) {
        return i + 'rd';
    }
    return i + 'th';
  }
}
