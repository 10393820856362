<mat-card>
  <div class="d-flex">
    <ert-search-paginator *ngIf="ds" [ds]="ds" [dateColumn]="'changedOn'"></ert-search-paginator>
    <mat-paginator #paginator [pageSize]="25" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>

  <mat-table *ngIf="ds" [dataSource]="ds" matSort class='ert-table'>
    <!-- name Column -->
    <ng-container matColumnDef="srcStudyId">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Study ID </mat-header-cell>
      <mat-cell *matCellDef="let studyAudit"> {{studyAudit.srcStudyId}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="studyOid">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Study OID </mat-header-cell>
      <mat-cell *matCellDef="let studyAudit"> {{studyAudit.studyOid}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="studyName">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Study Name </mat-header-cell>
      <mat-cell *matCellDef="let studyAudit"> {{studyAudit.studyName}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="changedOn">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Changed On </mat-header-cell>
      <mat-cell *matCellDef="let studyAudit"> {{studyAudit.changedOn}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="createdByName">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Changed By </mat-header-cell>
      <mat-cell *matCellDef="let studyAudit"> {{studyAudit.createdByName}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="reasonForChange">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Reason For Change </mat-header-cell>
      <mat-cell *matCellDef="let studyAudit"> {{studyAudit.reasonForChange}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="phase">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Phase </mat-header-cell>
      <mat-cell *matCellDef="let studyAudit"> {{studyAudit.phase}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="sponsorName">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Sponsor Name </mat-header-cell>
      <mat-cell *matCellDef="let studyAudit"> {{studyAudit.sponsorName}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="protocolName">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Protocol Name </mat-header-cell>
      <mat-cell *matCellDef="let studyAudit"> {{studyAudit.protocolName}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="protocolNumber">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Protocol # </mat-header-cell>
      <mat-cell *matCellDef="let studyAudit"> {{studyAudit.protocolNumber}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="country">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Country </mat-header-cell>
      <mat-cell *matCellDef="let studyAudit"> {{studyAudit.country}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="therapeuticArea">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Therapeutic Area </mat-header-cell>
      <mat-cell *matCellDef="let studyAudit"> {{studyAudit.therapeuticArea}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="numSites">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Sites </mat-header-cell>
      <mat-cell *matCellDef="let studyAudit"> {{studyAudit.numSites}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="numSubjects">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Subjects </mat-header-cell>
      <mat-cell *matCellDef="let studyAudit"> {{studyAudit.numSubjects}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="indications">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Indication </mat-header-cell>
      <mat-cell *matCellDef="let studyAudit"> {{studyAudit.indications}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Synopsis </mat-header-cell>
      <mat-cell *matCellDef="let studyAudit"> {{studyAudit.description}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="startDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Start Date </mat-header-cell>
      <mat-cell *matCellDef="let studyAudit"> {{studyAudit.startDate}} </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let studyAudit; columns: displayedColumns;"></mat-row>
  </mat-table>

</mat-card>
