import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { SetBreadCrumbs } from '@app/core/store/breadcrumbs/breadcrumbs.actions';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AuditComponentService } from '@app/shared/services/audit';
import dateFormat from 'dateformat';

export interface StudyAudit {
  srcStudyId: number;
  studyName: string;
  changedOn: string;
  createdByName: string;
  reasonForChange: string;
  phase: string;
  sponsorName: string;
  protocolName: string;
  country: string;
  therapeuticArea: string;
  numSites: number;
  numSubjects: number;
  indications: string;
  description: string;
  studyOid: string;
  startDate: string;
  protocolNumber: string;
}

@Component({
  selector: 'ert-edit-study-audit',
  templateUrl: './edit-study-audit.component.html',
  styleUrls: ['./edit-study-audit.component.scss']
})

export class EditStudyAuditComponent implements AfterViewInit {
  ds: MatTableDataSource<StudyAudit>;
  displayedColumns: string[] = ['srcStudyId', 'studyOid', 'studyName', 'changedOn', 'createdByName', 'reasonForChange', 'phase',
   'sponsorName', 'protocolName', 'protocolNumber', 'country', 'therapeuticArea', 'numSites', 'numSubjects', 'indications',
    'description', 'startDate'];
  @ViewChild(MatSort) sort: MatSort;
  dataModel: StudyAudit[];
  // dateFormat = require('dateformat');
  @ViewChild(MatPaginator) pagnitor: MatPaginator;

  constructor(private auditService: AuditComponentService,
              private store: Store<any>) {
    this.store.dispatch(new SetBreadCrumbs({
      crumbs: [
        {text: 'Audit Report'},
        {text: 'Study Audit'}
      ]
    }));
   }

   ngAfterViewInit() {
    // this.auditService.getAuditStudy().subscribe(data => {
    //   this.dataModel = data;
    //   this.initAuditTable(this.dataModel);
    // });
  }

  initAuditTable(data) {
    data.forEach(element => {
      element.changedOn = dateFormat(element.changedOn, 'dd-mmm-yyyy H:MM:ss "UTC"', true);
      element.startDate = dateFormat(element.startDate, 'dd-mmm-yyyy H:MM:ss "UTC"', true);
    });
    this.ds = new MatTableDataSource<StudyAudit>(data);
    this.ds.paginator = this.pagnitor;
    setTimeout(() => {
      this.ds.sort = this.sort;
      this.ds.sortingDataAccessor = (datas: any, header: string): any => {
        if (header === 'startDate' || header === 'changedOn') {
          return new Date(datas[header].replace(/-/g,' '));
        } else if (typeof datas[header] === 'string') {
          return datas[header].trim().toLocaleLowerCase();
        } else {
          return datas[header];
        }
      }
    });
  }

}
