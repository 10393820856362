import { Component, OnInit, Input, ContentChild, TemplateRef } from '@angular/core';
import { FormGeneratorService } from '@shared/form-generator/form-base.service';

/**
 * ----- USAGE WITH A FORM TO PREVIEW A TEMPLATE ---------
 * ng-template contains the template with forms values to be rendered
 *
  <mat-card class="display display-flex">
    <ert-dynamic-form [formData]="formData" (submit)="submit($event)" class="split"></ert-dynamic-form>
    <ert-form-preview *ngIf="true" [fields]="formData.fields" [previewTitle]="'Preview'" class="split">
      <ng-template #templateRef let-fields="fields">
        <p>Message Options: {{fields.message}}</p>

        <div *ngFor="let item of fields | keyvalue">
          <p>{{item.key}} - {{item.value}}</p>
        </div>
      </ng-template>
    </ert-form-preview>
  </mat-card>
*
*/
@Component({
  selector: 'ert-form-preview',
  templateUrl: './form-preview.component.html',
  styleUrls: ['./form-preview.component.scss']
})
export class FormPreviewComponent implements OnInit {

  constructor(
    private formGeneratorService: FormGeneratorService
  ) { }
  @Input() fields: object;
  @Input() previewTitle = 'Preview';
  @ContentChild(TemplateRef) itemTemplate: TemplateRef<any>;

  ngOnInit() {
    this.formGeneratorService.getFielValues.subscribe((data: any) => {
      this.fields = data;
    });
  }

}
