<div class="d-flex" *ngIf="studies.length > 0">
  <mat-form-field >
    <mat-select matNativeControl placeholder="Choose a Study" [(ngModel)]="allStudies" title="Pick a Study to view Audit Trial Report"
      #search (selectionChange)="searchInputChange($event.value)">
      <mat-option value="">All Studies</mat-option>
      <mat-option *ngFor="let study of studies" [title]="study.studyName" [value]="study.studyName">
        {{study.studyName}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <input (dateInput)="startDateChange($event.value)" [(ngModel)]="fromDate" (click)="resetStartDate();startDatePicker.open()"
      title="Pick a Start Date to customise the view for Audit Trial Report" matInput [matDatepicker]="startDatePicker"
      placeholder="Choose a start date" onkeydown="return false">
    <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
    <mat-datepicker #startDatePicker></mat-datepicker>
  </mat-form-field>
  <mat-form-field>
    <input (dateInput)="endDateChange($event.value)" [(ngModel)]="toDate" (click)="resetEndDate();endDatePicker.open()"
      title="Pick an End Date to customise the view for Audit Trial Report" onkeydown="return false" matInput
      [matDatepicker]="endDatePicker" placeholder="Choose an end date">
    <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
    <mat-datepicker #endDatePicker></mat-datepicker>
  </mat-form-field>

  <button class="es-button export-button" mat-button (click)="exportToCSV()" title="Export the data to CSV file"><img
      src="assets/icons/ux/Export.svg" alt="Export Icon">Export</button>
</div>