import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ert-loading-chart',
  templateUrl: './data-loading-chart.component.html',
  styleUrls: ['./data-loading-chart.component.scss']
})
export class DataLoadingChartComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
