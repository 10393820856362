import { Component, OnInit, ViewChild } from '@angular/core';
import { AuditComponentService } from '../services/audit';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SetBreadCrumbs } from '@app/core/store/breadcrumbs/breadcrumbs.actions';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { ErrorNotificationService } from '@app/core/services/error-notification.service';
import { GlobalHeaderService } from '@app/shared/services/global-header.service';
import { BreadcrumbConfigService } from '@app/shared/services/breadcrumb-config.service';
import dateFormat from 'dateformat';


@Component({
  selector: 'app-audit-log',
  templateUrl: './audit-log.component.html',
  styleUrls: ['./audit-log.component.scss']
})
export class AuditLogComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  auditData;
  auditDataFlag: string = 'unchecked';
  displayedColumns = ['changedOn', 'changedBy', 'action', 'protocolNumber', 'object',
    'rowData', 'changedFields', 'reasonForChange'];
  displayedData;
  exportData;
  // dateFormat = require('dateformat');

  constructor(
    private auditLogService: AuditComponentService,
    private store: Store<any>,
    private errorNotificationService: ErrorNotificationService,
    private globalHeaderService: GlobalHeaderService,
    private brdCrmbConfig: BreadcrumbConfigService,
    
  ) {
    this.globalHeaderService.getHeader().then((header) => {
      header.addBreadcrumbs([
        this.brdCrmbConfig.getHomeBrdCrmb(),
        this.brdCrmbConfig.getOrgSettingBrdCrmb()
      ]);
      header.subscribe('logout', this.globalHeaderService.logoutHandler());
      header.subscribe('session-expired', this.globalHeaderService.sessionExpHandler());
    });
   }

  ngOnInit() {
    this.store.dispatch(
      new SetBreadCrumbs({
        crumbs: [{ text: 'Organization Settings', url: 'settings/admin/organization/' },
        { text: 'Audit Trail' }]
      })
    );
    this.auditLogService.getAuditLog().subscribe(logs => {
      if (logs) {
        this.auditData = logs;
        this.initAuditTable(this.auditData);
        this.auditDataFlag = 'valid';
      } else {
        this.auditDataFlag = 'invalid';
      }
    });
  }

  initAuditTable(auditData) {
    auditData.forEach(element => {
      element.changedOn = dateFormat(element.changedOn, 'dd-mmm-yyyy H:MM:ss "UTC"', true);
    });
    this.displayedData = new MatTableDataSource(auditData);
    this.displayedData.paginator = this.paginator;
    setTimeout(() => {
      this.displayedData.sort = this.sort;
      this.displayedData.sortingDataAccessor = (datas: any, header: string): any => {
        if (header === 'changedOn') {
          return new Date(datas[header].replace(/-/g, ' '));
        } else if (typeof datas[header] === 'string') {
          return datas[header].trim().toLocaleLowerCase();
        } else {
          return datas[header];
        }
      };
    });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.displayedData.filter = filterValue;
  }

  onExport() {
    try {
      this.exportData = this.displayedData.filteredData;
      const today = dateFormat(new Date(), 'dd-mmm-yyyy').toString();
      var orgName = JSON.parse(sessionStorage.getItem('RBM-AUTH'));
      // Removing all special characters and replacing space with underscore
      orgName = orgName.clientName.toString().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '').replace(/ /g,"_");
      const fileName = 'AuditLog_' + orgName + '_' + today;
      const options = {
        showLabels: true,
        headers: ['Changed On', 'Changed By', 'Action', 'Protocol #', 'Object Name', 'Row Data',
          'Changed Fields', 'Reason For Change']
      };
      new ngxCsv(this.exportData, fileName, options);
    } catch(e) {
      this.errorNotificationService.notifyError('Error Downloading File.', e);
    }
  }


}
