import { Component, OnInit } from '@angular/core';
import { GlobalHeaderService } from '@shared/services/global-header.service';
import { BreadcrumbConfigService } from '@shared/services/breadcrumb-config.service';
import { UpdateToolbar } from '@core/store/toolbar/toolbar.actions';
import { ActionTabSwitch} from '@app/core/store/tabswitch/tabswitch.actions';
import {Store} from '@ngrx/store';

@Component({
  selector: 'app-data-monitoring',
  templateUrl: './data-monitoring.component.html',
  styleUrls: ['./data-monitoring.component.scss']
})
export class DataMonitoringComponent implements OnInit {

  constructor(
    private store: Store<any>,
    private globalHeaderService: GlobalHeaderService,
    private brdCrmbConfig: BreadcrumbConfigService,
  ) {
    this.store.dispatch(new UpdateToolbar({
      title: 'Data Quality Monitoring',
    }));
  }

  ngOnInit() {
    this.setupBreadcrumbs();
    this.store.dispatch(new ActionTabSwitch(''));
  }

  setupBreadcrumbs() {
    this.globalHeaderService.getHeader().then((header) => {
      header.addBreadcrumbs([
        this.brdCrmbConfig.getHomeBrdCrmb(),
        this.brdCrmbConfig.getOrgSettingBrdCrmb(),
        this.brdCrmbConfig.getDataMonitoringBrdCrmb(),
        // {label: metricData.calcTypeName}
      ]);
      header.subscribe('logout', this.globalHeaderService.logoutHandler());
      header.subscribe('session-expired', this.globalHeaderService.sessionExpHandler());
    });
  }

}
