
export type ColumnTypes = 'text' | 'number'| 'email' | 'select' | 'date' | 'hyperlink';
export type ActionTypes = 'actionAll' | 'actionEditOnly';

export enum EnumFieldTypes {
  TEXT = 'text',
  EMAIL = 'email',
  SELECT = 'select',
  DATE = 'date',
  HYPERLINK = 'hyperlink',
  EXPAND = 'expand',
  TOGGLE = 'toggle',
  NUMBER = 'number',
}

export interface TypeOptions {
  key: any;
  value: any;
}

interface SingleColumDetail {
  validations?: any[];
  type: ColumnTypes ;
  displayName: string;
  options?: TypeOptions[];
  required?: boolean;
  actions?: ActionTypes;
  class?: string;
}

export interface ColumnDetails {
  [key: string]: SingleColumDetail;
}
