import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef, Input, AfterViewInit } from '@angular/core';
import { CommunicationEventsService } from '@app/shared/services/communication/communication-events.service';
declare var Plotly: any;

@Component({
  selector: 'ert-box-plot',
  templateUrl: './box-plot.component.html',
  styleUrls: ['./box-plot.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BoxPlotComponent implements OnInit, AfterViewInit {
  private chartContainer: ElementRef;
  @ViewChild('boxplot') set content(content: ElementRef) {
    if(content) {
      this.chartContainer = content;
    }
  }

  @Input() private boxPlotData;
  @Input() private height;
  @Input() private width;
  studyProtocolData;
  firstDraw: boolean = true;
  selectedStudyData: any;

  constructor(
     private communicationEvents: CommunicationEventsService,
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.drawChart();
    this.firstDraw = false;
    window.addEventListener('resize', this.drawChart.bind(this));
  }

  ngOnChanges(changes) {
    if (this.boxPlotData && !this.firstDraw) {
      this.firstDraw = true;
      this.drawChart();
      this.firstDraw = false;
    }
  }

  getLayout() {
    // This object defines the plot
    var groupedBoxPlot = {
      data: [{
        y: [],
        x: [],
        text: [],
        ids:[],
        name: 'Compliance       ',
        marker: { color: '#1692BA' },
        boxpoints: 'Wiskers and Outliers',
        hovertemplate: '%{text} <br>' + '%{y}% <extra></extra>',
        type: 'box'
      },
      {
        y: [],
        x: [],
        name: 'Quality      ',
        mode: 'markers',
        marker: { color: '#90368C' },
        text: [],
        boxpoints: 'Wiskers and Outliers',
        type: 'box'
      }],
      layout: {
        title: {
          // text: '<h4>' +'STUDIES BY SCORE % CHANGE'+ '</h4>',
          font: {
            family: 'Noto Sans',
          }
        },
        margin: {
          l: 10,
          b: 50,
          t: 20,
          r: 10
        },
        legend: {
          x: 0.5,
          y: 1.1,
          xanchor: 'center',
          bgcolor: '#f8f8f8',
          "orientation": "h",
          font: {
            family: 'Noto Sans',
          }
        },
        autosize: false,
        height: 285,
        shapes: [{
          type: 'line',
          xref: 'paper',
          y0: 0,
          y1: 0,
          x0: 0,
          x1: 1,
          line: {
            width: 1,
            dash: 'dot'
          }
        }],
        xaxis: {
          ticksuffix: '</b>',
          tickprefix: '<b>',
          tickfont: {
            family: 'Noto Sans',
            color: '#808080',
            size: 11
          },
          fixedrange: true,
          linecolor: 'black',
          linewidth: 1,
          mirror: 2,
          title: {
            text: '<b>Change Category</b>',
            font: {
              size: 12,
              family: 'Noto Sans',
              color: '#808080'
            }
          },
        },
        yaxis: {
          automargin: true,
          fixedrange: true,
          ticksuffix: '%</b>',
          tickprefix: '<b>',
          tickfont: {
            family: 'Noto Sans',
            color: '#808080',
            size: 11
          },
          title: {
            text: '<b>Score By % Change</b>',
            font: {
              size: 12,
              family: 'Noto Sans',
              color: '#808080'
            }
          },
          zeroline: false,
          linecolor: 'black',
          linewidth: 1,
          mirror: 2,
          hovermode: 'closest',
        },
        boxmode: 'group',
      }
    };

    return groupedBoxPlot;
  }

  drawChart() {
    if (this.firstDraw) {
      const chartData = this.boxPlotData;
      var layout = this.getLayout();
    // Adding data to the chart
      layout.layout['width'] = (window.innerWidth / 2) - 80;
      layout.data[0].y = chartData.yaxis_comp;
      layout.data[0].x = chartData.xaxis_comp;
      layout.data[0].text = chartData.tooltipText_comp;
      layout.data[0].ids = chartData.tooltipText_comp_studyIds;
      layout.data[1].y = chartData.yaxis_qual;
      layout.data[1].x = chartData.xaxis_qual;
      layout.data[1].text = chartData.tooltipText_qual;
      layout.data[1].ids = chartData.tooltipText_qual_studyIds;

      var plotEvent = this.chartContainer.nativeElement;

      if(this.chartContainer && this.chartContainer.nativeElement) {
        Plotly.newPlot(this.chartContainer.nativeElement, layout);
      }
      // This syntax can be used to add events to plot
      plotEvent.on('plotly_click', (data) => {
        this.selectedStudyData = {studyId: data.points[0].id};
      });

      // Need this as a workaround because plotty has a bug handling event propagation
      plotEvent.addEventListener('click', (event) => {
        event.stopPropagation();
        event.stopImmediatePropagation();
        if (this.selectedStudyData ) {
          this.communicationEvents.singleClickEventListener(this.selectedStudyData);
        }
      });

    } else {
      // Updating Width for window resize
      var update = {
        width: (window.innerWidth / 2) - 80
      }
      if(this.chartContainer && this.chartContainer.nativeElement) {
        Plotly.relayout(this.chartContainer.nativeElement, update);
      }
    }
  }

}
