<mat-drawer-container autosize>
  <mat-drawer class="sidenav" #drawer opened mode="side">
    <mat-list class="full-width vert-align" role="list">
      <div>
        <button color="primary" mat-button [routerLink]="['/dashboard/alerts']">
          <mat-icon aria-label="Alerts" svgIcon="alerts"></mat-icon>Alerts
        </button>
      </div>
      <div>
        <button mat-button [routerLink]="['/dashboard/tasks']">
          <mat-icon aria-label="Tasks" svgIcon="tasks"></mat-icon>Tasks
        </button>
      </div>
    </mat-list>
  </mat-drawer>

  <mat-card class="dashboard__card">
    <div>
      <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
      </mat-form-field>
      <mat-paginator #paginator [pageSize]="20" [pageSizeOptions]="[10, 20, 50, 100]">
      </mat-paginator>
    </div>

    <mat-table #table [dataSource]="displayedData" class="ert-table" matSort>

      <ng-container matColumnDef="taskTitle">
        <mat-header-cell class="title-width" *matHeaderCellDef mat-sort-header> Title </mat-header-cell>
        <mat-cell class="title-width" *matCellDef="let tasks" [ngClass]="{'assigned' : tasks.taskOrigin === 'assigned'}">
          {{tasks.taskTitle}}
        </mat-cell>

      </ng-container>
      <ng-container matColumnDef="siteNo">
        <mat-header-cell class="site-width" *matHeaderCellDef mat-sort-header> Site # </mat-header-cell>
        <mat-cell class="site-width" *matCellDef="let tasks" [ngClass]="{'assigned' : tasks.taskOrigin === 'assigned'}">
          {{tasks.siteNo}}
        </mat-cell>

      </ng-container>
      <ng-container matColumnDef="protocolNumber">
        <mat-header-cell class="protocol-width" *matHeaderCellDef mat-sort-header> Protocol # </mat-header-cell>
        <mat-cell class="protocol-width" *matCellDef="let tasks" [ngClass]="{'assigned' : tasks.taskOrigin === 'assigned'}">
          {{tasks.protocolNumber}}
        </mat-cell>

      </ng-container>

      <ng-container matColumnDef="taskDescription">
        <mat-header-cell class="desc-width" *matHeaderCellDef mat-sort-header> Description </mat-header-cell>
        <mat-cell class="desc-width" *matCellDef="let tasks" [ngClass]="{'assigned' : tasks.taskOrigin === 'assigned'}">
          {{tasks.taskDescription}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="assignee">
        <mat-header-cell class="assignee-width" *matHeaderCellDef mat-sort-header> Assignee </mat-header-cell>
        <mat-cell class="assignee-width" *matCellDef="let tasks" [ngClass]="{'assigned' : tasks.taskOrigin === 'assigned'}">
          {{tasks.assignee}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="dueDate">
        <mat-header-cell class="duedate-width" *matHeaderCellDef mat-sort-header> Due Date </mat-header-cell>
        <mat-cell class="duedate-width" *matCellDef="let tasks" [ngClass]="{'assigned' : tasks.taskOrigin === 'assigned'}">
          {{tasks.dueDate | displayDate}} </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row [routerLink]="['detail', row.studyTaskId]" *matRowDef="let row; columns: displayedColumns;"></mat-row>

    </mat-table>

  </mat-card>

</mat-drawer-container>
