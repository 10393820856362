<div>
    <div class="dialog-title">
      <h4>Latest Job Refresh Extract Details</h4>
      <span class="cancel_icon">
      <button class="icon-button cursor-pointer" (click)="closeDownloadDialog('close')">
        <svg viewBox="0 0 384 512" fill="currentColor">
          <path d="M345 137c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-119 119L73 103c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l119 119L39 375c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l119-119L311 409c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-119-119L345 137z"/></svg>
      </button>
      </span>
    </div>
    <div class="modal-content">
      <table class="table">
            <th>Refresh Extract Job Notes:</th>
            <th>DataSource Id:</th>
            <th>DataSource Name:</th>
            <th>Refresh Extract Job Id:</th>
            <th>Mode:</th>
            <th>Type:</th>
            <th>Progress:</th>
            <th>Created At:</th>
            <th>Started At:</th>
            <th>Completed At:</th>
            <tr *ngFor='let item of resultJobs'>
                 <td>{{(item.job.extractRefreshJob.notes)}}</td>
                 <td>{{(item.job.extractRefreshJob.datasource.id)}}</td>
                 <td>{{(item.job.extractRefreshJob.datasource.name)}}</td>
                 <td>{{(item.job.id)}}</td>
                 <td>{{(item.job.mode)}}</td>
                 <td>{{(item.job.type)}}</td>
                 <td>{{(item.job.progress)}}</td>
                 <td>{{(item.job.createdAt)}}</td>
                 <td>{{(item.job.startedAt)}}</td>
                 <td>{{(item.job.completedAt)}}</td>
            </tr>  

      </table>
    </div>
    </div>