<div class="dynamic-form">
  <form (ngSubmit)="onSubmit($event)" [formGroup]="form" autocomplete="{{formOptions.formAutoComplete ? 'on' : 'off'}}" class="row">
    <h4 *ngIf="formOptions.subheading">{{formOptions.subheading}}</h4>
    <div *ngFor="let field of formFields" class="form-row" [ngClass]="field.classes">
      <div class="dynamic-form__fields">
        <!-- FORM CONTROL FIELD  -->
        <ng-container [ngTemplateOutlet]="formFieldsTemplate" [ngTemplateOutletContext]="{field: field, form: form}"></ng-container>

        <!-- FIELDSET FIELDS  -->
        <ng-container *ngIf="field.controlType ==='fieldSet'">
            <fieldset class="row">
            <legend>{{field.label}}</legend>
            <div *ngFor="let singleField of field.fieldsList" [ngClass]="singleField.classes">
              <ng-container [ngTemplateOutlet]="formFieldsTemplate" [ngTemplateOutletContext]="{field: singleField, form: form.get(field.fieldGroupName)}"></ng-container>
            </div>
          </fieldset>
        </ng-container>

        <!-- FORM GROUP FIELDS  -->
        <ng-container *ngIf="field.controlType ==='formGroup'">
            <div *ngFor="let singleField of field.fieldsList" [ngClass]="singleField.classes">
              <ng-container [ngTemplateOutlet]="formFieldsTemplate" [ngTemplateOutletContext]="{field: singleField, form: form.get(field.fieldGroupName)}"></ng-container>
            </div>
        </ng-container>

         <!-- SHOW INLINE ERRORS -->
        <ng-container *ngIf="formOptions.showInlineErrors">

          <mat-error *ngIf="!field.valid && form.touched && form.controls[field.name]?.hasError('required')">
            {{field.label}} is required
          </mat-error>
          <div *ngFor="let errors of formErrors | keyvalue">
            <div *ngIf="errors.key===field.name && !field.valid">
              <mat-error *ngFor="let errorMessage of errors.value">{{errorMessage}}</mat-error>
            </div>
          </div>
        </ng-container>

      </div>
    </div>
    <div class="form-row" *ngIf="!formOptions.hideAllBtn">
      <button class="button cursor-pointer" *ngIf="!formOptions.cancelBtn">Cancel</button>
      <button class="button cursor-pointer" type="submit"
        [disabled]="!form.valid">{{formOptions.submitBtnText | titlecase }}</button>
    </div>
  </form>
  <div *ngIf="payLoad" class="form-row">
    <strong>Saved the following values</strong><br>{{payLoad}}
  </div>
</div>


<!--TEMPLATTE REUSABLE - TO SHOW FIELDS-->
<ng-template #formFieldsTemplate let-field="field" let-form="form">
  <div [ngSwitch]="field.controlType" [formGroup]="form">
    <!-- INPUT FIELD  -->
    <div *ngSwitchCase="'input'">
      <span>
        <cr-input 
           [formControlName]="field.name"
           [label]="field.label + (field?.required ? ' *' : '')"
           [ngClass]="field.name"
           [type]="field.fieldType"></cr-input><span *ngIf="field.optionalText">{{field.optionalText}}</span>
        </span>
        <mat-hint *ngIf="field.helperText" align="end"> {{field.helperText}}</mat-hint>
    </div>

    <!-- TEXTAREA FIELD  -->
    <span *ngSwitchCase="'textarea'" class="text-area">
    <cr-input [textarea]="true"
        [rows]="field.rows"
        [formControlName]="field.name"
        [label]="field.label + (field?.required ? ' *' : '')"
        [ngClass]="field.name">
    </cr-input>
  </span>

    <!-- SELECT FIELD  -->
    <span *ngSwitchCase="'select'" class="fieldset-item">
      <label [for]="field.name">{{field.label + (field?.required ? ' *' : '')}}</label>
      <select 
        [id] = "field.name"
        [ngClass]="field.name"
        [formControlName]="field.name"
        [label]="field.label + (field?.required ? ' *' : '')"
        (selectionChange)="eventFn(field,$event)">
        <option *ngFor="let opt of field.optionsList" [value]="opt.key">{{opt.value}}</option>
      </select>
    </span>

    <!-- CHECKBOX FIELD  -->
    <div *ngSwitchCase="'checkbox'">
      <input type="checkbox"
        [ngClass]="field.name"
        [checked]="field.checked"
        (change)="eventFn(field, $event)">
        {{field.label}}
    </div>

    <!-- Icon FIELD  -->
    <div *ngSwitchCase="'icon'">
      <span *ngIf="field.solidFill === true">
        <mat-icon [ngClass]="field.name" 
                  [ngStyle]= "{'-webkit-text-fill-color': field.color,
                              'height': field.height,
                              'width': field.width}">
                  {{field.iconDisplay}}
        </mat-icon>
      </span>
      <span *ngIf="field.solidFill === false">
        <mat-icon [ngClass]="field.name" 
                  [ngStyle]="{'color': field.color,
                            'height': field.height,
                            'width': field.width}">
                  {{field.iconDisplay}}
        </mat-icon>
      </span>
    </div>

    <!-- RADIO FIELD  -->
    <div class="dynamic-form__radio" *ngSwitchCase="'radio'">
      <label>{{field.label + (field?.required ? ' *' : '')}} </label>
      <mat-radio-group
        [formControlName]="field.name"
        [ngClass]="field.name">
        <mat-radio-button *ngFor="let opt of field.optionsList" [value]="opt.key">{{opt.value}}</mat-radio-button>
      </mat-radio-group>
    </div>

    <!-- DATEPICKER FIELD  -->
    <mat-form-field class="dynamic-form__date-picker" *ngSwitchCase="'datePicker'">
      <input matInput
         [min]="field.minDate"
         [matDatepicker]="datePicker"
         [placeholder]="field.label + (field?.required ? ' *' : '')"
         [formControlName]="field.name"
      >
      <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
      <mat-datepicker #datePicker></mat-datepicker>
    </mat-form-field>

    <!-- SLIDER FIELD  -->
    <mat-form-field class="slider-input-field" *ngSwitchCase="'slider'">
      <input class="slider-input" matInput type="field.fieldType"
         [placeholder]="field.label + (field?.required ? ' *' : '')"
         [formControlName]="field.name"
         [(ngModel)]="field.value"
         (change)="eventFn(field, $event)"
         [min]="field.min"
         [max]="field.max"
         [step]="field?.step"
         [disableControl]="field.disabled"
      >
      <span class="slider-units" *ngIf="field?.units">{{ field?.units }}</span>
      <mat-slider class="slider-field"
          [formControlName]="field.name"
          [(ngModel)]="field.value"
          [min]="field.min"
          [max]="field.max"
          [step]="field?.step"
          (input)="eventFn(field, $event)"
          [disableControl]="field.disabled"
          thumb-label="field.thumbLabel">
      </mat-slider>
    </mat-form-field>

    <!-- SLIDE TOGGLE FIELD -->
    <div *ngSwitchCase="'slide-toggle'" class="dynamic-form__slide-toggle flex">
      <span>{{field.label}} : </span>
      <span class='medium-form-text toggle-text' [ngClass]="!field.status ? 'active': 'inactive'">{{field.disableText}}</span>
      <label [for]="field.name" [ngClass]="field.name +' switch'">
        <input type="checkbox" role="switch" [id]="field.name" [checked]="field.status" [(ngModel)]="field.status"  [formControlName]="field.name"
        [disableControl]="field.disabled">
    </label>
      <span class='medium-form-text toggle-text' [ngClass]="field.status ? 'active': 'inactive'">{{field.enableText}}</span>
    </div>
  </div>
</ng-template>

