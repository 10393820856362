import { BigCardComponent } from './modals/big-card.component';
import { PermissionService } from '@shared/services/permisssion.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, Input, OnChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UtilsService } from '../services/utils.service';

@Component({
  selector: 'ert-scorecard',
  templateUrl: './scorecard.component.html',
  styleUrls: ['./scorecard.component.scss']
})
export class ScorecardComponent implements OnInit, OnChanges {
  @Input() scorecardTitle: string;
  @Input() displayedColumns = ['name', 'score', 'trend'];
  @Input() imageFilename: string;
  @Input() kriData: any[];
  @Input() siteId: number;
  @Input() studyId: number;

  // dataSource = new MatTableDataSource(ELEMENT_DATA);
  dataSource: any = null;
  private displayTooltip = 'none';
  private tooltipX = '0';
  private tooltipY = '0';
  private tooltipSubject: any;
  private data: any = null;
  isStudyAdmin: boolean = false;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
    private permService: PermissionService,
  ) { }

  openDialog(): void {
    const dialogRef = this.dialog.open(BigCardComponent, {
      width: '250px',
      data: this.data
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  addAlert(): void {
    const dialogRef = this.dialog.open(BigCardComponent, {
      width: '250px',
      data: this.data
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }


  ngOnInit() {
    const editStudyPermission = this.permService.hasPermission('editStudy', { studyId: this.studyId.toString() });
    this.isStudyAdmin = (editStudyPermission && editStudyPermission === 'all')
      || editStudyPermission || this.permService.hasRole('vp') || this.permService.hasRole('studyAdmin');
  }

  ngOnChanges(changes) {
    if (changes.kriData && changes.kriData.currentValue) {
      const kriData = changes.kriData.currentValue;
      this.kriData = kriData;
      this.dataSource = new MatTableDataSource(kriData);
      this.dataSource.sort = this.sort;
    }
  }

  getScoreColor(score) {
    return UtilsService.getColorFromScore(score);
  }

  mouseEnter($event, $element) {
    // this.tooltipSubject = $element;
    this.displayTooltip = 'block';
    this.tooltipX = ($event.x + 10).toString() + 'px';
    this.tooltipY = $event.y.toString() + 'px';
  }

  mouseExit($event: MouseEvent, $element) {
    this.displayTooltip = 'none';
  }

  scoreTooltip(element) {
    var threshold;
    if (element.units === '%') {
      threshold = parseFloat(element.calcContext.thresholdValue) * 100 + element.units.toLowerCase();
    } else threshold = parseFloat(element.calcContext.thresholdValue) + ' ' + element.units.toLowerCase();
    return 'Value: ' + (element.calcLongDisplayVal !== 'No Data' ? element.calcLongDisplayVal.toLowerCase() : 'No Data') + '\n' + 'Threshold: ' + (element.calcContext.thresholdValue ? threshold : 'No Data');
  }
}
