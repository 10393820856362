import { Component, OnInit, Inject, Optional } from "@angular/core";
import { MatDialog, MatDialogRef,  MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UtilsService } from "@app/shared/services/utils.service";
import { ToAdminService } from "@app/to-admin/to-admin.service";
import { ErrorNotificationService } from '@app/core/services/error-notification.service';
import { ToastrService } from 'ngx-toastr';
import { StudyService } from '@shared/services/study.service';

@Component({
  selector: "ert-dialog-add-study",
  templateUrl: "./dialog-add-study.component.html",
  styleUrls: ["./dialog-add-study.component.scss"],
})
export class DialogAddStudyComponent implements OnInit {
  studyServiceStudyList;
  selectedStudy = "null";
  filStudyServiceStudyList = [];
  diStudies;

  constructor(
    private studyService: StudyService,
    private toAdminService: ToAdminService,
    private toastr: ToastrService,
    private errorNotificationService: ErrorNotificationService,
    public dialogRef: MatDialogRef<DialogAddStudyComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public diStudyList: any
  ) {
  }

  ngOnInit() {
        //get study service studies list of the organization
        this.studyService.getStudyServiceStudies().subscribe(studies => {
          //filter the list by removing already existed studies
          if(studies) {
            this.studyServiceStudyList = studies.sort(function(a,b) {
              let x = '';
              let y = '';
              if(a['ertStudyName']) {
                x = a['ertStudyName'].toLowerCase();
              }
              if(b['ertStudyName']) {
                y = b['ertStudyName'].toLowerCase();
              }              
              if (x < y) {return -1;}
              if (x > y) {return 1;}
              return 0;
            });
            //if user clicks add button before study list loads
            if(!this.diStudyList) {
              this.studyService.getStudiesForAdmin().subscribe((studies) =>  {
                this.diStudies = studies;
                this.pushToList();
              });
            } else {
              this.diStudies = this.diStudyList;
              this.pushToList();
            }
          }
        });
  }

  pushToList() {
    for (let item of this.studyServiceStudyList) {
      //if there is no study added already then show all
      if(this.diStudies == null) {
        this.filStudyServiceStudyList.push(item);
      } else if (!this.diStudies.find((i) => i.ertStudyId == item.ertStudyId)) {
        this.filStudyServiceStudyList.push(item);
      }
    }
  }
  onNoClick(): void {
    this.dialogRef.close(null);
  }

  addStudy(): void {
    //call api to add study in database
    if (this.selectedStudy != "null") {
      //close dialog with updated study list
      let selectedStudyServiceStudy = this.filStudyServiceStudyList.find(
        (i) => i.ertStudyId == this.selectedStudy
      );
      this.onSave(selectedStudyServiceStudy);
    } else {
      this.dialogRef.close();
    }
  }

  onSave(selectedStudyServiceStudy) {
    this.toAdminService.addStudy(selectedStudyServiceStudy).subscribe(
      (data) => {
        this.toastr.success("Study added successfully.");
        let selectedStudyData = {
          clinicalGovId: data[0].clinicalGovId,
          country: data[0].country,
          createdDate: data[0].createdDate,
          dataInsightsStatus: data[0].dataInsightsStatus,
          dataInsightsStatusUI: "Disabled",
          ertStudyId: data[0].ertStudyId,
          dateEnabled: data[0].dateEnabled,
          description: data[0].description,
          duration: data[0].duration,
          indications: data[0].indications,
          numSites: data[0].numSites,
          numSubjects: data[0].numSubjects,
          phase: data[0].phase,
          protocolName: data[0].protocolName,
          protocolNumber: data[0].protocolNumber,
          reasonForChange: data[0].reasonForChange,
          region: data[0].region,
          sponsorName: data[0].sponsorName,
          srcStudyId: data[0].srcStudyId,
          startDate: data[0].startDate,
          studyName: data[0].studyName,
          studyOid: data[0].studyOid,
          therapeuticArea: data[0].therapeuticArea,
        };
        
        this.dialogRef.close(selectedStudyData);
      },
      (err) => {
        if (err.status === 403) {
          this.errorNotificationService.notifyError("You do not have permission to perform this task.", err);
        } else {
          this.errorNotificationService.notifyError("Error adding a study. Please try again.", err);
        }
      }
    );
  }
}

export class StudyData {
  studyName: string;
  clinicalGovId: string;
  protocolName: string;
  protocolNumber: string;
  description: string;
  numSubjects: number;
  numSites: number;
  startDate: string;
  duration: number;
  createdDate: string;
  therapeuticArea: null;
  sponsorName: string;
  phase: string;
  indications: string;
  country: string;
  region: string;
  studyOid: string;
  compositeValue: string;
  reasonForChange: string;
  dataInsightsStatus: boolean;
  dateEnabled: string;
}
