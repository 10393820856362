<form [formGroup]="form" (ngSubmit)="onFormSubmit()" #formElem="ngForm">
  <mat-form-field>
    <mat-select placeholder="{{ name }} Mitigation Strategy *" formControlName="mitigationStrategy"
      (selectionChange)="onStrategyChanged()" [(ngModel)]="threshold && threshold.mitigationStrategy"
      [disableControl]="!threshold">
      <mat-option *ngFor="let strategy of mitigationStrategies" [value]="strategy.value">{{ strategy.display }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field *ngIf="form.controls['mitigationStrategy'] && form.value?.mitigationStrategy === 'other'">
    <input matInput placeholder="Please Specify *" formControlName="otherMitigationStrategy">
  </mat-form-field>

  <ng-container *ngIf="name == 'KRI'">
    <div class="row justify-content-between">
      <mat-form-field class="threshold-min col-sm-4">
        <input class="threshold-input" matInput type="number" placeholder="Threshold Min *"
          formControlName="thresholdRangeMin" [(ngModel)]="rangeThreshold.min" (ngModelChange)="onTresholdRangeChange()"
          [step]="threshold?.sliderConfig?.step">
        <span class="normal-text">{{ threshold?.units | lowercase }}</span>
      </mat-form-field>
      <mat-form-field class="threshold-max col-sm-4">
        <input class="threshold-input" matInput type="number" placeholder="Threshold Max *"
          formControlName="thresholdRangeMax" [(ngModel)]="rangeThreshold.max" (ngModelChange)="onTresholdRangeChange()"
          [step]="threshold?.sliderConfig?.step">
        <span class="normal-text">{{ threshold?.units | lowercase }}</span>
        <mat-error class="threshold-value-error"
          *ngIf="form.controls['thresholdRangeMax'].errors && form.controls['thresholdRangeMax'].errors.incorrectValue && (name == 'KRI')">
          Threshold max must be greater than Threshold min.</mat-error>
      </mat-form-field>
    </div>
  </ng-container>

  <ng-container>
    <mat-form-field class="threshold range">
      <input class="threshold-input" matInput type="number" [placeholder]="name == 'KRI' ? 'Threshold *' : 'Target *'"
        formControlName="thresholdValue" [(ngModel)]="thresholdValue" (change)="onInputChange($event)"
        [min]="rangeThreshold?.min" [max]="rangeThreshold?.max" [step]="threshold?.sliderConfig?.step"
        [disableControl]="!threshold">
      <span class="threshold-units">{{ threshold?.units | lowercase}}</span>
      <mat-error class="threshold-value-error"
        *ngIf="form.controls['thresholdValue'].errors && form.controls['thresholdValue'].errors.incorrectValue && (name == 'KRI')">
        Please select a value between the <b>Threshold Range</b>.</mat-error>
      <mat-slider class="threshold-value-slider" formControlName="thresholdValue" [(ngModel)]="thresholdValue"
        [min]="rangeThreshold?.min" [max]="rangeThreshold?.max" [step]="threshold?.sliderConfig?.step"
        (input)="onSliderChange($event)" [disableControl]="!threshold" thumb-label="true">
      </mat-slider>

    </mat-form-field>

    <div *ngIf="name == 'KRI'">
      <mat-form-field class="operator threshold-operator">
        <mat-select placeholder="Threshold Operator *" formControlName="thresholdOperator"
          [(ngModel)]="threshold && threshold.thresholdOperator" [disableControl]="!threshold">
          <mat-option value="gte">Greater Than Or Equal To</mat-option>
          <mat-option value="lte">Less Than Or Equal To</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </ng-container>
  <div>
    <mat-form-field class="operator">
      <mat-select placeholder="{{ name }} Weight *" formControlName="weight"
        [(ngModel)]="threshold && threshold.calcWeight" [disableControl]="!threshold">
        <mat-option [value]="1">Low</mat-option>
        <mat-option [value]="2">Medium</mat-option>
        <mat-option [value]="3">High</mat-option>
        <mat-option [value]="4">Critical</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="threshold-active" *ngIf="this.type === 'Update'">
    <mat-checkbox formControlName='active' [(ngModel)]="threshold && threshold.active" [disableControl]="!threshold">
      Active?</mat-checkbox>
  </div>

  <mat-checkbox color="primary" class="generate-alert-checkbox" [checked]="shouldGenerateAlert"
    (change)="toggleGenerateAlert()">Generate
    Alert?</mat-checkbox>
  <mat-form-field class="date-picker" *ngIf="shouldGenerateAlert">
    <input matInput [matDatepicker]="alertStartDate" [min]="minDate" placeholder="Alert Start Date *"
      formControlName="alertStartDate" [disableControl]="!threshold">
    <mat-datepicker-toggle matSuffix [for]="alertStartDate"></mat-datepicker-toggle>
    <mat-datepicker #alertStartDate></mat-datepicker>
  </mat-form-field>

  <mat-form-field *ngIf="this.type === 'Update' && form.dirty" class="operator">
    <textarea matInput placeholder="Reason for Change *" formControlName="reasonForChange"></textarea>
  </mat-form-field>
  <div class="button-container">
    <button class="es-button" (click)="onClose()" type="button">Cancel</button>
    <ert-spinner-button [active]="buttonActive" [buttonText]="buttonText" [disabled]="!form.valid" class="button-right">
    </ert-spinner-button>
  </div>
</form>