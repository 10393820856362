<mat-drawer-container autosize>
  <mat-drawer class="sidenav" #drawer opened mode="side">
    <mat-list class="full-width vert-align" role="list">
      <div>
        <button color="primary" mat-button [routerLink]="['/dashboard/alerts']">
          <mat-icon aria-label="Alerts" svgIcon="alerts"></mat-icon>Alerts
        </button>
      </div>
      <div>
        <button mat-button [routerLink]="['/dashboard/tasks']">
          <mat-icon aria-label="Tasks" svgIcon="tasks"></mat-icon><span>Tasks</span>
        </button>
      </div>
    </mat-list>
  </mat-drawer>

  <mat-toolbar>
    <button class="back-to-alerts es-button" routerLink="{{from}}">
      <mat-icon>keyboard_backspace</mat-icon><span>Back to Alerts</span>
    </button>
    <button [ertPermission]="'createTask'" (click)="generateTask(item)" class="es-button -es-primary">
      <mat-icon>create</mat-icon>Create Task
    </button>
    <button (click)="ignoreAlert(item)" class="back-to-alerts" class="es-button">
      <mat-icon>delete</mat-icon><span>Ignore Alert</span>
    </button>
  </mat-toolbar>
  <div class="parent-container">
    <!-- <mat-spinner *ngIf="loading"></mat-spinner> -->
    <mat-card *ngIf="item">
      <mat-card-header>
        <mat-card-title>
          <h4>{{item.subject}}</h4>
        </mat-card-title>
        <mat-card-subtitle>Alert ID: {{item.studyAlertLogId}}</mat-card-subtitle>
        <mat-card-subtitle>Title: {{item.alertTitle}}</mat-card-subtitle>
        <mat-card-subtitle>Study Name: {{item.studyName}}</mat-card-subtitle>
        <mat-card-subtitle>Protocol #: {{item.studyProtocolNumber}}</mat-card-subtitle>
        <mat-card-subtitle *ngIf="item.siteNo !== null">Site #: {{item.siteNo}}</mat-card-subtitle>

        <mat-card-subtitle>
          <span *ngIf="item.alertMessageData.msgTemplate === 'THRESHOLD'">KRI: </span>
          <span *ngIf="item.alertMessageData.msgTemplate === 'TARGET'">TARGET: </span>
          {{item.alertMessageData.metricName}}
        </mat-card-subtitle>
        <mat-card-subtitle>
          <span *ngIf="item.alertMessageData.msgTemplate === 'MILESTONE'">Milestone: </span>
          {{item.milestoneType}}
        </mat-card-subtitle>
        <mat-card-subtitle>Date of Alert: {{item.createdDate | displayDate:true}}</mat-card-subtitle>
        <mat-card-subtitle *ngIf="item.isUserGenerated === false && item.alertMessageData.msgTemplate !== 'MILESTONE'">Mitigation
          Strategy: {{item.mitigationStrategyNice}}</mat-card-subtitle>
        <mat-card-subtitle *ngIf="! item.studyName">Portfolio</mat-card-subtitle>
        <mat-card-subtitle *ngIf="item.thresholdShortVal">Configured Threshold:
          <span *ngIf="item.alertMessageData.thresholdOperator === 'LTE'"> &lt;= </span>
          <span *ngIf="item.alertMessageData.thresholdOperator === 'GTE'"> &gt;= </span>
          {{item.thresholdShortVal}}
        </mat-card-subtitle>
        <mat-card-subtitle *ngIf="item.metricValue!==null && item.alertMessageData.msgTemplate !== 'MILESTONE'">
          Actual Value: {{item.metricValue}}
        </mat-card-subtitle>
        <mat-card-subtitle *ngIf="item.metricValue!==null && item.alertMessageData.msgTemplate === 'MILESTONE'">
          Expected Date: {{item.alertMessageData.expectedDate | displayDate}}
        </mat-card-subtitle>
        <mat-card-subtitle *ngIf="item.metricValue!==null && item.alertMessageData.msgTemplate === 'MILESTONE'">
          Actual Date: {{item.metricValue | displayDate}}
        </mat-card-subtitle>

      </mat-card-header>
      <mat-divider></mat-divider>
      <mat-card-content class="alertBody">
        <p>{{item.alertMessage}}</p>
      </mat-card-content>
      <mat-card-actions>

      </mat-card-actions>
    </mat-card>
  </div>

</mat-drawer-container>
