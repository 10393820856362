import {Component, Inject, Input, OnInit} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'ert-confirm-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})

export class ConfirmationDialogComponent implements OnInit{
  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  public confirmMessage: string;
  public confirmQuestion = 'Do you wish to continue?';
  title = 'Do you wish to continue?';
  buttonsText = {
    cancel: 'Cancel', confirm: 'Confirm'
  };

  ngOnInit() {
    this.title = this.data && this.data.title || this.title;
    this.buttonsText = this.data && this.data.buttons || this.buttonsText;
    this.confirmQuestion = this.data && this.data.question || this.confirmQuestion;
  }

  closeConfirmDialog(bool) {
    this.dialogRef.close(bool);
  }
}
